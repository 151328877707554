import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { IVersion } from '../types/version'
import { fetchGet, processError } from '../utils/fetch-utils'

function useVersion(gateway: any): IVersion | null {
  const dispatch = useDispatch()
  const [version, setVersion] = useState<IVersion | null>(null)

  useEffect(() => {
    const fetchVersion = async () => {
      try {
        const fetchedData = await fetchGet('/api/version', gateway)
        setVersion(fetchedData)
      } catch (e) {
        processError(e, dispatch)
      }
    }

    fetchVersion()
  }, [dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

  return version
}

export default useVersion
