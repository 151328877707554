import React, { FC, memo } from 'react'
import { withTooltipPreventOverflow } from '../../ConfTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface IButtonProps {
  cb: (cb: any) => void
  cls: string
  icon?: any
  text: string
  placement?: string
  type?: 'prompt'
  disabled?: boolean
}

export const Button: FC<IButtonProps> = memo(({ cb, cls, icon, text, placement, type, disabled }) => {
  return type === 'prompt' ? (
    <>
      {withTooltipPreventOverflow(
        <button type="button" onClick={cb} className={cls}>
          {icon && <FontAwesomeIcon icon={icon} />}
        </button>,
        text,
        text,
        '',
        placement || 'top',
      )}
    </>
  ) : (
    <button type="button" value={text} disabled={disabled} className={cls} key={text} onClick={cb}>
      {text}
    </button>
  )
})
