import React, { FC, useEffect, useMemo, useState } from 'react'
import { IRightbar } from '../rightbar-types'
import { AppAccordion } from '@t4b/core'
import { FormattedMessage } from 'react-intl'
import { Button } from 'react-bootstrap'
import { useFormValidation } from '../../../hooks/useFormValidation'
import { buildControlsExtTwoPerLine, mselectInput, sselectInput, textInput } from '../../../utils/controls'
import {
  buildMultiselectOptionsFromArray,
  buildMultiselectOptionsFromEnum,
  buildOptionsWithAll,
  isAllOption,
  isEqualArrays,
  optionsToStrings,
} from '../../../utils/multiselect-utils'
import { stylesMulti } from './Channel'
import { ReceiverChannel } from './ReceiverChannel'
import { useDispatch, useSelector } from 'react-redux'
import { updateNotificationRuleAll } from '../../../redux/reducers/notificationReducer'
import { addNotificationRuleFetch, NotificationRulesFetch, updateNotificationRuleFetch } from '../../monitoring/NotificationPages/NotificationRules/actions'
import { hideRightBar } from '../../../redux/actions/rightbar-actions'
import { alreadyExistNameNotification } from '../lpRightbarUtils/utils'
import { OrderType } from '../../../entity/configuration'
import * as yup from 'yup'

export class NotificationRule {
  Channels: any[]
  Receivers: any[]
  NotificationEventType: string
  Name: string
  Initiator: any[]
  Id: any
  Details: string
  MinCount: number | string
  constructor(item?: any) {
    this.Channels = item?.Channels ?? []
    this.Receivers = item?.Receivers ?? []
    this.NotificationEventType = item?.NotificationEventType ?? ''
    this.Name = item?.Name ?? ''
    this.Initiator = item?.Initiator ?? [{ value: 'All', label: 'All' }]
    this.Id = item?.Id ?? Math.floor(Math.random() * (10000 - 1 + 1)) + 1
    this.Details = item?.Details ?? '*'
    this.MinCount = item?.MinCount ?? '50'
  }
}
const NotificationRulesRightbar: FC<IRightbar> = ({ data: { type, item, setLoading, Gateway } }) => {
  const dispatch = useDispatch()
  const { rules, initiators, rulesTable, initiatorsMask } = useSelector((state: any) => state.NotificationReducer)
  const NotificationEvent = useMemo(() => Object.keys(initiators), [initiators])
  const [update, setUpdate] = useState(false)

  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(
    new NotificationRule({
      ...item,
      Initiator: buildMultiselectOptionsFromArray(item.Initiator),
      NotificationEventType: { value: item.NotificationEventType, label: item.NotificationEventType },
      Details: item.Details,
    }),
    {
      NotificationEventType: yup.string(),
      Name: yup.string().required(),
      Details: yup.string().required(),
      Initiator: yup.array().required(),
      MinCount: yup
        .string()
        .matches(/^[0-9]+$/gi)
        .transform((_, val) => String(val))
        .test('', '', (value: any) => value > 1),
    },
  )

  const newItem = rules.find((elem: any) => elem.Id === inputState.Id)
  const options = initiators?.[inputState?.NotificationEventType.value] ?? []
  const disabledDetails = initiatorsMask.includes(inputState?.NotificationEventType.value)

  if (inputState.NotificationEventType.value !== 'Quote limitation stopped feeding') {
    errors.MinCount = false
  }

  useEffect(() => {
    setUpdate(!update)
  }, [])

  useEffect(() => {
    if (disabledDetails) {
      touched.Details = false
      errors.Details = false
      setInputState((prev: any) => {
        return {
          ...prev,
          Details: '',
        }
      })
    } else {
      setInputState((prev: any) => {
        return {
          ...prev,
          Details: prev.Details.length ? prev.Details : '*',
        }
      })
    }
  }, [disabledDetails])

  useEffect(() => {
    if (inputState?.NotificationEventType.value === 'Quote limitation stopped feeding') {
      setInputState((prev: any) => {
        return {
          ...prev,
          MinCount: prev.MinCount ?? '50',
        }
      })
    }

    if (!initiators[inputState?.NotificationEventType.value]?.length) {
      touched.Initiator = false
      errors.Initiator = false
      setInputState((prev: any) => {
        return {
          ...prev,
          Initiator: [],
        }
      })
    } else if (inputState.Initiator.length === 0) {
      setInputState((prev: any) => {
        return {
          ...prev,
          Initiator: [{ value: 'All', label: 'All' }],
        }
      })
    }
  }, [inputState?.NotificationEventType.value])

  useEffect(() => {
    let timeout: any = null
    if (update) {
      timeout = setTimeout(() => {
        dispatch(
          updateNotificationRuleAll({
            data: {
              ...newItem,
              Name: inputState.Name,
              NotificationEventType: inputState.NotificationEventType,
              Details: inputState.Details,
              Initiator: inputState.Initiator,
            },
            id: item.Id,
          }),
        )
      }, 300)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [inputState])

  useEffect(() => {
    if (type === 'add') {
      setInputState((prev: any) => {
        return {
          ...prev,
          NotificationEventType: { value: NotificationEvent[0], label: NotificationEvent[0] },
        }
      })
    }
  }, [NotificationEvent])

  const setOrderTypesState = (state: any) => {
    const all = 'All'

    if (
      state.Initiator?.find(isAllOption) ||
      isEqualArrays(
        optionsToStrings(state.Initiator)
          .filter(option => option !== all)
          .sort(),
        Object.keys(OrderType)
          .filter(option => option !== all)
          .sort(),
      )
    ) {
      setInputState({
        ...state,
        Initiator: buildMultiselectOptionsFromArray([all]),
      })
      return
    }

    setInputState(state)
  }

  const inputs = buildControlsExtTwoPerLine(
    [
      textInput('Name').errorMessage(alreadyExistNameNotification(type, rulesTable, inputState, errors)),
      sselectInput('NotificationEventType', buildMultiselectOptionsFromArray(NotificationEvent)).optionZindex(true, 999999999999),
      mselectInput('Initiator', buildOptionsWithAll(buildMultiselectOptionsFromEnum(['All', ...options]), inputState.Initiator), true)
        .optionZindex(true, 999999)
        .stateSetup(setOrderTypesState)
        .styles(stylesMulti)
        .disabled(!initiators[inputState?.NotificationEventType.value]?.length),
      textInput('Details').skipWhen(disabledDetails),
      textInput('MinCount').skipWhen(inputState?.NotificationEventType.value !== 'Quote limitation stopped feeding'),
    ],
    inputState,
    setInputState,
    'Rule',
    touched,
    setTouched,
    errors,
  )
  const handleSave = async () => {
    if (disabledDetails) {
      errors.Details = false
      touched.Details = false
    }

    if (!initiators[inputState?.NotificationEventType.value]?.length) {
      touched.Initiator = false
      errors.Initiator = false
    }

    if (!isValid()) return

    if (type === 'add' || type === 'clone') {
      await addNotificationRuleFetch({
        body: {
          ...inputState,
          ...newItem,
          Initiator: inputState?.Initiator?.map((item: any) => item.value) ?? [],
          NotificationEventType: inputState?.NotificationEventType?.value ?? inputState?.NotificationEventType,
          Id: -1 * (newItem?.Id ?? Math.floor(Math.random() * (10000 - 1 + 1)) + 1),
          MinCount: inputState?.NotificationEventType.value !== 'Quote limitation stopped feeding' ? '1' : inputState?.MinCount,
        },
        dispatch,
        Gateway,
      })
    } else {
      await updateNotificationRuleFetch({
        body: {
          ...inputState,
          ...newItem,
          Initiator: newItem?.Initiator?.map((item: any) => item.value) ?? [],
          NotificationEventType: newItem.NotificationEventType?.value ?? newItem?.NotificationEventType,
          MinCount: inputState?.NotificationEventType.value !== 'Quote limitation stopped feeding' ? '1' : inputState?.MinCount,
        },
        dispatch,
        Gateway,
      })
    }
    await NotificationRulesFetch({
      setLoading,
      Gateway: Gateway,
      dispatch,
      Page: 1,
      Count: 10000,
    })
    dispatch(hideRightBar())
  }

  return (
    <>
      <AppAccordion
        item={{
          title: <FormattedMessage id={`Rule.${type}`} />,
          item: (
            <div>
              {inputs}
              <ReceiverChannel
                id={newItem?.Id}
                data={
                  newItem?.Receivers?.length
                    ? newItem.Receivers
                    : [
                        {
                          Name: '',
                          Id: -Math.floor(Math.random() * (10000 - 1 + 1)) + 1,
                        },
                      ]
                }
                name={'Receivers'}
              />
            </div>
          ),
        }}
        isHidden={false}
      />

      <Button className="t4b-bg-dark-button mt-3 ml-20" onClick={handleSave}>
        <FormattedMessage id="save" tagName="span" />
      </Button>
    </>
  )
}

export default NotificationRulesRightbar
