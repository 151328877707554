import React from 'react'
import { TopAskPrice, TopBidPrice, TotalAskVolume, TotalBidVolume } from '../../lp-performance/QuotesView'
import { buildAsk, buildBid } from './utilsModal'
import classNames from 'classnames'
import { Card } from 'react-bootstrap'
import { newAsTime } from '../../../utils/table-utils'

export const QuotesViewModal = ({ quotes, depth, name }: any) => {
  return (
    <>
      <Card className={classNames('quote-source-model', 'mb-20')}>
        <Card.Header className={classNames('accordion-custom__toggle', 'd-flex', 'justify-content-between', 'text-white', 'font-500', 'border-none', 'headerSpred')}>
          <span>{name ?? ''}</span>
          <span className={'headerTime'}>{newAsTime(quotes?.OrderBook?.Ask[0]?.Time) ?? ''}</span>
        </Card.Header>
        <Card.Body className="accordion-custom__collapse">
          <div className="row">
            <TopBidPrice quotes={quotes} />
            <TopAskPrice quotes={quotes} />
          </div>
          <div className="d-flex justify-content-center">
            <table
              style={{
                tableLayout: 'fixed',
                borderCollapse: 'collapse',
                textAlign: 'center',
              }}
            >
              <colgroup>
                <col style={{ width: '100px' }} />
                <col style={{ width: '100px' }} />
                <col style={{ width: '100px' }} />
              </colgroup>
              <tbody>
                <td>Volume</td>
                <td>Price</td>
                <td>Spread</td>
                {buildAsk(quotes, depth)}
                {buildBid(quotes, depth)}
              </tbody>
            </table>
          </div>
          <div
            style={{
              borderBottom: '2px solid rgba(0, 0, 0, 0.3)',
              margin: '1rem 0',
            }}
          />
          <div className="row">
            <TotalBidVolume quotes={quotes} />
            <TotalAskVolume quotes={quotes} />
          </div>
          <div
            style={{
              borderBottom: '2px solid rgba(0, 0, 0, 0.3)',
              margin: '1rem 0',
            }}
          />
        </Card.Body>
      </Card>
    </>
  )
}
