import { Dispatch } from 'redux'
import { throwErrorMessage, throwSuccessMessage } from '../../../utils/errors-utils'
import { buildHTTPGetOptions, buildHTTPPostOptions, checkResponse, fetchGet, processError } from '../../../utils/fetch-utils'
import { Action } from '../actions'

export function fetchCeActive(params: any) {
  return (dispatch: Dispatch) => {
    const url = new URL('/api/continuousExecution/active', window.location.origin)
    url.searchParams.set('gateway', params.Gateway)
    url.searchParams.set('page', params.Page)
    url.searchParams.set('count', params.Count)
    if (params.field.length && params.by !== 'none') {
      url.searchParams.set('sort', params?.field)
      url.searchParams.set('by', params.by)
    }
    dispatch({ type: Action.InProgressStart })
    params.setLoading(true)
    return fetch(url.toString(), buildHTTPGetOptions())
      .then((response: Response) => checkResponse(response))
      .then((data: any) => dispatch({ type: Action.GotCeActive, data }))
      .catch((error: Error) => processError(error, dispatch))
      .finally(() => {
        dispatch({ type: Action.InProgressEnd })
        params.setLoading(false)
      })
  }
}

function onlyFetchCeFailed(params: any) {
  const url = new URL('/api/continuousExecution/failed', window.location.origin)
  url.searchParams.set('gateway', params.Gateway)
  url.searchParams.set('page', params.Page)
  url.searchParams.set('count', params.Count)
  if (params.field.length && params.by !== 'none') {
    url.searchParams.set('sort', params.field)
    url.searchParams.set('by', params.by)
  }

  return fetch(url.toString(), buildHTTPGetOptions()).then((response: Response) => checkResponse(response))
}

export function fetchCeFailed(params: any) {
  return (dispatch: Dispatch) => {
    dispatch({ type: Action.InProgressStart })
    params.setLoading(true)

    return onlyFetchCeFailed(params)
      .then((data: any) => dispatch({ type: Action.GotCeFailed, data }))
      .catch((error: Error) => processError(error, dispatch))
      .finally(() => {
        dispatch({ type: Action.InProgressEnd })
        params.setLoading(false)
      })
  }
}

function onlyFetchCeActive(params: any) {
  const url = new URL('/api/continuousExecution/active', window.location.origin)
  url.searchParams.set('gateway', params.Gateway)
  url.searchParams.set('page', params.Page)
  url.searchParams.set('count', params.Count)
  if (params.field.length && params.by !== 'none') {
    url.searchParams.set('sort', params.field)
    url.searchParams.set('by', params.by)
  }

  return fetch(url.toString(), buildHTTPGetOptions()).then((response: Response) => checkResponse(response))
}

export function fetchCeActiveRetry(params: any, item: any) {
  const url = new URL('/api/continuousExecution/activeStop', document.location.origin)
  url.searchParams.set('gateway', params.Gateway)

  const req = {
    Id: item.Id,
  }

  return (dispatch: Dispatch) => {
    dispatch({ type: Action.InProgressStart })
    params.setLoading(true)

    return fetch(url.toString(), buildHTTPPostOptions(req))
      .then((response: Response) => checkResponse(response))
      .then((status: any) => {
        if (status.Status) {
          throwErrorMessage()
        } else {
          return onlyFetchCeActive(params)
        }
      })
      .then((data: any) => dispatch({ type: Action.GotCeActive, data }))
      .catch((error: Error) => processError(error, dispatch))
      .finally(() => {
        dispatch({ type: Action.InProgressEnd })
        params.setLoading(false)
      })
  }
}

export function fetchCeFailedRetry(params: any, item: any) {
  const url = new URL('/api/continuousExecution/failedRetry', document.location.origin)
  url.searchParams.set('gateway', params.Gateway)

  const req = {
    Id: item.Id,
  }

  return (dispatch: Dispatch) => {
    dispatch({ type: Action.InProgressStart })
    params.setLoading(true)

    return fetch(url.toString(), buildHTTPPostOptions(req))
      .then((response: Response) => checkResponse(response))
      .then((status: any) => {
        if (status.Status) {
          throwErrorMessage()
        } else {
          return onlyFetchCeFailed(params)
        }
      })
      .then((data: any) => dispatch({ type: Action.GotCeFailed, data }))
      .catch((error: Error) => processError(error, dispatch))
      .finally(() => {
        dispatch({ type: Action.InProgressEnd })
        params.setLoading(false)
      })
  }
}

function onlyFetchCeRules(params: any) {
  const url = new URL('/api/continuousExecution/rules', window.location.origin)
  url.searchParams.set('gateway', params.Gateway)
  url.searchParams.set('pool', params.Pool)
  url.searchParams.set('page', params.Page)
  url.searchParams.set('count', params.Count)

  return fetch(url.toString(), buildHTTPGetOptions()).then((response: Response) => checkResponse(response, '/api/continuousExecution/rules'))
}

export function fetchCeRules(params: any) {
  return (dispatch: Dispatch) => {
    dispatch({ type: Action.InProgressStart })
    params.setLoading(true)

    return onlyFetchCeRules(params)
      .then((data: any) => dispatch({ type: Action.GotCeRules, data }))
      .catch((error: Error) => processError(error, dispatch))
      .finally(() => {
        dispatch({ type: Action.InProgressEnd })
        params.setLoading(false)
      })
  }
}

export function fetchCeRulesDelete(params: any) {
  return (dispatch: Dispatch) => {
    const url = new URL('/api/continuousExecution/rulesDelete', window.location.origin)
    url.searchParams.set('gateway', params.Gateway)
    url.searchParams.set('pool', params.Pool)

    dispatch({ type: Action.InProgressStart })
    params.setLoading(true)

    return fetch(url.toString(), buildHTTPPostOptions({ Id: params.Id }, '/api/continuousExecution/rules'))
      .then((response: Response) => checkResponse(response, '/api/continuousExecution/rules'))
      .then((result: any) => {
        if (result.Status) {
          return new Promise((resolve, reject) => reject(new Error('error.404')))
        }
        throwSuccessMessage('Successfully')
        return onlyFetchCeRules(params)
      })
      .then((data: any) => dispatch({ type: Action.GotCeRules, data }))
      .catch((error: Error) => processError(error, dispatch))
      .finally(() => {
        dispatch({ type: Action.InProgressEnd })
        params.setLoading(false)
      })
  }
}

export function fetchCeRulesChangePriority(arg: any) {
  return (dispatch: Dispatch) => {
    const { params, data, result } = arg
    const url = new URL('/api/continuousExecution/rulesChangePriority', window.location.origin)
    url.searchParams.set('gateway', params.Gateway)
    url.searchParams.set('pool', params.Pool)

    const newRules = Array.from(data)
    newRules.splice(result.source.index, 1)
    newRules.splice(result.destination.index, 0, data[result.source.index])
    return fetch(url.toString(), buildHTTPPostOptions({ NewPriority: newRules.map((rule: any) => rule.Id) }))
      .then((response: Response) => checkResponse(response))
      .then((result: any) => {
        if (result.Status) {
          return new Promise((resolve, reject) => reject(new Error('error.404')))
        }
        throwSuccessMessage('Successfully')
        return new Promise((resolve, reject) => resolve(true))
      })
      .catch((error: Error) => processError(error, dispatch))
  }
}

export function fetchCeRulesUpdate(params: any) {
  const { action, rule } = params
  const url = new URL(`/api/continuousExecution/rules${action === 'clone' ? 'Add' : `${action[0].toUpperCase()}${action.slice(1)}`}`, document.location.origin)
  url.searchParams.set('gateway', params.Gateway)
  url.searchParams.set('pool', params.Pool)

  if (action === 'clone') {
    rule.Id = 'None'
  }
  return (dispatch: Dispatch) => {
    dispatch({ type: Action.InProgressStart })
    params.setLoading(true)

    return fetch(url.toString(), buildHTTPPostOptions(rule, '/api/continuousExecution/rules'))
      .then((response: Response) => checkResponse(response, '/api/continuousExecution/rules'))
      .then((result: any) => {
        if (result.Status) {
          return new Promise((resolve, reject) => reject(new Error('error.404')))
        } else {
          throwSuccessMessage('Successfully')
          return onlyFetchCeRules(params)
        }
      })
      .then((data: any) => dispatch({ type: Action.GotCeRules, data }))
      .catch((error: Error) => processError(error, dispatch))
      .finally(() => {
        dispatch({ type: Action.InProgressEnd })
        params.setLoading(false)
      })
  }
}

export function fetchCeHistory(setLoading: (isLoading: boolean) => void, ...queryParams: any) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: Action.InProgressStart })
    setLoading(true)

    try {
      const data = await fetchGet('/api/continuousExecution/history', ...queryParams)
      dispatch({ type: Action.GotCeHistory, data })
    } catch (e) {
      processError(e, dispatch)
    }

    setLoading(false)
    dispatch({ type: Action.InProgressEnd })
  }
}
