import * as yup from 'yup'

export class FeedExecPresetEntity {
  Id: string | number
  Name: string
  Description: string
  Enabled: boolean
  PresetId: number
  Begin: number
  Duration: number
  End: number
  Start: number
  ScheduleType: any
  Days: any
  flag: boolean
  bunchProfiles: any
  flagSchedule?: boolean

  static schema = {
    Name: yup.string().required(),
  }

  constructor(item?: any) {
    this.flagSchedule = item.flagSchedule ?? false
    this.bunchProfiles = item.bunchProfiles ?? [item.Name]
    this.Id = item.Id ?? Math.floor(Math.random() * (1000 + 1 - 1))
    this.Name = item.Name ?? ''
    this.Description = item.Description ?? ''
    this.Enabled = item.Enabled ?? true
    this.PresetId = item.PresetId ?? Math.floor(Math.random() * (1000 + 1 - 1))
    this.Begin = item.Begin ?? Date.now()
    this.Duration = item.Duration ?? 0
    this.End = item.End ?? Date.now()
    this.Start = item.Start ?? 0
    this.ScheduleType = item.ScheduleType ?? 'Always'
    this.Days = item.Days ?? [{ value: 'Everyday', label: 'Everyday' }]
    this.flag = item.flag ?? false
  }
}
