import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { ConnectionString } from '../../entity/configuration'
import { useFormValidation } from '../../hooks/useFormValidation'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { buildControlsExtTwoPerLine, passwordInput, textInput } from '../../utils/controls'
import { IRightbar } from './rightbar-types'

const DatabaseRightbar: React.FC<IRightbar> = ({ data: { item, onSave } }) => {
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(new ConnectionString(item), ConnectionString.schema('modify'))
  const dispatch = useDispatch()

  const handleSave = () => {
    if (!isValid()) {
      return
    }
    if (onSave) {
      onSave(inputState)
    }
    dispatch(hideRightBar())
  }

  return (
    <Card>
      <Card.Header className="color-dark font-500">
        <FormattedMessage id="database-rightbar.title" />
      </Card.Header>
      <Card.Body>
        {buildControlsExtTwoPerLine(
          [textInput('Server'), textInput('Port'), textInput('UserId'), passwordInput('Password', 'change'), textInput('Database')],
          inputState,
          setInputState,
          'database',
          touched,
          setTouched,
          errors,
        )}
        <Button className="t4b-bg-dark-button mt-3" onClick={handleSave}>
          <FormattedMessage id="save" />
        </Button>
      </Card.Body>
    </Card>
  )
}
export default DatabaseRightbar
