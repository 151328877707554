import { Dispatch } from 'redux'
import { buildHTTPGetOptions, buildHTTPPostOptions, checkResponse, checkTextResponse, downloadFile, processError } from '../../utils/fetch-utils'
import { Action } from './actions'
import { throwErrorMessage } from '../../utils/errors-utils'

export function buildDealsUrl(params: any, download?: boolean) {
  let name = params.PageName
  switch (name) {
    case 'fixReports':
      name = '/executionReports'
      break
    case 'reportDownload':
      name = '/DownloadBookSnapshotReport'
      break
    case 'bookSnapshotReport':
      name = '/BookSnapshotReport'
      break
    case 'aggregationDeals':
      name = 'lpExecutions'
      break
    case 'fixOrders':
      name = '/orders'
      break
    case 'fixSwaps':
      name = '/swaps'
      break
    case 'mt4deals':
    case 'mt5deals':
    case 'deals':
      name = ''
      break
  }

  const arr = ['fixOrders', 'fixReports', 'fixSwaps']

  if (download && arr.includes(params.PageName)) {
    name += 'Download'
  } else if (params.PageName === 'reportDownload') {
    name = name
  } else if (download) {
    name += '/download'
  }

  const newUrl = params.PageName === 'aggregationDeals' ? `/api/${name}` : `/api/deals${name}`
  const url = new URL(`${newUrl}`, window.location.origin)

  if (params.PageName === 'reportDownload' || params.PageName === 'bookSnapshotReport') {
    url.searchParams.set('gateway', params.Gateway)
    url.searchParams.set('platform', params.Platform)
    url.searchParams.set('orderId', params.Order)
  } else {
    url.searchParams.set('gateway', params.Gateway)
    url.searchParams.set('lpexecutiontype', params?.lpExecutionType?.value)

    url.searchParams.set('platform', params.Platform.value)
    if (params.Session) {
      url.searchParams.set('session', params.Session.value)
    }
    url.searchParams.set('pool', params.Pool.value)
    url.searchParams.set('from', params.From)
    url.searchParams.set('to', params.To)
    url.searchParams.set('page', params.Page)
    url.searchParams.set('count', params.Count)
    url.searchParams.set('groups', params.Groups)

    if (params.field.length && params.by !== 'none') {
      url.searchParams.set('sort', params.field)
      url.searchParams.set('by', params.by)
    }
    if (params.Search) {
      url.searchParams.set('search', params.Search)
    }
  }

  return url
}

export function fetchDeals(params: any) {
  const url = buildDealsUrl(params)
  return (dispatch: Dispatch) => {
    dispatch({ type: Action.InProgressStart })
    params.setLoading(true)

    return fetch(url.toString(), buildHTTPGetOptions(params.signal))
      .then((response: Response) => checkResponse(response))
      .then(
        data => dispatch({ type: Action.GotDeals, data }),
        (error: Error) => {
          dispatch({
            type: Action.GotDeals,
            data: {
              Data: [],
              TotalCount: 0,
            },
          })
          return new Promise((resolve, reject) => reject(error))
        },
      )
      .catch((error: Error) => processError(error, dispatch))
      .finally(() => {
        dispatch({ type: Action.InProgressEnd })
        params.setLoading(false)
      })
  }
}

export function fetchReportDeals(params: any) {
  const url = buildDealsUrl(params)
  return (dispatch: Dispatch) => {
    return fetch(url.toString(), buildHTTPGetOptions())
      .then((response: Response) => checkResponse(response))
      .then(data => {
        if (data.Errors.length) {
          throwErrorMessage(data.Errors.join(' '), true)
        }
        dispatch({ type: Action.GotReport, data })
      })
      .catch((error: Error) => processError(error, dispatch))
  }
}

export function fetchDealsDownload(params: any, body: any) {
  const url = buildDealsUrl(params, true)
  url.searchParams.set('type', params.FileType)

  return (dispatch: Dispatch) => {
    return fetch(url.toString(), buildHTTPPostOptions(body))
      .then((response: Response) => checkTextResponse(response))
      .then((data: any) => downloadFile(`deals.${params.FileType}`, data))
      .catch((error: Error) => processError(error, dispatch))
  }
}

export function fetchDealsDownloadAll(params: any, body: any) {
  const url = buildDealsUrl(params, true)
  url.searchParams.set('type', params.FileType)

  url.searchParams.delete('page')
  url.searchParams.delete('count')

  return (dispatch: Dispatch) => {
    return fetch(url.toString(), buildHTTPPostOptions(body))
      .then((response: Response) => checkTextResponse(response))
      .then((data: any) => downloadFile(`deals.${params.FileType}`, data))
      .catch((error: Error) => processError(error, dispatch))
  }
}

export function fetchReportDealsDownload(params: any) {
  const url = buildDealsUrl(params, true)
  url.searchParams.set('type', params.FileType)

  return (dispatch: Dispatch) => {
    return fetch(url.toString(), buildHTTPGetOptions())
      .then((response: Response) => checkTextResponse(response))
      .then((data: any) => downloadFile(`report.${params.FileType}`, data))
      .catch((error: Error) => processError(error, dispatch))
  }
}
