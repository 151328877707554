import React, { useEffect, useRef, useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import {
  fetchLpsAdd,
  fetchLpsAddBased,
  fetchLpsAddHedgingBBook,
  fetchLpsAddSynteticIndexLP,
  fetchLpsAddSynteticLP,
  fetchLpsAddVolumeTransformer,
  fetchLpsModifyVolumeTransformer,
  fetchLpsUpdate,
  fetchLpsUpdateBased,
  fetchLpsUpdateHedgingBBook,
  fetchLpsUpdateSynteticIndexLP,
  fetchLpsUpdateSynteticLP,
} from '../../redux/actions/system-settings-actions'
import { buildControlsExtTwoPerLine, sselectInput, textInput, checkboxInput } from '../../utils/controls'
import { useFormValidation } from '../../hooks/useFormValidation'
import {
  Binance,
  BusinessSettingsEntity,
  HedgingBBook,
  InteractiveBrokers,
  Laverate,
  LMAXCryptoClass,
  LMAXCryptoConnectorsClass,
  LpAlpaca,
  LpEntity,
  LpVolumeTransformer,
} from '../../entity/system-settings'
import { AppAccordion } from '@t4b/core/lib'
import { IRightbar } from './rightbar-types'
import { Limit, LpSession } from '../../entity/platforms'
import { buildOverridesHedgingBBookTable, buildOverridesLimitTable, buildOverridesMarketTable } from './lpRightbarUtils/LpRightbarHedgingBBook'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { LMAXCryptoConnector } from './lpRightbarUtils/LMAXCryptoConnector'
import { alpacaSchema, bbookHedged, binanceSchema, getExecSchema, getFeedSchema, leverateSchema, LMAXCryptoClassSchema, schema } from './lpRightbarUtils/shema'
import {
  alpaca,
  binanceInputsOne,
  binanceInputsTwo,
  executionSession,
  feedingSession,
  hedgingPool,
  interactiveBrokersInputs,
  laverate,
  LMAXCrypto,
  synthetic,
} from './lpRightbarUtils/controls'
import {
  isAlpaca,
  isBinance,
  isBitaDxFeedXValley,
  isHedgingBBook,
  isBasedLps,
  isLMAXCrypto,
  isSyntheticLP,
  isHuobi,
  isInteractiveBrokers,
  isHedgingSynthetic,
} from './lpRightbarUtils/lpType'
import { alreadyExist, newObjectLMAXCrypto } from './lpRightbarUtils/utils'
import * as yup from 'yup'
import { buildPartyIdTable } from './lpRightbarUtils/PartyIds'
import { onlyFetchSendercompTargetcomp } from '../../redux/actions/platforms-actions'
import { processError } from '../../utils/fetch-utils'
import { buildMultiselectOptionsFromArray, buildSelectOption, isTrimString } from '../../utils/multiselect-utils'

const LpRightbar: React.FC<IRightbar> = ({ data: { type, params, item } }) => {
  const dispatch = useDispatch()

  const { gateway } = useSelector((state: any) => state.gateways)

  const {
    types,
    lps,
    pool,
    ApiBasedLps,
    hedgingBBook,
    FuturesAllowedValues,
    StockAllowedValues,
    SyntheticIndices,
    SyntheticLps,
    VolumeTransformers,
    HedgingSyntheticSymbolLps,
    HedgingSyntheticIndexLps,
  } = useSelector((state: any) => state.sysLps)

  const execRef = useRef<any>(null)
  const partyIdsRef = useRef<any>(null)
  const feedRef = useRef<any>(null)
  const cryptoRef = useRef<any>(null)
  const [LMAXCryptoConnectors, setLMAXCryptoConnectors] = useState(false)
  const [LMAXCryptoCredentials, setLMAXCryptoCredentials] = useState(false)
  const [count, setCount] = useState(0)
  const [renderCount, setRenderCount] = useState(0)

  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(
    new LpEntity({ ...item, Type: { value: item.Type, label: item.Type === 'LMAXCryptoF' ? 'LMAX-CryptoFutures' : item.Type } }),
    schema,
  )
  const [execState, setExecState, execTouched, setExecTouched, execErrors, isExecValid] = useFormValidation(new LpSession(item.ExecutionSession), getExecSchema())

  const [businessSettings, setBusinessSettings, businessSettingsTouched, setBusinessSettingsTouched, businessSettingsErrors, isBusinessSettingsValid] = useFormValidation(
    new BusinessSettingsEntity(item.BusinessSettings),
    {},
  )

  const [volumeTransformer, setVolumeTransformer, volumeTransformerTouched, setVolumeTransformerTouched, volumeTransformerErrors, isVolumeTransformerValid] = useFormValidation(
    new LpVolumeTransformer({ ...item, AggregationPool: { value: item.AggregationPool, label: item.AggregationPool } }),
    {
      AggregationPool: yup.object().shape({
        value: yup.string().required(),
        label: yup.string().required(),
      }),
    },
  )

  const [hedgedSynthetic, setHedgedSynthetic, hedgedSyntheticTouched, setHedgedSyntheticTouched, hedgedSyntheticErrors, isHedgedSyntheticValid] = useFormValidation(
    { ...item, Feeder: { value: item.Feeder, label: item.Feeder } },
    {
      Feeder: yup.object().shape({
        value: yup.string().required(),
        label: yup.string().required(),
      }),
    },
  )

  const [feedingState, setFeedingState, feedingTouched, setFeedingTouched, feedingErrors, isFeedingValid] = useFormValidation(new LpSession(item.FeedingSession), getFeedSchema())

  const [alpacaState, setAlpacaState, alpacaTouched, setAlpacaTouched, alpacaErrors, isAlpacaValid] = useFormValidation(new LpAlpaca(item), alpacaSchema)

  const [hedgingBBookState, setHedgingBBookState, hedgingBBookTouched, setHedgingBBookTouched, hedgingBBookErrors, isHedgingBBookValid] = useFormValidation(
    new HedgingBBook({ ...item, AggregationPool: { value: item.AggregationPool, label: item.AggregationPool } }),
    bbookHedged,
  )

  const [useDefaultServers, setUseDefaultServers] = useState(item?.UseDefaultServers)
  const [binanceState, setBinanceState, binanceTouched, setBinanceTouched, binanceErrors, isBinanceValid] = useFormValidation(
    new Binance({
      ...item,
      DepthLevels: { value: item?.DepthLevels ?? '5', label: item?.DepthLevels ?? '5' },
      QuotesUpdateInterval: { value: item?.QuotesUpdateInterval ?? '100', label: item?.QuotesUpdateInterval ?? '100' },
      PositionMode: { value: item.PositionMode === 'Net' ? 'Net' : 'LongShort', label: item.PositionMode === 'Net' ? 'Net' : 'Long/Short' },
      TradeMode: { value: item.TradeMode ?? 'Cross', label: item.TradeMode ?? 'Cross' },
    }),
    binanceSchema(
      inputState.Type.value === 'Huobi' ? '1' : FuturesAllowedValues?.MinLeverage,
      inputState.Type.value === 'Huobi' ? '75' : FuturesAllowedValues?.MaxLeverage,
      type,
      inputState.Type.value,
      useDefaultServers,
    ),
  )

  const [interactiveBrokersState, setInteractiveBrokersState, interactiveBrokersTouched, setInteractiveBrokersTouched, interactiveBrokersErrors, isInteractiveBrokersValid] =
    useFormValidation(new InteractiveBrokers(item), {})

  const [syntheticState, setSyntheticState, syntheticTouched, setSyntheticTouched, syntheticErrors, isSyntheticValid] = useFormValidation(
    { QuoteRefreshTimeout: [...SyntheticIndices, ...SyntheticLps]?.filter((item: any) => item.Name === inputState.Name)[0]?.QuoteRefreshTimeout ?? '3600' },
    {
      QuoteRefreshTimeout: yup
        .string()
        .matches(/^[0-9]+$/gi)
        .test('Is positive?', 'ERROR: The number must be greater than 0!', value => value > 0),
    },
  )

  const [leverateState, setLeverateState, leverateTouched, setLeverateTouched, leverateErrors, isLeverateValid] = useFormValidation(new Laverate(item), leverateSchema)

  const [flag, setFlag] = useState(true)

  const [LMAXCryptoOneState, setLMAXCryptoOneState, LMAXCryptoOneTouched, setLMAXCryptoOneTouched, LMAXCryptoOneErrors, isLMAXCryptoOneValid] = useFormValidation(
    new LMAXCryptoClass(newObjectLMAXCrypto(item)),
    flag ? {} : LMAXCryptoClassSchema,
  )

  const findSyntheticFeeder = (inputState: any) => {
    if (inputState.Type.value === 'HedgingSyntheticIndex') {
      return SyntheticIndices.map((item: any) => item.Name)
    } else {
      return SyntheticLps.map((item: any) => item.Name)
    }
  }

  useEffect(() => {
    if (LMAXCryptoOneState.Enabled) {
      setFlag(false)
    } else {
      setFlag(true)
    }
    setUseDefaultServers(binanceState.UseDefaultServers)
  }, [LMAXCryptoOneState.Enabled, binanceState.UseDefaultServers])

  useEffect(() => {
    if (type === 'add') {
      if (inputState.Type.value === 'MorningStar' || inputState.Type.value === 'Leverate' || inputState.Type.value === 'ActivFinancial') {
        setLeverateState((prev: any) => {
          return {
            ...prev,
            LogPath: inputState.Name.length === 0 ? '' : `${inputState.Name}_log`,
          }
        })
      }
    }
  }, [inputState.Name]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (type === 'add') {
      if (inputState.Type.value === 'SyntheticLP') {
        setSyntheticState((prev: any) => {
          return {
            ...prev,
            QuoteRefreshTimeout: '3600',
          }
        })
      }
      setHedgingBBookState((prev: any) => {
        return {
          ...prev,
          AggregationPool: buildSelectOption(pool[0] ? pool[0] : ''),
        }
      })
      setVolumeTransformer((prev: any) => {
        return {
          ...prev,
          AggregationPool: buildSelectOption(pool[0] ? pool[0] : ''),
        }
      })

      setHedgedSynthetic((prev: any) => {
        return {
          ...prev,
          Feeder: buildSelectOption(findSyntheticFeeder(inputState)[0] ? findSyntheticFeeder(inputState)[0] : ''),
        }
      })
    }
  }, [inputState.Type.value]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (type === 'modify') {
      return
    } else {
      if (inputState.Type.value === 'TradeviewPrime' || inputState.Type.value === 'cTrader') {
        execState.TifOverrides.Limit = [new Limit({ Symbol: '*', Mode: { value: 'GTCwithCancel', label: 'GTCwithCancel' } })]
        setExecState({ ...execState })
      } else {
        execState.TifOverrides.Limit = [new Limit({ Symbol: '', Mode: { value: '', label: '' } })]
        setExecState({ ...execState })
      }
    }
  }, [inputState.Type.value]) // eslint-disable-line react-hooks/exhaustive-deps

  const [sendercompTargetcompId, setSendercompTargetcompId] = useState([{ SenderCompId: '', TargetCompId: '' }])
  useEffect(() => {
    onlyFetchSendercompTargetcomp({ ...params })
      .then((data: any) => setSendercompTargetcompId(data))
      .catch((error: Error) => processError(error, dispatch))
  }, [params, gateway, dispatch])

  const [touchedParty, setTouchedParty] = useState<any>({})

  const handleSave = () => {
    setCount(prev => prev + 1)

    const cond1 = isValid()
    const cond2 = isExecValid()
    const cond3 = isFeedingValid()
    const alpacaValid = isAlpacaValid()
    const binanceValid = isBinanceValid()
    const syntheticLP = isSyntheticValid()
    const laverate = isLeverateValid()
    const LMAXCrypto = isLMAXCryptoOneValid()
    const hb = isHedgingBBookValid()
    const vc = isVolumeTransformerValid()
    const hbs = isHedgedSyntheticValid()

    if (!cond2 && !execErrors.PartyIds) {
      if (execRef.current) {
        execRef.current.open()
      }
    }

    if (!cond2 && execErrors.PartyIds) {
      if (execState.PartyIds) {
        execState.PartyIds.forEach((item: any) => {
          if (item.PartyId === undefined && item.PartyIdSource === undefined && item.PartyRole === undefined) {
            setTouchedParty({ PartyId: true, PartyIdSource: true, PartyRole: true })
          }
        })
      }
      if (partyIdsRef.current) {
        partyIdsRef.current.open()
      }
    }

    if (!cond3) {
      if (feedRef.current) {
        feedRef.current.open()
      }
    }

    if (!LMAXCrypto) {
      if (cryptoRef.current) {
        cryptoRef.current.open()
      }
    }
    if (inputState.Type.value === 'Mt4') {
      if (!cond1) {
        return
      }
    } else if (inputState.Type.value === 'Bbook') {
      if (!cond1) {
        return
      }
    } else if (isAlpaca(inputState)) {
      if (!alpacaValid || !cond1) {
        return
      }
    } else if (isBinance(inputState) || isHuobi(inputState)) {
      if (!binanceValid || !cond1) {
        return
      }
    } else if (isLMAXCrypto(inputState)) {
      if (LMAXCryptoOneState?.ConnectorParameters?.Connectors.length === 0) {
        if (!LMAXCrypto || !cond1) {
          cryptoRef.current.open()
          return
        }
      } else {
        if (!LMAXCrypto || !LMAXCryptoConnectors || !LMAXCryptoCredentials || !cond1) {
          cryptoRef.current.open()
          return
        }
      }
    } else if (isHedgingBBook(inputState)) {
      if (hedgingBBookErrors.Thresholds || errors.Name) {
        hedgingBBookState.Thresholds.forEach((item: any) => {
          if (item.Threshold === '') {
            item.Threshold = undefined
          }
        })
        return
      }
      if (!hb || !cond1) {
        return
      }
    } else if (isBasedLps(inputState)) {
      if (!laverate || !cond1) {
        return
      }
    } else if (isSyntheticLP(inputState)) {
      if (!syntheticLP || !cond1) {
        return
      }
    } else if (inputState.Type.value === 'VolumeConverter') {
      if (!cond1 || !vc) {
        return
      }
    } else if (isHedgingSynthetic(inputState)) {
      if (!cond1 || !hbs) {
        return
      }
    } else if (!cond1 || !cond2 || !cond3) {
      return
    }

    let body = {
      ...inputState,
      Type: inputState.Type.value,
      Name: inputState.Name.trim(),
      BusinessSettings: businessSettings,
      FeedingSession: {
        ...feedingState,
        SslProtocols: feedingState.SslProtocols.value,
        IgnoreLogOptions: feedingState.IgnoreLogOptions.value,
        TifOverrides: {
          Market: execState.TifOverrides.Market?.map((item: any) => {
            return {
              TifFrom: item.TifFrom?.value,
              TifTo: item.TifTo?.value,
              Symbol: isTrimString(item?.Symbol ?? ''),
            }
          }),
          Limit: execState.TifOverrides.Limit?.map((item: any) => {
            return {
              Mode: item.Mode?.value,
              Symbol: isTrimString(item?.Symbol ?? ''),
            }
          }),
        },
      },
      ExecutionSession: {
        ...execState,
        IgnoreLogOptions: execState.IgnoreLogOptions.value,
        SslProtocols: execState.SslProtocols.value,
        PartyIds: execState.PartyIds,
        TifOverrides: {
          Market: execState.TifOverrides.Market?.map((item: any) => {
            return {
              TifFrom: item.TifFrom?.value,
              TifTo: item.TifTo?.value,
              Symbol: isTrimString(item?.Symbol ?? ''),
            }
          }),
          Limit: execState.TifOverrides.Limit?.map((item: any) => {
            return {
              Mode: item.Mode?.value,
              Symbol: isTrimString(item?.Symbol ?? ''),
            }
          }),
        },
      },
    }

    if (inputState.Type.value === 'Bbook') {
      body = {
        Name: inputState.Name.trim(),
        Type: inputState.Type.value,
      }
    } else if (inputState.Type.value === 'Mt4') {
      delete body.ExecutionSession
    } else if (inputState.Type.value === 'SyntheticLP' || inputState.Type.value === 'SyntheticIndexLP') {
      body = {
        Name: inputState.Name.trim(),
        Type: inputState.Type.value,
        QuoteRefreshTimeout: syntheticState.QuoteRefreshTimeout,
      }
    } else if (isHedgingBBook(inputState)) {
      body = {
        Name: inputState.Name.trim(),
        Type: inputState.Type.value,
        AggregationPool: hedgingBBookState.AggregationPool === '' ? pool[0] : hedgingBBookState.AggregationPool.value,
        Thresholds: hedgingBBookState.Thresholds.map((item: any) => {
          return {
            ...item,
            Mask: isTrimString(item.Mask),
          }
        }),
      }
    } else if (isAlpaca(inputState)) {
      body = {
        UseDefaultServers: binanceState.UseDefaultServers,
        RestAddress: binanceState.RestAddress,
        SocketApiAddress: binanceState.SocketApiAddress,
        SocketStreamAddress: binanceState.SocketStreamAddress,
        Name: inputState.Name.trim(),
        Type: inputState.Type.value,
        ApiKey: alpacaState.ApiKey,
        ApiSecret: alpacaState.ApiSecret,
        Live: alpacaState.Live,
      }
    } else if (isLMAXCrypto(inputState)) {
      body = {
        Name: inputState.Name.trim(),
        Type: inputState.Type.value,
        BusinessSettings: businessSettings,
        ExecutionSession: {
          ...execState,
          SslProtocols: execState.SslProtocols.value,
          IgnoreLogOptions: execState.IgnoreLogOptions.value,
          PartyIds: execState.PartyIds,
          TifOverrides: {
            Market: execState.TifOverrides.Market?.map((item: any) => {
              return {
                TifFrom: item.TifFrom?.value,
                TifTo: item.TifTo?.value,
                Symbol: isTrimString(item?.Symbol ?? ''),
              }
            }),
            Limit: execState.TifOverrides.Limit?.map((item: any) => {
              return {
                Mode: item.Mode?.value,
                Symbol: isTrimString(item?.Symbol ?? ''),
              }
            }),
          },
        },
        FeedingSession: {
          ...feedingState,
          SslProtocols: feedingState.SslProtocols.value,
          IgnoreLogOptions: feedingState.IgnoreLogOptions.value,
          TifOverrides: {
            Market: execState.TifOverrides.Market?.map((item: any) => {
              return {
                TifFrom: item.TifFrom?.value,
                TifTo: item.TifTo?.value,
                Symbol: isTrimString(item?.Symbol ?? ''),
              }
            }),
            Limit: execState.TifOverrides.Limit?.map((item: any) => {
              return {
                Mode: item.Mode?.value,
                Symbol: isTrimString(item?.Symbol ?? ''),
              }
            }),
          },
        },
        CryptoFutures: LMAXCryptoOneState,
      }
    } else if (isBasedLps(inputState)) {
      body = {
        Name: inputState.Name.trim(),
        Type: inputState.Type.value,
        ApiKey: leverateState.ApiKey,
        ApiSecret: leverateState.ApiSecret,
        LogPath: leverateState.LogPath,
        ReconnectTimeout: leverateState.ReconnectTimeout,
        EnableFeederLogging: leverateState.EnableFeederLogging,
        WebServiceUrl: inputState.Type.value === 'Leverate' ? null : leverateState.WebServiceUrl,
        WebServicePort: leverateState.WebServicePort === '' ? 0 : leverateState.WebServicePort,
      }
    } else if (isBinance(inputState)) {
      body = {
        ...binanceState,
        PositionMode: binanceState.PositionMode.value,
        TradeMode: binanceState.TradeMode.value,
        WebServicePort: binanceState.WebServicePort === '' ? 0 : leverateState.WebServicePort,
        DepthLevels: binanceState.DepthLevels.value,
        QuotesUpdateInterval: binanceState.QuotesUpdateInterval.value,
        Name: inputState.Name.trim(),
        ApiSecret: binanceState.ApiSecret ? binanceState.ApiSecret : null,
        Type: inputState.Type.value,
        Leverage: binanceState.Type === 'BinanceFutures' || binanceState.Type === 'BinanceFuturesCoin' ? binanceState.Leverage : null,
      }
    } else if (isInteractiveBrokers(inputState)) {
      body = {
        ...interactiveBrokersState,
        Name: inputState.Name.trim(),
        ApiSecret: interactiveBrokersState.ApiSecret ? interactiveBrokersState.ApiSecret : null,
        Type: inputState.Type.value,
      }
    } else if (isHuobi(inputState)) {
      body = {
        ...binanceState,
        PositionMode: binanceState.PositionMode.value,
        TradeMode: binanceState.TradeMode.value,
        WebServicePort: binanceState.WebServicePort === '' ? 0 : leverateState.WebServicePort,
        DepthLevels: binanceState.DepthLevels.value,
        QuotesUpdateInterval: binanceState.QuotesUpdateInterval.value,
        Name: inputState.Name.trim(),
        ApiSecret: binanceState.ApiSecret ? binanceState.ApiSecret : null,
        Type: inputState.Type.value,
      }
    } else if (inputState.Type.value === 'VolumeConverter') {
      body = {
        Name: inputState.Name.trim(),
        Type: inputState.Type.value,
        AggregationPool: volumeTransformer.AggregationPool.value,
      }
    } else if (isHedgingSynthetic(inputState)) {
      body = {
        Name: inputState.Name.trim(),
        Type: inputState.Type.value,
        Feeder: hedgedSynthetic.Feeder.value,
      }
    }

    if (type === 'add') {
      if (isAlpaca(inputState)) {
        dispatch(fetchLpsAddBased({ action: '', params, body }))
      } else if (isBasedLps(inputState)) {
        dispatch(fetchLpsAddBased({ action: '', params, body }))
      } else if (isBinance(inputState)) {
        dispatch(fetchLpsAddBased({ action: '', params, body }))
      } else if (isInteractiveBrokers(inputState)) {
        dispatch(fetchLpsAddBased({ action: '', params, body }))
      } else if (isHuobi(inputState)) {
        dispatch(fetchLpsAddBased({ action: '', params, body }))
      } else if (isHedgingBBook(inputState)) {
        dispatch(fetchLpsAddHedgingBBook({ action: '', params, body }))
      } else if (inputState.Type.value === 'SyntheticLP') {
        dispatch(fetchLpsAddSynteticLP({ action: '', params, body }))
      } else if (inputState.Type.value === 'SyntheticIndexLP') {
        dispatch(fetchLpsAddSynteticIndexLP({ action: '', params, body: { QuoteRefreshTimeout: body.QuoteRefreshTimeout, Name: body.Name } }))
      } else if (inputState.Type.value === 'VolumeConverter') {
        dispatch(fetchLpsAddVolumeTransformer({ action: '', params, body }))
      } else {
        dispatch(fetchLpsAdd({ action: type, params, body }))
      }
    } else if (type === 'modify') {
      if (isAlpaca(inputState)) {
        dispatch(fetchLpsUpdateBased({ action: '', params, body }))
      } else if (isBasedLps(inputState)) {
        dispatch(fetchLpsUpdateBased({ action: '', params, body }))
      } else if (isInteractiveBrokers(inputState)) {
        dispatch(fetchLpsUpdateBased({ action: '', params, body }))
      } else if (isBinance(inputState)) {
        dispatch(fetchLpsUpdateBased({ action: '', params, body }))
      } else if (isHuobi(inputState)) {
        dispatch(fetchLpsUpdateBased({ action: '', params, body }))
      } else if (isHedgingBBook(inputState)) {
        dispatch(fetchLpsUpdateHedgingBBook({ action: '', params, body }))
      } else if (inputState.Type.value === 'VolumeConverter') {
        dispatch(fetchLpsModifyVolumeTransformer({ action: '', params, body }))
      } else if (inputState.Type.value === 'SyntheticLP') {
        dispatch(
          fetchLpsUpdateSynteticLP({
            action: '',
            params: {
              lp: body.Name,
              Gateway: params.Gateway,
            },
            body: { QuoteRefreshTimeout: body.QuoteRefreshTimeout },
          }),
        )
      } else if (inputState.Type.value === 'SyntheticIndexLP') {
        dispatch(
          fetchLpsUpdateSynteticIndexLP({
            action: '',
            params: {
              lp: body.Name,
              Gateway: params.Gateway,
            },
            body: { QuoteRefreshTimeout: body.QuoteRefreshTimeout },
          }),
        )
      } else {
        dispatch(fetchLpsUpdate({ action: type, params, body }))
      }
    }
    dispatch(hideRightBar())
  }

  const filterPortExec = sendercompTargetcompId.filter((elem: any) => {
    if (elem?.SenderCompId === item?.ExecutionSession?.SenderCompId && elem?.TargetCompId === item?.ExecutionSession?.TargetCompId) {
      return
    } else {
      return elem
    }
  })

  const filterPortFeed = sendercompTargetcompId.filter((elem: any) => {
    if (elem?.SenderCompId === item?.FeedingSession?.SenderCompId && elem?.TargetCompId === item?.FeedingSession?.TargetCompId) {
      return
    } else {
      return elem
    }
  })

  const execution = executionSession(execState, setExecState, execTouched, setExecTouched, execErrors, type, inputState, gateway, filterPortExec)
  const feeding = feedingSession(feedingState, setFeedingState, feedingTouched, setFeedingTouched, feedingErrors, type, inputState, gateway, filterPortFeed)
  const leverate = laverate(leverateState, setLeverateState, leverateTouched, setLeverateTouched, leverateErrors, inputState)
  const alpacaInputs = alpaca(alpacaState, setAlpacaState, alpacaTouched, setAlpacaTouched, alpacaErrors)
  const LMAXCryptoOne = LMAXCrypto(LMAXCryptoOneState, setLMAXCryptoOneState, LMAXCryptoOneTouched, setLMAXCryptoOneTouched, LMAXCryptoOneErrors)
  const hedgingBBookPool = hedgingPool(hedgingBBookState, setHedgingBBookState, hedgingBBookTouched, setHedgingBBookTouched, hedgingBBookErrors, pool)
  const binanceInputs = binanceInputsTwo(
    binanceState,
    setBinanceState,
    binanceTouched,
    setBinanceTouched,
    binanceErrors,
    FuturesAllowedValues,
    StockAllowedValues,
    inputState,
    type,
  )
  const binanceInputsAccordion = binanceInputsOne(binanceState, setBinanceState, binanceTouched, setBinanceTouched, binanceErrors, inputState)
  const syntheticInputs = synthetic(syntheticState, setSyntheticState, syntheticTouched, setSyntheticTouched, syntheticErrors)
  const interactiveBrokers = interactiveBrokersInputs(
    interactiveBrokersState,
    setInteractiveBrokersState,
    interactiveBrokersTouched,
    setInteractiveBrokersTouched,
    interactiveBrokersErrors,
    type,
  )

  const volumeTransformControl = buildControlsExtTwoPerLine(
    [sselectInput('AggregationPool', buildMultiselectOptionsFromArray(pool), false).errorMessage('Incorrect value')],
    volumeTransformer,
    setVolumeTransformer,
    'lp',
    volumeTransformerTouched,
    setVolumeTransformerTouched,
    volumeTransformerErrors,
  )

  const HedgedSyntheticControl = buildControlsExtTwoPerLine(
    [sselectInput('Feeder', buildMultiselectOptionsFromArray(findSyntheticFeeder(inputState)), false).errorMessage('Incorrect value')],
    hedgedSynthetic,
    setHedgedSynthetic,
    'lp',
    hedgedSyntheticTouched,
    setHedgedSyntheticTouched,
    hedgedSyntheticErrors,
  )

  const itemFilter = ['MetaTrader4', 'MetaTrader5', 'ExtApi', 'FixApi']
  const filterConnectorType = LMAXCryptoOneState?.ConnectorParameters?.Connectors?.map((item: any) => item.ConnectorType)
  const filterTypeDelete = itemFilter?.filter((item: any) => !filterConnectorType?.includes(item))

  const LMAXCryptoConnectorsUI = LMAXCryptoOneState?.ConnectorParameters?.Connectors?.map((item: any, i: number) => {
    item.Id = Math.round(1 - 0.5 + Math.random() * (9999 - 1 + 1))
    if (item.ConnectorType === '') {
      item.ConnectorType = filterTypeDelete[0]
    }
    return (
      <div key={i} className="lpBorder p-3 mt-3">
        <div className="d-flex align-items-center justify-content-center mb-3 ">
          <FormattedMessage id="lp.ConnectorParameters" />
        </div>
        <div className="lpBorder p-3 mb-3">
          <LMAXCryptoConnector
            connector={item}
            setLMAXCryptoOneState={setLMAXCryptoOneState}
            type={type}
            LMAXCryptoOneState={LMAXCryptoOneState}
            filterType={itemFilter}
            LMAXCryptoConnectors={setLMAXCryptoConnectors}
            LMAXCryptoCredentials={setLMAXCryptoCredentials}
            count={count}
            filterTypeDelete={filterTypeDelete}
            setRenderCount={setRenderCount}
            renderCount={renderCount}
          />
        </div>
      </div>
    )
  })

  const handlePlus = () => {
    setCount(0)
    setRenderCount(0)
    if (LMAXCryptoOneState.ConnectorParameters.Connectors.length < 4) {
      const newLMAXCryptoConnectors = [new LMAXCryptoConnectorsClass()].map((item: any) => {
        if (item.disabled === true) {
          item.disabled = false
        }
        return item
      })
      setLMAXCryptoOneState((prev: any) => {
        return {
          ...prev,
          ConnectorParameters: {
            Connectors: [...prev.ConnectorParameters.Connectors, ...newLMAXCryptoConnectors],
          },
        }
      })
    } else {
      return
    }
  }

  //================================================================

  const ref = useRef(null)

  let controls = null
  if (inputState.Type.value === 'Mt4') {
    controls = (
      <Card>
        <Card.Header className="color-dark font-500">
          <FormattedMessage id="lp.Feeding" />
        </Card.Header>
        <Card.Body>
          {buildControlsExtTwoPerLine(
            [textInput('Server'), textInput('Login'), textInput('Password')],
            feedingState,
            setFeedingState,
            'lp',
            feedingTouched,
            setFeedingTouched,
            feedingErrors,
          )}
        </Card.Body>
      </Card>
    )
  } else if (inputState.Type.value === 'Bbook') {
    controls = null
  } else if (inputState.Type.value === 'SyntheticLP') {
    controls = syntheticInputs
  } else if (isAlpaca(inputState)) {
    controls = (
      <div>
        <div>{alpacaInputs}</div>
        <AppAccordion
          item={{
            title: <FormattedMessage id={'Lp.Advanced'} />,
            item: binanceInputsAccordion,
          }}
          style={{ margin: '0 -20px' }}
          isHidden={true}
        />
      </div>
    )
  } else if (isBasedLps(inputState)) {
    controls = leverate
  } else if (isBitaDxFeedXValley(inputState)) {
    controls = <AppAccordion item={{ title: <FormattedMessage id="lp.Feeding" />, item: feeding }} ref={feedRef} style={{ margin: '0 -20px' }} />
  } else if (inputState.Type.value === 'VolumeConverter') {
    controls = volumeTransformControl
  } else if (isBinance(inputState) || isHuobi(inputState)) {
    controls = (
      <div>
        <div>{binanceInputs}</div>
        <AppAccordion
          item={{
            title: <FormattedMessage id={'Lp.Advanced'} />,
            item: binanceInputsAccordion,
          }}
          style={{ margin: '0 -20px' }}
          isHidden={true}
        />
      </div>
    )
  } else if (isInteractiveBrokers(inputState)) {
    controls = (
      <div>
        <div>{interactiveBrokers}</div>
      </div>
    )
  } else if (isHedgingBBook(inputState)) {
    controls = (
      <div>
        <div>{hedgingBBookPool}</div>

        <AppAccordion
          item={{
            title: <FormattedMessage id={'Lp.BbookHedged'} />,
            item: buildOverridesHedgingBBookTable(hedgingBBookState, hedgingBBookErrors, hedgingBBookTouched, setHedgingBBookTouched, setExecState, execState),
          }}
          ref={ref}
          render={ref.current}
          style={{ margin: '0 -20px' }}
          isHidden={false}
        />
      </div>
    )
  } else if (inputState.Type.value === 'ISPrime') {
    controls = (
      <>
        <AppAccordion
          item={{
            title: <FormattedMessage id="lp.Execution" />,
            item: execution,
          }}
          ref={execRef}
          style={{ margin: '0 -20px' }}
        />
        <AppAccordion item={{ title: <FormattedMessage id="lp.Feeding" />, item: feeding }} ref={feedRef} style={{ margin: '0 -20px' }} />
        <AppAccordion
          item={{
            title: <FormattedMessage id={'LPR.Overrides'} />,
            item: [buildOverridesMarketTable(execState, setExecState), buildOverridesLimitTable(inputState, setExecState, execState)],
          }}
          ref={ref}
          render={ref.current}
          style={{ margin: '0 -20px' }}
        />
        <AppAccordion
          item={{
            title: <FormattedMessage id={'LPR.PartyIds'} />,
            item: [buildPartyIdTable(execState, setExecState, touchedParty, setTouchedParty)],
          }}
          ref={partyIdsRef}
          render={partyIdsRef.current}
          style={{ margin: '0 -20px' }}
        />
      </>
    )
  } else if (isLMAXCrypto(inputState)) {
    controls = (
      <>
        {buildControlsExtTwoPerLine(
          [checkboxInput('AutoSwitchDST')],
          businessSettings,
          setBusinessSettings,
          'lp',
          businessSettingsTouched,
          setBusinessSettingsTouched,
          businessSettingsErrors,
        )}
        <AppAccordion
          item={{
            title: <FormattedMessage id="lp.Execution" />,
            item: execution,
          }}
          ref={execRef}
          style={{ margin: '0 -20px' }}
        />
        <AppAccordion item={{ title: <FormattedMessage id="lp.Feeding" />, item: feeding }} ref={feedRef} style={{ margin: '0 -20px' }} />
        <AppAccordion
          item={{
            title: <FormattedMessage id={'LPR.Overrides'} />,
            item: [buildOverridesMarketTable(execState, setExecState), buildOverridesLimitTable(inputState, setExecState, execState)],
          }}
          ref={ref}
          render={ref.current}
          style={{ margin: '0 -20px' }}
        />
        <AppAccordion
          item={{
            title: <FormattedMessage id="lp.LMAXCrypto.Futures" />,
            item: [
              LMAXCryptoOne,
              LMAXCryptoConnectorsUI,
              LMAXCryptoOneState.ConnectorParameters.Connectors.length === 4 ? null : (
                <div key="plus-row" className="cursor-pointer d-flex align-items-center justify-content-center lpPlusHover mt-3" onClick={handlePlus}>
                  <FontAwesomeIcon icon={faPlus} />
                </div>
              ),
            ],
          }}
          ref={cryptoRef}
          render={cryptoRef.current}
          style={{ margin: '0 -20px' }}
        />
      </>
    )
  } else if (inputState.Type.value === 'SyntheticIndexLP') {
    controls = syntheticInputs
  } else if (isHedgingSynthetic(inputState)) {
    controls = HedgedSyntheticControl
  } else {
    controls = (
      <>
        {buildControlsExtTwoPerLine(
          [checkboxInput('AutoSwitchDST')],
          businessSettings,
          setBusinessSettings,
          'lp',
          businessSettingsTouched,
          setBusinessSettingsTouched,
          businessSettingsErrors,
        )}
        <AppAccordion
          item={{
            title: <FormattedMessage id="lp.Execution" />,
            item: execution,
          }}
          ref={execRef}
          style={{ margin: '0 -20px' }}
        />
        <AppAccordion item={{ title: <FormattedMessage id="lp.Feeding" />, item: feeding }} ref={feedRef} style={{ margin: '0 -20px' }} />
        <AppAccordion
          item={{
            title: <FormattedMessage id={'LPR.Overrides'} />,
            item: [buildOverridesMarketTable(execState, setExecState), buildOverridesLimitTable(inputState, setExecState, execState)],
          }}
          ref={ref}
          render={ref.current}
          style={{ margin: '0 -20px' }}
        />
      </>
    )
  }

  const setName = (newState: any) => {
    const partialState = {
      FileStorePath: (newState.Name && newState.Name + '_store') || '',
      FileLogPath: (newState.Name && newState.Name + '_log') || '',
    }
    const binanceNewState = {
      LogPath: (newState.Name && newState.Name + '_log') || ' ',
    }

    setInputState(newState)
    setExecState({ ...execState, ...partialState })
    setFeedingState({ ...feedingState, ...partialState })
    setBinanceState({ ...binanceState, ...binanceNewState })
    setInteractiveBrokersState({ ...interactiveBrokersState, LogPath: (newState.Name && newState.Name + '_log') || ' ' })
  }

  const setType = (newState: any) => {
    if (newState.Type.value === 'IG' || newState.Type.value === 'Bequant') {
      setExecState({ ...execState, BeginString: 'FIXT.1.1' })
      setFeedingState({ ...feedingState, BeginString: 'FIXT.1.1' })
    } else if (newState.Type.value === 'IGDMA') {
      setExecState({ ...execState, BeginString: 'FIX.4.2' })
      setFeedingState({ ...feedingState, BeginString: 'FIXT.1.1' })
    } else if (newState.Type.value === 'CubicFX') {
      setExecState({ ...execState, BeginString: 'FIX.4.3' })
      setFeedingState({ ...feedingState, BeginString: 'FIX.4.3' })
    } else if (newState.Type.value === 'HantecMarkets') {
      setExecState({ ...execState, BeginString: 'FIX.4.3' })
      setFeedingState({ ...feedingState, BeginString: 'FIX.4.3' })
    } else if (newState.Type.value === 'TypeC' || newState.Type.value === 'CFI') {
      setExecState({ ...execState, BeginString: 'FIX.4.3' })
      setFeedingState({ ...feedingState, BeginString: 'FIX.4.3' })
    } else if (newState.Type.value === 'Euronext') {
      setExecState({ ...execState, BeginString: 'FIX.4.2' })
      setFeedingState({ ...feedingState, BeginString: 'FIX.4.2' })
    } else if (newState.Type.value === 'Solid') {
      setExecState({ ...execState, BeginString: 'FIX.4.2' })
      setFeedingState({ ...feedingState, BeginString: 'FIX.4.2' })
    } else {
      setExecState({ ...execState, BeginString: 'FIX.4.4' })
      setFeedingState({ ...feedingState, BeginString: 'FIX.4.4' })
    }
    setInputState(newState)
  }

  return (
    <>
      <Card style={{ borderBottom: 'none' }}>
        <Card.Header className="color-dark font-500">
          <FormattedMessage id={type !== 'modify' ? 'lp.Title' : 'lp.modifyTitle'} />
        </Card.Header>
        <Card.Body style={{ paddingBottom: '0', borderBottom: 'none' }}>
          {buildControlsExtTwoPerLine(
            [
              textInput('Name')
                .disabled(type === 'modify')
                .errorMessage(
                  alreadyExist(
                    type,
                    [
                      ...lps,
                      ...SyntheticLps,
                      ...ApiBasedLps,
                      ...hedgingBBook,
                      ...SyntheticIndices,
                      ...VolumeTransformers,
                      ...HedgingSyntheticSymbolLps,
                      ...HedgingSyntheticIndexLps,
                    ],
                    inputState,
                    errors,
                  ),
                )
                .stateSetup(setName),
              sselectInput('Type', buildMultiselectOptionsFromArray(types)).disabled(type !== 'add'),
            ],
            inputState,
            setType,
            'lp',
            touched,
            setTouched,
            errors,
          )}
          {controls}
        </Card.Body>
      </Card>
      <Button className="t4b-bg-dark-button my-3 ml-20" onClick={handleSave} onMouseDown={handleSave}>
        <FormattedMessage id="save" tagName="span" />
      </Button>
    </>
  )
}
export default LpRightbar
