export function round(num: number | string): number {
  if (typeof num === 'number') {
    return parseFloat(num.toFixed(2))
  }

  return parseFloat(num)
}

export function expToNum(value: number | string): string {
  if (typeof value === 'number') {
    if (value.toString().includes('e')) {
      const expString = value.toExponential()
      let [mant, exp] = expString.split('e')
      let [, b] = mant.split('.')

      return value.toFixed(Math.abs(parseInt(exp)) + (b?.length ?? 0))
    }
    return value.toString()
  }
  return value
}
