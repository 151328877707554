import React from 'react'
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import Select from 'react-select'

interface ISearchableSelectInput {
  state: any
  name: string
  options: any
  touched?: any
  errors?: any
  errorText?: string
  label?: string
  isDisabled?: boolean
  className?: string
  styles?: any
  placeholder?: string

  setState(newState: any): any
  onClick?(event: any): any
  setTouched?(touched: any): any
  errMsgText?: string
  isSearchable?: boolean
  zIndex?: boolean
  tooltip?: string
  tooltipFlag?: boolean
  zIndexNumber?: any
}

const SearchableSelectInput: React.FC<ISearchableSelectInput> = ({
  state,
  errMsgText,
  setState,
  name,
  options,
  label,
  className,
  styles,
  isDisabled,
  onClick,
  placeholder,
  errors,
  errorText,
  touched,
  setTouched,
  isSearchable,
  zIndex,
  tooltip,
  tooltipFlag,
  zIndexNumber,
}) => {
  const handleChange = (event: any) => {
    setState({
      ...state,
      [name]: event,
    })
  }

  const handleBlur = () => {
    setTouched &&
      setTouched({
        ...touched,
        [name]: true,
      })
  }

  const isInvalid = touched && touched[name] && errors && errors[name]

  const renderTooltip = (props?: any, tooltip?: string) => (
    <Tooltip id="button-tooltip" {...props}>
      {tooltip}
    </Tooltip>
  )

  const filterOptions = (candidate: { label: string; value: string; data: any }, input: string) => {
    if (input) {
      return candidate?.data?.aliases?.find((item: string) => item.toUpperCase().includes(input.toUpperCase())) || candidate?.label?.toUpperCase().includes(input.toUpperCase())
    }
    return true
  }

  return (
    <>
      {tooltipFlag ? (
        <OverlayTrigger placement="bottom" delay={{ show: 500, hide: 400 }} overlay={renderTooltip({}, tooltip)}>
          <Form.Group className={className} style={zIndex ? { zIndex: zIndexNumber } : {}} onClick={onClick}>
            {label && (
              <Form.Label>
                <FormattedMessage id={label} tagName="span" />:
              </Form.Label>
            )}
            <div className={errMsgText && `d-flex justify-content-between`}>
              <div style={errMsgText ? { width: '100%' } : {}} className={`${isInvalid && 'invalid-feedback-select'}`}>
                <Select
                  filterOption={filterOptions}
                  styles={styles}
                  options={options}
                  name="symb"
                  value={state[name]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isDisabled={isDisabled}
                  placeholder={placeholder}
                  isSearchable={true}
                />
              </div>
              <div className={errMsgText ? 'colorText' : ''}>{errMsgText}</div>
            </div>
            <div className={`invalid-feedback ${isInvalid && 'd-block'}`}>
              <FormattedMessage id={errorText || 'field-validation-error'} tagName="span" />
            </div>
          </Form.Group>
        </OverlayTrigger>
      ) : (
        <Form.Group className={className} style={zIndex ? { zIndex: zIndexNumber } : {}} onClick={onClick}>
          {label && (
            <Form.Label>
              <FormattedMessage id={label} tagName="span" />:
            </Form.Label>
          )}
          <div className={errMsgText && `d-flex justify-content-between`}>
            <div style={errMsgText ? { width: '100%' } : {}} className={`${isInvalid && 'invalid-feedback-select'}`}>
              <Select
                filterOption={filterOptions}
                styles={styles}
                options={options}
                name="symb"
                value={state[name]}
                onChange={handleChange}
                onBlur={handleBlur}
                isDisabled={isDisabled}
                placeholder={placeholder}
                isSearchable={true}
              />
            </div>
            <div className={errMsgText ? 'colorText' : ''}>{errMsgText}</div>
          </div>
          <div className={`invalid-feedback ${isInvalid && 'd-block'}`}>
            <FormattedMessage id={errorText || 'field-validation-error'} tagName="span" />
          </div>
        </Form.Group>
      )}
    </>
  )
}

export default React.memo(SearchableSelectInput)
