import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { hideModal } from '../../redux/actions/modal-actions'

import { buildControlsExtTwoPerLine, cmselectInput, textInput } from '../../utils/controls'
import { useFormValidation } from '../../hooks/useFormValidation'

const ModalSymbolsMap: React.FC = () => {
  const dispatch = useDispatch()
  const { modalData } = useSelector((state: any) => state.modals)
  const [inputState, setInputState, touched, setTouched, errors] = useFormValidation({ Mask: '*', Suffixes: [] }, {})

  const handleYes = () => {
    if (modalData.onYes) {
      modalData.onYes()
      modalData.fetch(modalData.gateway, modalData.platform, inputState)
    }
    dispatch(hideModal())
  }

  const handleNo = () => {
    dispatch(hideModal())
  }

  const headerText = typeof modalData.headerText === 'function' ? modalData.headerText() : <FormattedMessage id={modalData.headerText} />
  const bodyText =
    typeof modalData.bodyText === 'function' ? modalData.bodyText() : 'Are you sure you want to load symbol map? All unsaved changes will be lost.'

  const controls = buildControlsExtTwoPerLine(
    [textInput('Mask', false, 'Add Mask', 'col-12'), cmselectInput('Suffixes', []).holder('Insert suffix with delimiter').title('Add suffix:')],
    inputState,
    setInputState,
    'symbolMap',
    touched,
    setTouched,
    errors,
  )

  return (
    <Modal show={true} onHide={handleNo} centered>
      <Modal.Header closeButton={true} className="color-dark font-500">
        {headerText}
      </Modal.Header>
      <Modal.Body>
        {modalData.bodyText ? (
          <div className="d-flex align-items-center justify-content-center text-center">
            <div className="d-flex align-items-center justify-content-center" style={{ color: 'red', marginBottom: '1rem', width: 320 }}>
              {bodyText}
            </div>
          </div>
        ) : null}
        {controls}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleYes}>
          <FormattedMessage id="Load and map" />
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default React.memo(ModalSymbolsMap)
