import { buildSelectOption } from '../utils/multiselect-utils'
import { IMultiSelectItem } from './multiselect'

export enum SwitchTriggerType {
  Currency = 'Currency',
  Points = 'Points',
  BalancePercent = 'BalancePercent',
}
export function convert(option: string) {
  if (option === SwitchTriggerType.Currency) {
    return 'PnL in acc currency'
  }
  if (option === SwitchTriggerType.Points) {
    return 'Price change in points'
  }
  if (option === SwitchTriggerType.BalancePercent) {
    return 'PnL percent of balance'
  }
  return option
}

export function convertObj(option: string) {
  if (option === SwitchTriggerType.Currency) {
    return { label: 'PnL in acc currency', value: SwitchTriggerType.Currency }
  }
  if (option === SwitchTriggerType.Points) {
    return { label: 'Price change in points', value: SwitchTriggerType.Points }
  }
  if (option === SwitchTriggerType.BalancePercent) {
    return { label: 'PnL percent of balance', value: SwitchTriggerType.BalancePercent }
  }
  return { label: option, value: option }
}
export class AutoSwitchEntity {
  Id: number
  RuleName: string
  Groups: string
  Accounts: string
  Symbol: IMultiSelectItem
  NewProcessor: IMultiSelectItem
  OriginalPool: IMultiSelectItem
  SwitchToPool: IMultiSelectItem
  SwitchTriggerType: IMultiSelectItem
  SwitchTriggerDirection: IMultiSelectItem
  SwitchTriggerValue: number
  ReturnTriggerType: IMultiSelectItem
  ReturnTriggerDirection: IMultiSelectItem
  ReturnTriggerValue: number
  AggregatedSwitch: boolean

  constructor(item: any) {
    this.Id = item.Id ?? 0
    this.RuleName = item.RuleName
    this.Groups = item.Groups
    this.Accounts = item.Accounts
    this.Symbol = buildSelectOption(item.Symbol)
    this.NewProcessor = buildSelectOption(item.NewProcessor)
    this.OriginalPool = buildSelectOption(item.OriginalPool)
    this.SwitchToPool = buildSelectOption(item.SwitchToPool)
    this.SwitchTriggerType = item.SwitchTriggerType
    this.SwitchTriggerDirection = buildSelectOption(item.SwitchTriggerDirection) || buildSelectOption('Greater')
    this.SwitchTriggerValue = item.SwitchTriggerValue ?? 0
    this.ReturnTriggerType = item.ReturnTriggerType
    this.ReturnTriggerDirection = buildSelectOption(item.ReturnTriggerDirection) || buildSelectOption('Greater')
    this.ReturnTriggerValue = item.ReturnTriggerValue ?? 0
    this.AggregatedSwitch = item.AggregatedSwitch ?? false
  }
}
