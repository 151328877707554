import { round } from '../utils/num-utils'

interface IChartSeries {
  name: string
  data: number[][]
}

function roundSeries(series: IChartSeries[]): IChartSeries[] {
  if (Array.isArray(series)) {
    return series.map((item: IChartSeries) => ({
      color: item.name === 'A-book' ? '#6FC1BC' : item.name === 'B-book' ? '#2B778D' : '#2CAFFE',
      name: item.name === 'C-book' ? 'A/B Risk' : item.name,
      data: item.data.map((elem: number[]) => {
        return [elem[0], round(elem[1])]
      }),
    }))
  }

  return []
}

export class SummaryEntity {
  TotalAccounts: number
  AbookVolume: number
  BbookVolume: number
  CBookVolume: number
  AbookBrokerProfit: number
  BbookBrokerProfit: number
  AbookRiskProfit: number
  BbookRiskProfit: number
  ABRiskProfit: number
  TotalBrokerProfit: number
  TotalDealsCount: number
  ClientProfit: number
  Series: IChartSeries[]
  TotalVolume: number

  constructor(item?: any) {
    if (item) {
      this.TotalAccounts = item.TotalAccounts
      this.TotalDealsCount = item.TotalDealsCount
      this.AbookVolume = round(item.AbookVolume)
      this.BbookVolume = round(item.BbookVolume)
      this.CBookVolume = round(item.CBookVolume)
      this.ClientProfit = round(item.ClientProfit)
      this.AbookBrokerProfit = round(item.AbookBrokerProfit)
      this.BbookBrokerProfit = round(item.BbookBrokerProfit)
      this.AbookRiskProfit = round(item.AbookRiskProfit)
      this.BbookRiskProfit = round(item.BbookRiskProfit)
      this.ABRiskProfit = round(item.ABRiskProfit)
      this.Series = roundSeries(item.Series)
      this.TotalBrokerProfit = round(item.TotalBrokerProfit)
      this.TotalVolume = round(item.TotalVolume)
    } else {
      this.TotalAccounts = 0
      this.TotalDealsCount = 0
      this.AbookVolume = 0
      this.BbookVolume = 0
      this.CBookVolume = 0
      this.ClientProfit = 0
      this.AbookBrokerProfit = 0
      this.BbookBrokerProfit = 0
      this.AbookRiskProfit = 0
      this.BbookRiskProfit = 0
      this.ABRiskProfit = 0
      this.Series = []
      this.TotalBrokerProfit = 0
      this.TotalVolume = 0
    }
  }
}
