import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { withTooltipPreventOverflow } from './ConfTable'
import classNames from 'classnames'

interface ISaveTableProps {
  items: Array<{ translateKey: string; callback: object }> | undefined
  className?: string
  icon: IconProp
  flag?: boolean
  text?: string
  setId?: any
  depthFlag?: boolean
}

const SaveTable: React.FC<ISaveTableProps> = ({ className, icon, items, flag, depthFlag, text, setId }) => {
  function fillValues() {
    if (items !== undefined) {
      return items.map((item: any, index: any) => {
        const wrapper = async () => {
          setShow(!show)
          if (item?.id) {
            await setId(item?.id)
            await item.callback(item?.id)
          } else {
            item.callback()
          }
        }

        return (
          <div key={index}>
            {item.flag ? (
              <label className="d-flex align-items-center justify-content-center dropdown-item input-file">
                <FormattedMessage id={item.translateKey} />
                <input
                  type="file"
                  onChange={e => {
                    setShow(!show)
                    item.onChange(e)
                  }}
                  onClick={(event: any) => {
                    event.target.value = null
                  }}
                  hidden
                />
              </label>
            ) : (
              <Dropdown.Item onSelect={wrapper} key={item.translateKey}>
                <FormattedMessage id={item.translateKey} />
              </Dropdown.Item>
            )}
          </div>
        )
      })
    }
    return null
  }
  const [show, setShow] = useState(false)
  function handleToogle(isOpen: boolean, event: any, metadata: { source: 'select' | 'click' | 'rootClose' | 'keydown' }) {
    if (metadata.source === 'select') {
      return
    }
    setShow(!show)
  }

  return (
    <>
      <Dropdown className={classNames(className)} drop="left" show={show} onToggle={handleToogle} style={{ cursor: 'pointer' }}>
        <Dropdown.Toggle variant="success" id="dropdown-basic" as="span" style={{ color: '#8dabc4' }}>
          {React.useMemo(
            () =>
              withTooltipPreventOverflow(
                <div
                  style={{
                    width: '28px',
                    height: '28px',
                    borderRadius: '4px',
                    color: 'white',
                    cursor: 'pointer',
                  }}
                  className="d-flex justify-content-center align-items-center mb-2 mr-2 btn-conf"
                >
                  <FontAwesomeIcon icon={icon} />
                </div>,
                flag ? `import-options-${text}` : depthFlag ? 'depthSettings' : `save options ${text}`,
                'save-options',
              ),
            [], // eslint-disable-line react-hooks/exhaustive-deps
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu>{fillValues()}</Dropdown.Menu>
      </Dropdown>
    </>
  )
}

export default SaveTable
