import React, { memo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faFile, faRepeat } from '@fortawesome/free-solid-svg-icons'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { downloadRegenerateReportFile, downloadReportAllFile, downloadReportFile, fetchReportRules } from '../../../redux/actions/reports-actions'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../redux/reducers/rootReducer'

export const GeneratedFiles = memo(({ item, platformName }: any) => {
  const dispatch = useDispatch()
  const { Reports } = useSelector((state: RootState) => state.Reports)
  const { gateway } = useSelector((reduxState: RootState) => reduxState.gateways)
  const fetchReport = (elem: any) => {
    dispatch(
      downloadReportFile({
        Gateway: gateway.Name,
        Platform: platformName,
        ScheduleRuleName: item.ScheduleRuleName,
        fileName: elem.Name,
      }),
    )
  }

  const fetchReportRegenerate = async (elem: any) => {
    await dispatch(
      downloadRegenerateReportFile({
        Gateway: gateway.Name,
        Platform: platformName,
        ScheduleRuleName: item.ScheduleRuleName,
        fileName: elem.Name,
      }),
    )

    await dispatch(
      fetchReportRules({
        Gateway: gateway.Name,
        Platform: platformName,
      }),
    )
  }

  const fetchReportAll = () => {
    dispatch(
      downloadReportAllFile({
        Gateway: gateway.Name,
        Platform: platformName,
        ScheduleRuleName: item.ScheduleRuleName,
      }),
    )
  }

  const renderTooltip = (props?: any, tooltip?: string) => (
    <Tooltip id="button-tooltip" {...props}>
      {tooltip}
    </Tooltip>
  )

  const reports = Reports.find((elem: any) => elem.ScheduleRuleName === item?.ScheduleRuleName)

  return (
    <>
      {reports?.ReportFiles.map((item: { Name: string; CanRegenerate: boolean }) => {
        return (
          <div className="d-flex align-items-center justify-content-between">
            <div className="reportNameDownload mb-2">
              <span className="align-items-center">
                <FontAwesomeIcon icon={faFile} className="mr-2 icon-faFile" />
                <span style={{ color: item.Name.includes('failed') ? 'red' : 'black' }}>{item.Name}</span>
              </span>
            </div>
            <span>
              {item.CanRegenerate && (
                <OverlayTrigger placement="top" delay={{ show: 500, hide: 400 }} overlay={renderTooltip({}, 'Regenerate report')}>
                  <FontAwesomeIcon icon={faRepeat} className="cursor-pointer icon-faDownload mr-2" onClick={() => fetchReportRegenerate(item)} />
                </OverlayTrigger>
              )}
              <OverlayTrigger placement="top" delay={{ show: 500, hide: 400 }} overlay={renderTooltip({}, 'Download report')}>
                <FontAwesomeIcon icon={faDownload} className="cursor-pointer icon-faDownload" onClick={() => fetchReport(item)} />
              </OverlayTrigger>
            </span>
          </div>
        )
      })}
      <Button className="t4b-bg-dark-button mt-3" onClick={fetchReportAll}>
        <FormattedMessage id="DownloadAll" />
      </Button>
    </>
  )
})
