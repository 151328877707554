import React from 'react'
import { FormattedMessage } from 'react-intl'

export function breadcrumbItems(pathname: string, except?: string[]): any[] {
  const partedPath = pathname
    .split('/')
    .filter((item: any) => item !== '')
    .map((item: any) => ({ url: '/' + item, text: 'breadcrumb.' + item }))
  const breadcrumbStrings = partedPath.reduce(
    (overall: any, item: any) => {
      const path = overall.path + item.url
      return { path, pathes: [...overall.pathes, { url: path, text: <FormattedMessage id={item.text} tagName="span" /> }] }
    },
    { path: '', pathes: [] },
  )
  const exceptions = except || []
  return breadcrumbStrings.pathes.filter((path: any) => !exceptions.find((p: string) => p === path.url))
}
