import React, { useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { changeExecutionLp, executionConfigurationChanged } from '../../redux/actions/execution/execution-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { buildControlsExtTwoPerLine, checkboxInput, selectInput, textInput } from '../../utils/controls'

interface IExecutionLiquidityProviderRightbar {
  data: any
  lpTypes: string[]
}

const ExecutionLpARightbar: React.FC<IExecutionLiquidityProviderRightbar> = ({ data: { type, item }, lpTypes }) => {
  const [inputState, setInputState] = useState(item)
  const dispatch = useDispatch()

  const handleClick = () => {
    const data = { ...inputState }
    dispatch(changeExecutionLp(data))
    dispatch(executionConfigurationChanged())
    dispatch(hideRightBar())
  }

  return (
    <Card>
      <Card.Header className="color-dark font-500">
        <FormattedMessage id={`execution-lp.${type}`} />
      </Card.Header>
      <Card.Body>
        {buildControlsExtTwoPerLine(
          [textInput('Name').disabled(true), type === 'modify' ? textInput('Type').disabled(true) : selectInput('Type').optionItems(lpTypes).disabled(true), checkboxInput('AllowPartialFilling'), checkboxInput('AllowLimitsOnLP')],
          inputState,
          setInputState,
          'execution-lp',
        )}

        <Button className="t4b-bg-dark-button mt-3" onClick={handleClick}>
          <FormattedMessage id="save" tagName="span" />
        </Button>
      </Card.Body>
    </Card>
  )
}
export default ExecutionLpARightbar
