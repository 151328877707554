class RiskTool {
  public newSwitch: any
  public activeSwitches: any
  public activeRules: any
  public history: any
  public autoSwitch: any
  public CheckTimeout: number | string

  constructor() {
    this.newSwitch = {
      data: [],
      totalCount: 0,
    }

    this.CheckTimeout = 0

    this.activeSwitches = {
      data: [],
      totalCount: 0,
    }
    this.activeRules = {
      data: [],
      totalCount: 0,
    }

    this.history = {
      data: [],
      totalCount: 0,
    }

    this.autoSwitch = {
      data: [],
      totalCount: 0,
    }
  }
}

function riskToolReducer(state = new RiskTool(), action: any) {
  switch (action.type) {
    case 'NEW_SWITCH':
      return {
        ...state,
        newSwitch: {
          ...state.newSwitch,
          data: action.data.Data,
          totalCount: action.data.TotalCount,
        },
      }
    case 'ACTIVE_RULES':
      return {
        ...state,
        activeRules: {
          ...state.activeRules,
          data: action.data.Data,
          totalCount: action.data.TotalCount,
        },
      }
    case 'RISK_HISTORY':
      return {
        ...state,
        history: {
          ...state.history,
          data: action.data.Data,
          totalCount: action.data.TotalCount,
        },
      }
    case 'CHECK_TIMEOUT':
      return {
        ...state,
        CheckTimeout: action.data,
      }
    case 'ACTIVE_SWITCHES':
      return {
        ...state,
        activeSwitches: {
          ...state.activeSwitches,
          data: action.data.Data,
          totalCount: action.data.TotalCount,
        },
      }
    case 'AUTO_SWITCH':
      return {
        ...state,
        autoSwitch: {
          ...state.autoSwitch,
          data: action.data.Data,
          totalCount: action.data.TotalCount,
        },
      }
    default:
      return state
  }
}

export default riskToolReducer
