import React from 'react'
import { FormattedMessage } from 'react-intl'
import { toast, UpdateOptions } from 'react-toastify'

const bottomRightToastPosition = { position: toast.POSITION.BOTTOM_RIGHT }

export function toastPosition(): UpdateOptions {
  return bottomRightToastPosition
}

export function throwErrorMessage(message?: any, flag?: boolean): void {
  if (message) {
    if (message?.length > 100) {
      toast.error(<FormattedMessage id={`ERROR: ${message}`} />, bottomRightToastPosition)
    } else {
      flag ? toast.error(<FormattedMessage id={`${message}`} />, bottomRightToastPosition) : toast.error(<FormattedMessage id={`api.error.${message}`} />, bottomRightToastPosition)
    }
  } else {
    return
  }
}

export function throwErrorMessageImport(message: string): void {
  toast.error(<FormattedMessage id={`ERROR: ${message}`} />, bottomRightToastPosition)
}

export function throwSuccessMessage(message: string): void {
  toast.success(<FormattedMessage id={message} />, bottomRightToastPosition)
}

export function throwWarnMessage(message: string): void {
  toast.warn(<FormattedMessage id={`api.error.${message}`} />, bottomRightToastPosition)
}
export function showToast(message: string, values: any): void {
  toast.success(<FormattedMessage id={message} values={values} />, bottomRightToastPosition)
}

export function showToastError(message: string, values: any): void {
  toast.error(<FormattedMessage id={message} values={values} />, bottomRightToastPosition)
}
