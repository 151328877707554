import React from 'react'
import { IRightbar } from '../rightbar-types'
import { useDispatch } from 'react-redux'
import { useFormValidation } from '../../../hooks/useFormValidation'
import * as yup from 'yup'
import { hideRightBar } from '../../../redux/actions/rightbar-actions'
import { Button, Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { buildControlsExtTwoPerLine, textInput } from '../../../utils/controls'
import { fetchHedgedConfig, fetchHedgedSyntheticVolume } from '../../../redux/actions/system-settings-actions'
import { transformGateway } from '../../../utils/fetch-utils'

const HedgedSyntheticVolumeRightbar: React.FC<IRightbar> = React.memo(({ data: { item, LpType, gateway, setLoading, lp } }) => {
  const dispatch = useDispatch()
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation({ ...item, Symbol: item.Symbol, Threshold: item.Threshold ? item.Threshold : '0' }, { Threshold: yup.number().positive().required() })

  const handleClick = async () => {
    if (!isValid()) {
      return
    }

    const body = [
      {
        Symbol: inputState.Symbol,
        Threshold: inputState.Threshold,
      },
    ]

    await dispatch(fetchHedgedSyntheticVolume(LpType, body, transformGateway(gateway), { lp: lp }))
    await dispatch(fetchHedgedConfig(setLoading, LpType, transformGateway(gateway), { lp: lp }))
    dispatch(hideRightBar())
  }

  return (
    <Card>
      <Card.Header className="color-dark font-500">
        <FormattedMessage id={`volume.comul.modify`} />
      </Card.Header>
      <Card.Body>
        {buildControlsExtTwoPerLine([textInput('Symbol').disabled(true), textInput('Threshold')], inputState, setInputState, 'volumeHedg', touched, setTouched, errors)}

        <Button className="t4b-bg-dark-button mt-3" onClick={handleClick}>
          <FormattedMessage id="save" />
        </Button>
      </Card.Body>
    </Card>
  )
})

export default HedgedSyntheticVolumeRightbar
