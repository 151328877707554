export interface IMultiSelectItem {
  label: string
  value: string
}

export class MultiselectItem implements IMultiSelectItem {
  public label: string
  public value: string

  constructor(item?: string) {
    this.label = item || ''
    this.value = item || ''
  }
}
