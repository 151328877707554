import { GatewaySettingsEntity, SmtpEntity } from '../../entity/system-settings'
import { Action } from '../actions/actions'

class State {
  public gateway: GatewaySettingsEntity
  public database: any
  public smtp: SmtpEntity

  constructor() {
    this.gateway = new GatewaySettingsEntity()
    this.database = {}
    this.smtp = new SmtpEntity()
  }
}

function commonSettingsReducer(state = new State(), action: any) {
  switch (action.type) {
    case Action.GotGateway:
      return {
        ...state,
        gateway: action.data,
      }
    case Action.GotDatabase:
      return {
        ...state,
        database: action.data,
      }
    case Action.GotSmtp:
      return {
        ...state,
        smtp: action.data,
      }
    default:
      return state
  }
}

export default commonSettingsReducer
