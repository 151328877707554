export const urlPaths: any = {
  '/api/lps': '/api/lps',
  '/api/systemSettings/commonGateway': '/api/systemSettings/commonGateway',
  '/api/systemSettings/commonDatabase': '/api/systemSettings/commonDatabase',
  '/api/platforms': '/api/platforms',
  '/api/platform/sessions': '/api/platform/sessions',
  '/api/platform/symbolMap': '/api/platform/symbolMap',
  '/api/platform/accounts': '/api/platform/accounts',
  '/api/platform/CustomSymbolSettings': '/api/platform/CustomSymbolSettings',
  '/api/platform/swaps': '/api/platform/swaps',
  '/api/platform/fixSymbolMap': '/api/platform/fixSymbolMap',
  '/api/users': '/api/users',
  '/api/dropCopy/sessions': '/api/dropCopy/sessions',
  '/api/dropCopy/settings': '/api/dropCopy/settingsModify',
  '/api/backups/rules': '/api/backups/setRules',
  '/api/execution': '/api/execution/update',
  '/api/feeding': '/api/feeding/update',
  '/api/reportGenerator/GetRules': '/api/reportGenerator/SetRule',
  '/api/notification/receivers': '/api/notification/updateReceiver',
  '/api/notification/channels': '/api/notification/updatechannels',
  '/api/notification/rules': '/api/notification/updateRule',
  '/api/lp/symbolMap': '/api/lp/symbolMap',
  '/api/consolidation/rules': '/api/consolidation/rules',
  '/api/continuousExecution/rules': '/api/continuousExecution/rules',
  '/api/riskTool/autoSwitch': '/api/riskTool/autoSwitch',
  '/api/globalSymbolMap': '/api/globalSymbolMap',
  '/api/globalSymbolMap/export': '/api/globalSymbolMap/export',
}
