import { Action } from '../actions/actions'

function statusReducer(state = { Status: 0, DelayedRebootTime: 0 }, action: any) {
  switch (action.type) {
    case Action.GotStatus:
      return action.data
    default:
      return state
  }
}

export default statusReducer
