import { AccountEntity, FixSymbolEntity, MarginInfoEntity, Swap } from '../../entity/platforms'
import { Action } from '../actions/actions'

class FixConfiguration {
  public fixAccounts: any
  public fixMarginInfo: any
  public fixSwaps: any
  public fixSymbolMap: any

  constructor() {
    this.fixAccounts = {
      data: [],
      totalCount: 0,
    }
    this.fixMarginInfo = {
      data: [],
      totalCount: 0,
    }
    this.fixSwaps = {
      data: [],
      totalCount: 0,
    }
    this.fixSymbolMap = {
      data: [],
      totalCount: 0,
    }
  }
}

function fixConfigurationReducer(state = new FixConfiguration(), action: any) {
  switch (action.type) {
    case Action.GotFixSessions:
      return state
    case Action.GotAccountsProfile:
      return {
        ...state,
        profile: action.data.map((item: { Id: number; Name: string }) => {
          return {
            value: item.Id,
            label: item.Name,
          }
        }),
      }
    case Action.GotFixAccounts:
      return {
        ...state,
        fixAccounts: {
          data: action.data.Data.map((item: any) => new AccountEntity(item)),
          totalCount: action.data.TotalCount,
        },
      }
    case Action.GotFixMarginInfo:
      return {
        ...state,
        fixMarginInfo: {
          data: action.data.Data.map((item: any) => new MarginInfoEntity(item)),
          totalCount: action.data.TotalCount,
        },
      }
    case Action.GotFixSwaps:
      return {
        ...state,
        fixSwaps: {
          data: action.data.Data.map((item: any) => new Swap(item)),
          totalCount: action.data.TotalCount,
        },
      }
    case Action.GotFixSymbolMap:
      return {
        ...state,
        fixSymbolMap: {
          data: action.data.Data.map((item: any) => new FixSymbolEntity(item)),
          totalCount: action.data.TotalCount,
        },
      }
    default:
      return state
  }
}

export default fixConfigurationReducer
