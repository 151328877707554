import React from 'react'
import { Form } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import Select from 'react-select'
import { getPlatformType, PlatformType } from '../../entity/platforms'
import { useSelector } from 'react-redux'

interface ISearchableSelectInput {
  state: any
  name: string
  options: any
  label?: string
  isDisabled?: boolean
  className?: string
  styles?: any
  setLoadingTerminal?: any
  isSearchable?: boolean
  classNamePrefix?: string
  setState(newState: any): any
  onClick?(event: any): any
  allOption?: boolean
  placement?: any
  zIndex?: any
}

const NewSearchableSelectInput: React.FC<ISearchableSelectInput> = ({
  allOption = false,
  state,
  zIndex = 99999999,
  setState,
  name,
  options,
  label,
  className,
  styles,
  isDisabled,
  onClick,
  setLoadingTerminal,
  isSearchable = true,
  classNamePrefix = '',
  placement = 'auto',
}) => {
  const { gateway } = useSelector((state: any) => state.gateways)
  const handleChange = (event: any) => {
    setState({
      ...state,
      [name]: event,
    })
  }

  const setStateExt = (newState: any) => {
    const platformType = getPlatformType(gateway.Platforms, newState.Platform)

    if (platformType !== PlatformType.fixapi && platformType !== PlatformType.extapi) {
      newState = { ...newState, Session: '' }
    } else if (!newState.Session || newState.Platform !== state.Platform) {
      newState = { ...newState, Session: 'All' }
    }
    setState(newState)
  }

  return (
    <Form.Group className={className} onClick={onClick} style={{ zIndex: zIndex }}>
      {label && (
        <Form.Label>
          <FormattedMessage id={label} tagName="span" />:
        </Form.Label>
      )}
      <Select
        className="SearchableSelectInput"
        menuPlacement={placement}
        minMenuHeight={300}
        classNamePrefix={classNamePrefix}
        isSearchable={true}
        styles={styles}
        options={options}
        name={name}
        value={state[name]}
        onChange={allOption ? setStateExt : handleChange}
        isDisabled={isDisabled}
        isFocused={false}
      />
    </Form.Group>
  )
}

export default NewSearchableSelectInput
