import React, { memo, MouseEvent, useCallback } from 'react'
import cls from '../chatBot.module.scss'
import { Button } from './Button'
import { AddMessage } from '../../../redux/reducers/ChatBotReducer'
import { sendMessages } from '../servises'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../redux/reducers/rootReducer'
import { useLocation } from 'react-router-dom'

export const SuggestedQuestions = memo(() => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { SuggestedQuestions, FetchStatus } = useSelector((state: RootState) => state.ChatBot)

  const onSendSuggestedQuestions = useCallback((e: MouseEvent<HTMLElement>) => {
    const value: string = (e.target as HTMLElement).innerText

    dispatch(AddMessage({ text: value, role: 'user' }))
    dispatch(sendMessages({ value: escape(value) }))
  }, [])

  const onSendSuggestedQuestionsTpPage = useCallback(() => {
    dispatch(AddMessage({ text: "What's in the current TP page?", role: 'user' }))
    dispatch(sendMessages({ value: escape(`What is ${pathname}?`) }))
  }, [pathname])

  return (
    <div className={cls.suggestedQuestions}>
      {FetchStatus.loading ? (
        <div className={cls.skeleton} />
      ) : (
        <Button text={"What's in the current TP page?"} cb={onSendSuggestedQuestionsTpPage} cls={cls.msgButtonBot} disabled={FetchStatus.loading || FetchStatus.status !== 200} />
      )}
      {SuggestedQuestions?.length > 0 &&
        SuggestedQuestions.map((item: string) => (
          <React.Fragment key={item}>
            {FetchStatus.loading ? (
              <div className={cls.skeleton} />
            ) : (
              <Button text={item} cb={onSendSuggestedQuestions} cls={cls.msgButtonBot} disabled={FetchStatus.loading || FetchStatus.status !== 200} />
            )}
          </React.Fragment>
        ))}
    </div>
  )
})
