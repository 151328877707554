import { GatewayEntity } from '../../entity/system-settings'
import { Action } from '../actions/actions'

class GatewaysState {
  public gateway: GatewayEntity
  public gateways: GatewayEntity[]

  constructor() {
    this.gateway = new GatewayEntity()
    this.gateways = []
  }
}

function gatewaysReducer(state = new GatewaysState(), action: any): GatewaysState {
  switch (action.type) {
    case Action.GotGateways:
      if (action.data.length) {
        return {
          gateways: action.data.map((item: any) => new GatewayEntity(item)),
          gateway: new GatewayEntity(action.data[0]),
        }
      }
      return new GatewaysState()
    // case Action.FlushGateways:
    //   return new GatewaysState()
    default:
      return state
  }
}

export default gatewaysReducer
