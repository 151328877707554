import { IQuote, IQuoteData } from '../../lp-performance/quotes-types'
import classNames from 'classnames'
import React from 'react'
function getBidDiff(source1: IQuoteData, source2: IQuoteData | undefined | null): any[] {
  return source1.OrderBook.Bid.map((item: IQuote | null, index: number) => {
    const diff: any = {
      VolumeDiff: null,
      PriceDiff: null,
    }

    if (item === null) {
      return null
    }

    if (item && source2?.OrderBook.Bid[index]) {
      diff.VolumeDiff = +(item.Volume - source2.OrderBook.Bid[index].Volume).toFixed(2)
      diff.PriceDiff = +(item.Price - source2.OrderBook.Bid[index].Price).toFixed(5)
    }

    return {
      ...item,
      ...diff,
    }
  })
}

function getAskDiff(source1: IQuoteData, source2: IQuoteData | undefined | null): any[] {
  return source1.OrderBook.Ask.map((item: IQuote | null, index: number) => {
    const diff: any = {
      VolumeDiff: null,
      PriceDiff: null,
    }

    if (item === null) {
      return null
    }

    if (item && source2?.OrderBook.Bid[index]) {
      diff.VolumeDiff = +(item.Volume - source2.OrderBook.Bid[index].Volume).toFixed(2)
      diff.PriceDiff = +(item.Price - source2.OrderBook.Bid[index].Price).toFixed(5)
    }

    return {
      ...item,
      ...diff,
    }
  })
}

export function buildBid(quote: any, depth: number) {
  let quotes: IQuoteData | undefined = quote
  let otherQuotes = null

  if (quotes === undefined) {
    return null
  }

  const data = getBidDiff(quotes, otherQuotes)

  const tds = []
  for (let i = 0; i < depth; i++) {
    const item = data[i]

    tds.push(
      <tr style={{ height: '26px' }} className={classNames(i === 0 && 'best-bid-row-modal')}>
        <td className="border-bottom border-dark">{item?.Volume === undefined ? 'N/A' : item?.Volume < 100000 ? `${item?.Volume} ` : `${item?.Volume / 1000000} M`}</td>
        <td className="border-bottom border-dark color-bid">{item?.Price ?? 'N/A'}</td>
        <td className={item?.Spreed ? 'border-bottom border-dark' : ''}>{item?.Spreed ?? 'N/A'}</td>
      </tr>,
    )
  }
  return tds
}

export function buildAsk(quote: any, depth: number) {
  let quotes: IQuoteData | undefined = quote
  let otherQuotes = null

  if (quotes === undefined) {
    return null
  }

  let notEnough = 0
  let data = getAskDiff(quotes, otherQuotes).slice(0, depth).reverse()
  if (data.length < depth) {
    notEnough = depth - data.length
  }

  const tds = []

  for (let i = 0; i < notEnough; i++) {
    const item: any = null
    tds.push(
      <tr style={{ height: '26px' }}>
        <td className="border-bottom border-dark">{item?.Volume === undefined ? 'N/A' : item?.Volume < 100000 ? `${item?.Volume}` : `${item?.Volume / 1000000} M`}</td>
        <td className="border-bottom border-dark color-ask">{item?.Price ?? 'N/A'}</td>
        <td className={item?.Spreed ? 'border-bottom border-dark' : ''}>{item?.Spreed ?? 'N/A'}</td>
      </tr>,
    )
  }

  for (let i = 0; i < data.length; i++) {
    const item = data[i]

    tds.push(
      <tr style={{ height: '26px' }} className={classNames(i === data.length - 1 && 'best-ask-row-modal')}>
        <td className={item?.Volume ? 'border-bottom border-dark' : ''}>
          {item?.Volume === undefined ? 'N/A' : item?.Volume < 100000 ? `${item?.Volume}` : `${item?.Volume / 1000000} M`}
        </td>
        <td className={item?.Price ? 'border-bottom border-dark color-ask' : ''}>{item?.Price ?? 'N/A'}</td>
        <td className={item?.Spreed ? 'border-bottom border-dark' : ''}>{item?.Spreed ?? 'N/A'}</td>
      </tr>,
    )
  }

  return tds
}
