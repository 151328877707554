import { faChartBar, faCheck, faClock, faClone, faFileDownload, faFileImport, faPlus, faTrashAlt, faWallet } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withTooltip } from '@t4b/core/lib/components/app-utils'
import classNames from 'classnames'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ReactComponent as SelectAllIcon } from '../select-all-icon.svg'

type ButtonType =
  | 'add'
  | 'clone'
  | 'delete'
  | 'delete-all'
  | 'select'
  | 'wallet'
  | 'import'
  | 'trading'
  | 'disabled'
  | 'enabled'
  | 'export-all'
  | 'export-all-tabled'
  | 'add-global-symbol'
  | 'checkTimeout'

class ButtonVariant {
  icon: any
  text: string
  className: string;

  [key: string]: any

  constructor(icon: any, text: string, className: string) {
    this.icon = icon
    this.text = text
    this.className = className
  }
}

interface IAppButton {
  variant: ButtonType
  className?: string

  onClick: any
}

const variants = new Map<ButtonType, ButtonVariant>()

variants.set('add', new ButtonVariant(faPlus, 'add', 'btn-plus'))
variants.set('clone', new ButtonVariant(faClone, 'clone', 'btn-clone'))
variants.set('delete', new ButtonVariant(faTrashAlt, 'delete', 'btn-delete'))
variants.set('delete-all', new ButtonVariant(faTrashAlt, 'delete-all', 'bg-danger'))
variants.set('select', new ButtonVariant(<SelectAllIcon />, 'select-all', 'btn-select'))
variants.set('wallet', new ButtonVariant(faWallet, 'deposit', 'btn-wallet'))
variants.set('import', new ButtonVariant(faFileImport, 'import', 'btn-clone'))
variants.set('trading', new ButtonVariant(faChartBar, 'trading', 'btn-wallet'))
variants.set('export-all', new ButtonVariant(faFileDownload, 'export', 'btn-export'))
variants.set('export-all-tabled', new ButtonVariant(faFileDownload, 'export', 'btn-clone'))
variants.set('add-global-symbol', new ButtonVariant(faPlus, 'add-global-symbol', 'btn-plus-symbols'))
variants.set('checkTimeout', new ButtonVariant(faClock, 'checkTimeoutText', 'btn-plus'))
variants.set('disabled', new ButtonVariant('', 'enabledText', 'btn-delete'))
variants.set('enabled', new ButtonVariant(faCheck, 'disabledText', 'btn-delete'))

const AppButton: React.FC<IAppButton> = ({ onClick, className, variant }) => {
  if (variants.has(variant)) {
    const selectedVariant = variants.get(variant) || new ButtonVariant(faPlus, 'add', 'btn-plus')
    return withTooltip(
      <div onClick={onClick} className={classNames('d-flex justify-content-center align-items-center btn-all ', selectedVariant.className, className)}>
        {variant === 'select' ? selectedVariant.icon : <FontAwesomeIcon icon={selectedVariant.icon} />}
      </div>,
      <FormattedMessage id={selectedVariant.text} />,
      selectedVariant.text,
    )
  }
  return null
}

export default React.memo(AppButton)
