import { Action } from '../actions/actions'
import { getJwt } from '../actions/authentication'

function authReducer(state = !!getJwt(), action: any) {
  switch (action.type) {
    case Action.Unauthorized:
      return false
    case Action.Authorized:
      return true
    default:
      return state
  }
}

export default authReducer
