import axios from 'axios'
import { AddFetchStatus, AddMessage, AddSuggestedQuestions } from '../../redux/reducers/ChatBotReducer'
import { Dispatch } from 'redux'
import { getKeyChatBot } from '../../redux/actions/authentication'

// const id = '1680905287055x920094037663809500'
// const api_key = 'gmyIXCHqKJlzkngCfiClExPSxoexsj'

export const sendMessages = ({ value }: { value: string }) => {
  return async (dispatch: Dispatch) => {
    dispatch(AddFetchStatus({ loading: true }))

    try {
      const { data, status } = await axios.post('https://myaskai.com/api/1.1/wf/ask-ai-chat', {
        id: getKeyChatBot().AIAssistantId,
        api_key: getKeyChatBot().AIAssistantApiKey,
        messages: [{ role: 'user', content: value }],
      })

      dispatch(AddMessage({ text: data.answer, role: 'bot' }))
      dispatch(AddSuggestedQuestions(data.suggestedQuestions ?? data.suggested_questions))
      dispatch(AddFetchStatus({ status }))
    } catch (e) {
      if (axios.isAxiosError(e)) {
        dispatch(AddFetchStatus({ status: e.request.status }))
      }
    }

    dispatch(AddFetchStatus({ loading: false }))
  }
}
