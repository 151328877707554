import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import TextInput from '../../inputs/TextInput'
import MultiSelectInput from '../../inputs/MultiSelectInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { deleteNotificationChannel, updateNotificationChannel } from '../../../redux/reducers/notificationReducer'
import { useDispatch, useSelector } from 'react-redux'
import { useFormValidation } from '../../../hooks/useFormValidation'
import * as yup from 'yup'

export const stylesMulti = {
  multiValue: (styles: any) => {
    return {
      ...styles,
      backgroundColor: '#007BFF',
      color: 'white',
      borderRadius: '5px',
    }
  },
  multiValueLabel: (styles: any) => ({
    ...styles,
    color: 'white',
  }),
  multiValueRemove: (styles: any) => ({
    ...styles,
    color: 'white',
    ':hover': {
      backgroundColor: '#007BFF',
      color: 'white',
      borderRadius: '5px',
      cursor: 'pointer',
    },
  }),
}
const Channel: FC<any> = ({ data, setValid, index }) => {
  const dispatch = useDispatch()
  const { receiver, channel } = useSelector((state: any) => state.NotificationReducer)
  const [update, setUpdate] = useState(false)

  const receivers = useMemo(
    () =>
      data.Receivers.map((item: any) => {
        return {
          value: item.Id,
          label: item.Name,
        }
      }),
    [data],
  )

  const initialValue = useMemo(() => {
    return {
      ...data,
      Receivers: receivers,
    }
  }, [data])

  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(initialValue, { Name: yup.string().required(), Receivers: yup.array().required() })

  useEffect(() => {
    setUpdate(!update)
  }, [])

  useEffect(() => {
    let timeout: any = null

    if (update) {
      timeout = setTimeout(() => {
        if (channel.find((item: any) => item.Name === inputState.Name && item.Id !== inputState.Id)) {
          dispatch(updateNotificationChannel({ ...inputState, valid: true }))
        } else {
          dispatch(updateNotificationChannel(inputState.Id < 0 ? { ...inputState, valid: false } : { ...inputState, Deleted: false }))
        }
      }, 300)
    }

    return () => clearTimeout(timeout)
  }, [inputState])

  useEffect(() => {
    setValid({ ...errors, isValid })
  }, [errors])

  const handelDelete = () => {
    dispatch(deleteNotificationChannel({ Id: inputState.Id }))
  }

  const receiverName = useMemo(
    () =>
      receiver.map((item: any) => {
        return {
          value: item.Id,
          label: item.Name,
        }
      }),
    [receiver],
  )
  const alreadyExistNames = useCallback(() => {
    if (channel.find((item: any) => item.valid && item.Id === inputState.Id)) {
      errors.Name = true
      touched.Name = true
      return 'Group with such name already exists'
    } else if (!inputState.Name.length) {
      return 'Incorrect value'
    }
    return ''
  }, [errors])
  const text = alreadyExistNames()

  return (
    <>
      <div className="d-flex justify-content-between mt-2">
        <TextInput
          key={index}
          state={inputState}
          setState={setInputState}
          touched={touched}
          setTouched={setTouched}
          errors={errors}
          name="Name"
          label="Chanel.Name"
          className="input settings-block__field channelBar"
          errorText={text}
        />
        <MultiSelectInput
          touched={touched}
          setTouched={setTouched}
          errors={errors}
          style={stylesMulti}
          state={inputState}
          setState={setInputState}
          name="Receivers"
          label="Chanel.Receivers"
          options={receiverName}
          className="input settings-block__field channelBar"
        />
        <FontAwesomeIcon icon={faTrashCan} className="ml-2 cursor-pointer align-items-center mt-4 faTrashCanChannel" onClick={handelDelete} />
      </div>
      {channel.length - 1 === index ? null : <div className="dealsOrder mt-2"></div>}
    </>
  )
}

export default Channel
