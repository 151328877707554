export const brandFieldSet = new Set(['CMC', 'PrimeFX', 'Equiti', 'Infinox', 'Amana', 'GBE', 'ADSSo', 'ADSSp', 'AlchemyMarkets', 'ScopeMarkets'])

export const onBehalfOfCompIdFieldSet = new Set(['Equiti', 'Infinox', 'Amana', 'GBE', 'ADSSo', 'ADSSp', 'AlchemyMarkets', 'ScopeMarkets'])

export const saxoClientIdAccountNumber = new Set(['Saxo'])

export const partyIdFieldSet = new Set(['HitBTC'])

export const queryIntervalFieldSet = new Set(['HitBTC'])

export const targetSubIDFieldSet = new Set(['FXCM', 'cTrader', 'CMC'])

export const senderSubIdFieldSet = new Set(['IGDMA', 'StoneX'])
export const senderSubIdFieldSetFeedExec = new Set(['StoneX'])

export const resubscribeOnRejectTimeoutFieldSet = new Set(['Exante'])

export const quoteTextProcessingFieldSet = new Set([
  'LMAXLD',
  'LMAXTY',
  'ScanM',
  'TakerPlus',
  'Orbex',
  'B2Broker',
  'TOPFX',
  'MEXGroup',
  'Broctagon',
  'ThinkMarkets',
  'Tickmill',
  'TypeO',
  'Valutrades',
  'DerivPrime',
])

export const targetBinanceFuturesFieldSet = new Set(['BinanceFutures', 'BinanceFuturesCoin', 'Huobi'])
export const OkxLive = new Set(['OkxFutures', 'Okx'])
export function isAlpaca(obj: any): boolean {
  return obj.Type.value === 'AlpacaMarkets'
}

export function isBitaDxFeedXValley(obj: any): boolean {
  return obj.Type.value === 'Bita' || obj.Type.value === 'DxFeed' || obj.Type.value === 'XValley'
}

export function isBasedLps(obj: any): boolean {
  return obj.Type.value === 'Leverate' || obj.Type.value === 'MorningStar' || obj.Type.value === 'ActivFinancial'
}

export function isHedgingBBook(obj: any): boolean {
  return obj.Type.value === 'BbookHedged'
}

export function isHedgingSynthetic(obj: any): boolean {
  return obj.Type.value === 'HedgingSyntheticIndex' || obj.Type.value === 'HedgingSyntheticSymbol'
}

export function isSyntheticLP(obj: any): boolean {
  return obj.Type.value === 'SyntheticLP' || obj.Type.value === 'SyntheticIndexLP'
}

export function isLMAXCrypto(obj: any): boolean {
  return obj.Type.value === 'LMAXCryptoF'
}

export function isBinance(obj: any): boolean {
  return obj.Type.value === 'BinanceStock' || obj.Type.value === 'BinanceFutures' || obj.Type.value === 'BinanceFuturesCoin'
}

export function isInteractiveBrokers(obj: any): boolean {
  return obj.Type.value === 'InteractiveBrokers'
}

export function isHuobi(obj: any): boolean {
  return obj.Type.value === 'Huobi' || obj?.Type?.value === 'Okx' || obj?.Type?.value === 'OkxFutures'
}

export const binanceType = (obj: any) => {
  if (obj.Type.value === 'BinanceStock') {
    return 'BinanceStock'
  } else {
    return 'BinanceFutures'
  }
}
