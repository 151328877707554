import React, { useEffect, useState } from 'react'
import { Alert, Button, Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { AggregationPoolEntity, ProcessingRuleEntity, ProcessingRuleItem, ProcessingRuleItemNotValid, roundTypes } from '../../entity/configuration'
import { useFormValidation } from '../../hooks/useFormValidation'
import { addProcessingRule, changeAggregationPool, changeExecutionLpOnePreset, changeProcessingRule, changeRoutingRuleNoChange, executionConfigurationChanged } from '../../redux/actions/execution/execution-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { RootState } from '../../redux/reducers/rootReducer'
import { buildControlsExtTwoPerLine, checkboxInput, sselectInput, textInput } from '../../utils/controls'
import { IRightbar } from './rightbar-types'
import { buildMultiselectOptionsFromArray } from '../../utils/multiselect-utils'
import { useTranslate } from '../../hooks/useTranslate'

const ProcessingRuleRightbar: React.FC<IRightbar> = React.memo(({ data: { type, item, preset } }) => {
  const translate = useTranslate()
  const starRule = item.Rules[item.Rules.length - 1] || new ProcessingRuleItem()
  const [common, setCommon, commonTouched, setCommonTouched, commonErrors, isCommonValid] = useFormValidation(new ProcessingRuleEntity({ ...item, Profile: { value: item.Profile, label: item.Profile } }), ProcessingRuleEntity.schema)

  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(
    new ProcessingRuleItem({
      ...starRule,
      MinVolume: String(starRule.MinVolume),
      MaxVolume: String(starRule.MaxVolume),
      RoundType: { value: starRule.RoundType, label: starRule.RoundType },
      ExecutionPriceSource: { value: starRule.ExecutionPriceSource, label: translate(`price-source.${starRule.ExecutionPriceSource}`) },
    }),
    ProcessingRuleItemNotValid.schema,
  )

  const dispatch = useDispatch()
  const { aggregationPools, Profile, lps, processingRules, routingRules } = useSelector((state: RootState) => state.executionConfiguration)
  const { gateway } = useSelector((state: RootState) => state.gateways)
  const presetName = Profile.map((item: any) => item.Name)

  useEffect(() => {
    if (!common.Profile.value && type === 'add') {
      setCommon((prev: any) => {
        return {
          ...prev,
          Profile: { value: preset === 'All' ? presetName[0] : preset, label: preset === 'All' ? presetName[0] : preset },
        }
      })
    }
  }, [Profile]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if ((common.Profile.value && type === 'add') || type === 'add') {
      setCommon((prev: any) => {
        return {
          ...prev,
          LpPool: { value: aggregationPools[0]?.Name ?? '', label: aggregationPools[0]?.Name ?? '' },
        }
      })
    }
  }, [common.Profile.value]) // eslint-disable-line react-hooks/exhaustive-deps

  const alreadyExist = () => {
    if (type === 'modify' && processingRules.find((platform: any) => platform.Name === common.Name && platform.Profile === common.Profile.value && platform.Id !== common.Id)) {
      commonErrors.Name = true
      return 'processing.exists'
    }
    if ((type === 'add' || type === 'clone') && processingRules.find((platform: any) => platform.Name === common.Name && platform.Profile === common.Profile.value)) {
      commonErrors.Name = true
      return 'processing.exists'
    }
    return ''
  }

  const [msg, setMsg] = useState({ AggregationPool: '', Profile: '' })

  const handleSave = () => {
    if (!isValid() || !isCommonValid()) {
      if (!common.Name?.lenght) {
        commonErrors.Name = true
        commonTouched.Name = true
      }

      if (!presetName.length) {
        commonErrors.Profile = true
        commonTouched.Profile = true
        setMsg((prev: any) => {
          return {
            ...prev,
            Profile: 'You need to create a profile to proceed',
          }
        })
      }

      if (!aggregationPools.map((elem: AggregationPoolEntity) => elem.Name).length) {
        commonErrors.LpPool = true
        commonTouched.LpPool = true
        setMsg((prev: any) => {
          return {
            ...prev,
            AggregationPool: 'You need to create an aggregation pool to proceed',
          }
        })
      }

      return
    }

    const findProfile = Profile.filter((item: any) => item.Name === common.Profile.value)
    const newRule = new ProcessingRuleEntity({
      ...item,
      ...common,
      LpPool: common.LpPool.value,
      Profile: common.Profile.value,
      Name: common.Name.trim(),
      Rules: [...item.Rules.slice(0, item.Rules.length - 1), new ProcessingRuleItem({ ...inputState, RoundType: inputState.RoundType.value, ExecutionPriceSource: inputState.ExecutionPriceSource.value })].map((item: any) => {
        return {
          ...item,
          Enabled: findProfile[0].Enabled,
          Id: -Math.floor(Math.random() * (2000000000 + 1 - 1)),
        }
      }),
    })

    if (type === 'add' || type === 'clone') {
      dispatch(addProcessingRule({ ...newRule, Id: -Math.floor(Math.random() * (1000 + 1 - 1)) }))
    } else {
      aggregationPools.forEach((item: any) => {
        if (item.Name === newRule.LpPool) {
          const nameLp = item.Lps.map((item: any) => item.value)
          dispatch(changeAggregationPool({ ...item, bunchProfiles: [...item.bunchProfiles, newRule.Profile], Profile: newRule.Profile }, !item.changed))
          lps.forEach((item: any) => {
            if (nameLp.includes(item.Name)) {
              dispatch(changeExecutionLpOnePreset({ ...item, bunchProfiles: [...item.bunchProfiles, newRule.Profile] }))
            }
          })
        }
      })

      const newRoutingRule = routingRules.find((item: any) => item.ProcessorId === newRule.Id)
      if (newRoutingRule?.Profile === newRule.Profile) {
        dispatch(changeRoutingRuleNoChange({ ...newRoutingRule }))
      } else {
        dispatch(changeRoutingRuleNoChange({ ...newRoutingRule, ProcessorId: '', ProcessorName: '' }))
      }

      dispatch(changeProcessingRule(newRule))
    }
    dispatch(executionConfigurationChanged())
    dispatch(hideRightBar())
  }

  const findBBookPool: any = aggregationPools?.find((item: any) => item.Name === common.LpPool?.value)
  const findPoolNameLp = findBBookPool?.Lps.map((item: any) => item.value)
  const findABBookType = gateway?.Lps.filter((item: any) => findPoolNameLp?.includes(item.Name))
  const findBbookType = findABBookType.filter((item: any) => item.Type === 'BbookHedged' || item.Type === 'VolumeConverter' || item.Type === 'HedgingSyntheticSymbol' || item.Type === 'HedgingSyntheticIndex' || item.Type === 'Bbook')
  let flag = findABBookType.length - findBbookType.length

  useEffect(() => {
    if (!flag) {
      setInputState((prev: any) => {
        return {
          ...prev,
          ExecutionPriceSource: { value: 'LP', label: translate(`price-source.LP`) },
        }
      })
    }
  }, [findBBookPool]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card>
      <Card.Header className="color-dark font-500">
        <FormattedMessage id={`processing-rule.${type}`} />
      </Card.Header>
      <Card.Body>
        {buildControlsExtTwoPerLine(
          [
            textInput('Name').errorMessage(alreadyExist()),
            sselectInput('Profile', buildMultiselectOptionsFromArray(presetName), false).errorMessage(msg.Profile),
            sselectInput('LpPool', buildMultiselectOptionsFromArray((aggregationPools && aggregationPools.map((elem: AggregationPoolEntity) => elem.Name)) || []), false).errorMessage(msg.AggregationPool),
          ],
          common,
          setCommon,
          'processing-rule',
          commonTouched,
          setCommonTouched,
          commonErrors,
        )}
        {buildControlsExtTwoPerLine(
          [
            textInput('MarkupFrom').disabled(inputState.ExecutionPriceSource.value === 'Platform'),
            textInput('MarkupTo').disabled(inputState.ExecutionPriceSource.value === 'Platform'),
            textInput('LimitMarkupFrom').disabled(inputState.ExecutionPriceSource.value === 'Platform'),
            textInput('LimitMarkupTo').disabled(inputState.ExecutionPriceSource.value === 'Platform'),
            sselectInput('RoundType', buildMultiselectOptionsFromArray(roundTypes), false).optionZindex(true, 1000),
            sselectInput(
              'ExecutionPriceSource',
              ['LP', 'Platform', 'TakePositiveSlippage'].map((item: any) => {
                return {
                  value: item,
                  label: translate(`price-source.${item}`),
                }
              }),
              false,
            )
              .optionZindex(true, 1000)
              .skipWhen(!gateway.EnablePriceOption)
              .disabled(!Boolean(flag)),

            textInput('DelayFrom').skipWhen(inputState.ExecutionPriceSource.value !== 'TakePositiveSlippage'),
            textInput('DelayTo').skipWhen(inputState.ExecutionPriceSource.value !== 'TakePositiveSlippage'),
            checkboxInput('AllowContinuousExecutionForFOK', true),
            checkboxInput('ExecuteLimitsAsMarkets', true),
          ],
          inputState,
          setInputState,
          'processing-rule',
          touched,
          setTouched,
          errors,
        )}
        {inputState.ExecuteLimitsAsMarkets && (
          <Alert className="d-flex mb-0" variant="danger">
            <FormattedMessage id="ExecuteLimitsAsMarkets.error" />
          </Alert>
        )}
        <Button className="t4b-bg-dark-button mt-3" onClick={handleSave}>
          <FormattedMessage id="save" tagName="span" />
        </Button>
      </Card.Body>
    </Card>
  )
})

export default ProcessingRuleRightbar
