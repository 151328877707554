import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormValidation } from '../../../hooks/useFormValidation'
import { Button, Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { hideRightBar } from '../../../redux/actions/rightbar-actions'
import { fetchAddReport, fetchRegenerateReport, fetchReportRules } from '../../../redux/actions/reports-actions'
import { RootState } from '../../../redux/reducers/rootReducer'
import { buildControlsExtTwoPerLine, checkboxInput, mselectInput, sselectInput, textInput, timeInput, timespanInput } from '../../../utils/controls'
import { buildMultiselectOptionsFromArray, buildSelectOption, optionsToStrings } from '../../../utils/multiselect-utils'
import { AppAccordion } from '@t4b/core'
import { GeneratedFiles } from './GeneratedFiles'
import { useTranslate } from '../../../hooks/useTranslate'
import moment from 'moment/moment'
import * as yup from 'yup'
import { folderNameSchema, newSchemaName } from '../../../utils/schema-utils'
import { alreadyReportFile } from '../lpRightbarUtils/utils'
import NewSearchableSelectInput from '../../inputs/NewSearchableSelectInput'

export const ReportRightbar = ({ data: { item, type, params, platformName } }: any) => {
  const dispatch = useDispatch()
  const translate = useTranslate()
  const { Reports } = useSelector((state: RootState) => state.Reports)
  const { gateway } = useSelector((reduxState: RootState) => reduxState.gateways)
  const mainRef = useRef<any>(null)
  const activePlatform = gateway?.Platforms?.find((item: any) => platformName === item.Name)?.Type
  const [checked, setChecked] = useState({ Type: { value: item.Type, label: translate(`report.${item.Type}`) } })

  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(
    {
      ...item,
      TimeOffset: { value: String(item.TimeOffset / 1000 / 60 / 60), label: String(item.TimeOffset / 1000 / 60 / 60) },
      DateOfMonth: buildSelectOption(item.DateOfMonth > 0 ? String(item.DateOfMonth) : '1'),
      DayOfWeek: buildSelectOption(item.DayOfWeek),
      From: moment(new Date(new Date().getTime() - 86400000))
        .startOf('day')
        .toDate()
        .getTime(),
      To: moment(new Date()).startOf('day').toDate().getTime(),
      ScheduleType: { label: translate(`report.${item?.ScheduleType}`), value: item?.ScheduleType },
    },
    {
      ScheduleRuleName: type === 'regenerate' ? yup.string() : folderNameSchema(),
      StartTime: type === 'regenerate' ? yup.number() : yup.number().required(),
      ScheduleType:
        checked?.Type?.value === 'Opra'
          ? yup.object()
          : yup.object().shape({
              label: yup.string().required(),
              value: yup.string().required(),
            }),
      AccountMask:
        checked?.Type?.value === 'Opra'
          ? yup.string()
          : yup
              .string()
              .matches(/^[0-9,*!]+$/gi)
              .nullable(),

      LpMask: checked?.Type?.value === 'Opra' ? yup.string() : yup.string().required(),

      DateOfMonth:
        checked?.Type?.value === 'Opra'
          ? yup.object()
          : yup.object().shape({
              label: yup.string().required(),
              value: yup.string().required(),
            }),

      DayOfWeek:
        checked?.Type?.value === 'Opra'
          ? yup.object()
          : yup.object().shape({
              label: yup.string().required(),
              value: yup.string().required(),
            }),
    },
  )

  const [validExcel, setValidExcel] = useState(item?.Excel?.Enabled)
  const [validUsers, setValidUsers] = useState(item?.Users?.Enabled)
  const [validQuotes, setValidQuotes] = useState(item?.Quotes?.Enabled)
  const [validDeals, setValidDeals] = useState(item?.Deals?.Enabled)
  const [validOpra, setValidOpra] = useState(item?.Output?.Enabled)
  const [inputStateColumns, setInputStateColumns, touchedColumns, setTouchedColumns, errorsColumns, isValidColumns] = useFormValidation(
    {
      ...item?.Excel,
      ExecutionColumns:
        type === 'add'
          ? buildMultiselectOptionsFromArray(['ALL'])
          : checked?.Type?.value === 'Exra'
          ? buildMultiselectOptionsFromArray(item.Excel?.ExecutionColumns)
          : buildMultiselectOptionsFromArray(item.Deals?.ExecutionColumns),
      RejectedColumns: type === 'add' ? buildMultiselectOptionsFromArray(['ALL']) : buildMultiselectOptionsFromArray(item.Excel?.RejectedColumns),
    },
    checked?.Type?.value === 'Exra'
      ? {
          Name: validExcel ? folderNameSchema() : yup.string(),
          Folder: validExcel ? newSchemaName() : yup.string(),
          ExecutionColumns: yup
            .array()
            .of(
              yup.object().shape({
                value: yup.string().required(),
                label: yup.string().required(),
              }),
            )
            .required(),
          RejectedColumns: yup
            .array()
            .of(
              yup.object().shape({
                value: yup.string().required(),
                label: yup.string().required(),
              }),
            )
            .required(),
        }
      : {},
  )

  const [inputStateUsers, setInputStateUsers, touchedUsers, setTouchedUsers, errorsUsers, isValidUsers] = useFormValidation(item?.Users ?? {}, {
    Name: type === 'regenerate' || checked?.Type?.value === 'Exra' || checked?.Type?.value === 'Opra' ? yup.string() : validUsers ? folderNameSchema() : yup.string(),
    Folder: type === 'regenerate' || checked?.Type?.value === 'Exra' || checked?.Type?.value === 'Opra' ? yup.string() : validUsers ? newSchemaName() : yup.string(),
  })

  const [inputStateQuotes, setInputStateQuotes, touchedQuotes, setTouchedQuotes, errorsQuotes, isValidQuotes] = useFormValidation(
    {
      ...item?.Quotes,
      ExpirationTimeout: item?.Quotes?.ExpirationTimeout ?? 900000,
    },
    {
      Name:
        type === 'regenerate' || activePlatform !== 'mt5' || checked?.Type?.value === 'Opra'
          ? yup.string()
          : validQuotes && activePlatform === 'mt5'
          ? folderNameSchema()
          : yup.string(),
      Folder:
        type === 'regenerate' || activePlatform !== 'mt5' || checked?.Type?.value === 'Opra'
          ? yup.string()
          : validQuotes && activePlatform === 'mt5'
          ? newSchemaName()
          : yup.string(),
    },
  )

  const [inputStateDeals, setInputStateDeals, touchedDeals, setTouchedDeals, errorsDeals, isValidDeals] = useFormValidation(item?.Deals ?? {}, {
    Name: checked?.Type?.value === 'Exra' || checked?.Type?.value === 'Opra' ? yup.string() : validDeals ? folderNameSchema() : yup.string(),
    Folder: checked?.Type?.value === 'Exra' || checked?.Type?.value === 'Opra' ? yup.string() : validDeals ? newSchemaName() : yup.string(),
  })

  const [inputStateOpra, setInputStateOpra, touchedOpra, setTouchedOpra, errorsOpra, isValidOpra] = useFormValidation(
    {
      ...item?.Output,
      FileType: { value: item?.Output?.FileType, label: item?.Output?.FileType },
    } ?? {},
    {
      Name: checked?.Type?.value === 'Exra' || checked?.Type?.value === 'Emir' ? yup.string() : validOpra ? folderNameSchema() : yup.string(),
      Folder: checked?.Type?.value === 'Exra' || checked?.Type?.value === 'Emir' ? yup.string() : validOpra ? newSchemaName() : yup.string(),
    },
  )

  useEffect(() => {
    if (inputState.AccountMask === '') {
      errors.AccountMask = false
    }
  }, [inputState.AccountMask])

  useEffect(() => {
    if (checked?.Type?.value === 'Opra') {
      setInputState((prev: any) => {
        return {
          ...prev,
          ScheduleType: { value: 'Monthly', label: translate('report.Monthly') },
        }
      })
    }
  }, [checked?.Type?.value])

  useEffect(() => {
    setValidExcel(inputStateColumns.Enabled)
    setValidDeals(inputStateDeals.Enabled)
    setValidUsers(inputStateUsers.Enabled)
    setValidQuotes(inputStateQuotes.Enabled)
    setValidOpra(inputStateOpra.Enabled)
  }, [inputStateColumns.Enabled, inputStateDeals.Enabled, inputStateUsers.Enabled, inputStateQuotes.Enabled, inputStateOpra.Enabled])

  const handleClick = async () => {
    const body = {
      Type: checked?.Type?.value,
      [checked?.Type?.value]: {
        ...inputState,
        AccountMask: inputState.AccountMask,
        LpMask: inputState.LpMask,
        DateOfMonth: inputState.DateOfMonth?.value,
        DayOfWeek: inputState.DayOfWeek?.value,
        ScheduleType: inputState.ScheduleType?.value,
        TimeOffset: +inputState.TimeOffset.value * 1000 * 60 * 60,
        Excel: {
          ...inputStateColumns,
          Name: inputStateColumns.Name?.trim(),
          Folder: inputStateColumns.Folder?.trim(),
          ExecutionColumns: optionsToStrings(inputStateColumns.ExecutionColumns).includes('ALL') ? null : optionsToStrings(inputStateColumns.ExecutionColumns),
          RejectedColumns: optionsToStrings(inputStateColumns.RejectedColumns).includes('ALL') ? null : optionsToStrings(inputStateColumns.RejectedColumns),
        },

        Users: { ...inputStateUsers, Name: inputStateUsers.Name?.trim(), Folder: inputStateUsers.Folder?.trim() },
        Quotes: { ...inputStateQuotes, Name: inputStateQuotes.Name?.trim(), Folder: inputStateQuotes.Folder?.trim() },
        Deals:
          checked?.Type?.value === 'Exra'
            ? inputStateDeals
            : {
                ...inputStateDeals,
                Name: inputStateDeals.Name?.trim(),
                Folder: inputStateDeals.Folder?.trim(),
                ExecutionColumns: optionsToStrings(inputStateColumns.ExecutionColumns).includes('ALL') ? null : optionsToStrings(inputStateColumns.ExecutionColumns),
              },
        Output: { ...inputStateOpra, Name: inputStateOpra.Name?.trim(), Folder: inputStateOpra.Folder?.trim(), FileType: inputStateOpra?.FileType?.value },

        Type: checked?.Type?.value,
        ScheduleRuleName: inputState.ScheduleRuleName,
      },
    }

    const a = isValid()
    const b = isValidUsers()
    const c = isValidQuotes()
    const d = isValidDeals()
    const e = isValidColumns()
    const f = isValidOpra()

    if (!a || !b || !c || !d || !e || !f) {
      return
    }

    if (type === 'add' || type === 'clone') {
      await dispatch(
        fetchAddReport({
          Gateway: gateway.Name,
          Platform: platformName,
          ruleName: inputState.ScheduleRuleName,
          setLoading: params.setLoading,
          body: body,
        }),
      )
    }

    if (type === 'modify') {
      await dispatch(
        fetchAddReport({
          Gateway: gateway.Name,
          Platform: platformName,
          ruleName: item.ScheduleRuleName,
          setLoading: params.setLoading,
          body: body,
        }),
      )
    }

    if (type === 'regenerate') {
      dispatch(
        fetchRegenerateReport({
          Gateway: gateway.Name,
          Platform: platformName,
          setLoading: params.setLoading,
          from: inputState.From,
          to: inputState.To,
          body: body,
          fileName: inputStateDeals.Name.length ? inputStateDeals.Name : inputStateColumns.Name,
        }),
      )
    }

    dispatch(
      fetchReportRules({
        Gateway: gateway.Name,
        Platform: platformName,
        setLoading: params.setLoading,
      }),
    )

    dispatch(hideRightBar())
  }

  const alreadyExist = () => {
    if ((type === 'add' || type === 'clone') && Reports.find((item: any) => item.ScheduleRuleName === inputState.ScheduleRuleName)) {
      errors.ScheduleRuleName = true
      return 'reportRightbar.exists'
    }

    if (type === 'modify' && Reports.find((item: any) => item.ScheduleRuleName === inputState.ScheduleRuleName && item.Id !== inputState.Id)) {
      errors.ScheduleRuleName = true
      return 'reportRightbar.exists'
    }
    return ''
  }
  const TimeZonePlus = Array.from({ length: 13 }, (_, index) => index)?.map((item: any) => {
    return {
      value: String(item),
      label: String(item),
    }
  })
  const TimeZoneMinus = Array.from({ length: 12 }, (_, index) => index + 1)
    ?.map((item: any) => {
      return {
        value: String(-1 * item),
        label: String(-1 * item),
      }
    })
    .reverse()

  const reportSettings = buildControlsExtTwoPerLine(
    [
      textInput('ScheduleRuleName')
        .skipWhen(type === 'regenerate')
        .errorMessage(alreadyExist()),
      textInput('InvestmentFirmBBook').skipWhen(checked?.Type?.value === 'Exra' || checked?.Type?.value === 'Opra'),
      timespanInput('StartTime', 1500),
      textInput('AccountMask', false, '', '', 'Mask', true).skipWhen(checked?.Type?.value === 'Opra'),
      textInput('LpMask', false, '', '', 'Mask', true).skipWhen(checked?.Type?.value === 'Opra'),
      textInput('ExecutionWithinFirmIdType')
        .skipWhen(checked?.Type?.value === 'Exra')
        .skipWhen(checked?.Type?.value === 'Opra'),
      textInput('UserGroupMask', false, '', '', 'Mask', true).skipWhen(checked?.Type?.value === 'Opra'),
      sselectInput('TimeOffset', [...TimeZoneMinus, ...TimeZonePlus]).optionZindex(true, 1000),
    ],
    inputState,
    setInputState,
    'ReportRightbar',
    touched,
    setTouched,
    errors,
  )

  const ScheduleTypeOptions = [
    { label: translate('report.Daily'), value: 'Daily' },
    { label: translate('report.Weekly'), value: 'Weekly' },
    { label: translate('report.Monthly'), value: 'Monthly' },
  ]

  const DaysOfWeekOptions = buildMultiselectOptionsFromArray(['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'])
  const DateOfMonthOptions = Array.from({ length: 31 }, (_, index) => index + 1)?.map((item: any) => {
    return {
      value: String(item),
      label: String(item),
    }
  })

  const setNewDate = (newState: any) => {
    if (newState.From > newState.To) {
      setInputState((prev: any) => {
        return {
          ...prev,
          From: newState.From,
          To: moment(newState.From + 86400000)
            .endOf('day')
            .toDate()
            .getTime(),
        }
      })
    } else {
      setInputState(newState)
    }
  }

  const reportSchedule = buildControlsExtTwoPerLine(
    [
      sselectInput('ScheduleType', checked?.Type?.value === 'Opra' ? [{ label: translate('report.Monthly'), value: 'Monthly' }] : ScheduleTypeOptions)
        .skipWhen(type === 'regenerate')
        .optionZindex(true, 400),

      timeInput('From', 100, true, moment(new Date().getTime() - 86400000).toDate())
        .stateSetup(setNewDate)
        .skipWhen(type !== 'regenerate'),
      timeInput('To', 100, true, moment().toDate())
        .setMinDate(moment(inputState.From + 86400000).toDate())
        .stateSetup(setNewDate)
        .skipWhen(type !== 'regenerate'),
      sselectInput('DayOfWeek', DaysOfWeekOptions)
        .optionZindex(false)
        .skipWhen(inputState.ScheduleType.value === 'Daily' || inputState.ScheduleType.value === 'Monthly' || type === 'regenerate'),
      sselectInput('DateOfMonth', DateOfMonthOptions)
        .optionZindex(false)
        .skipWhen(inputState.ScheduleType.value === 'Daily' || inputState.ScheduleType.value === 'Weekly' || type === 'regenerate'),
    ],
    inputState,
    setInputState,
    'ReportRightbar',
    touched,
    setTouched,
    errors,
  )

  const RejectedColumnsOptions = buildMultiselectOptionsFromArray([
    'ALL',
    'ORDER_ID',
    'ORDER_TYPE',
    'OPEN_TIME_UTC',
    'TRADING_PLATFORM',
    'LOGIN',
    'SYMBOL',
    'CMD',
    'VOLUME',
    'PLATFORM_REQUEST_PRICE',
    'ORDER_STATUS',
  ])
  const ExecutionColumnsOptions = buildMultiselectOptionsFromArray([
    'ALL',
    'TICKET',
    'SECONDARY_TICKET',
    'PLATFORM_TICKET',
    'LOGIN',
    'SYMBOL',
    'CMD',
    'VOLUME',
    'OPEN_TIME_UTC',
    'OPEN_TIME_PLATFORM',
    'PLATFORM_REQUEST_PRICE',
    'LP_REQUEST_PRICE',
    'LP_EXECUTION_PRICE',
    'PLATFORM_EXECUTION_PRICE',
    'PLATFORM_SLIPPAGE',
    'LP_SLIPPAGE',
    'BROKER_MARKUP',
    'LP',
    'TRADING_PLATFORM',
    'LP_EXECUTION_TIME_MS',
    'FULL_EXECUTION_TIME_MS',
    'ORDER_ID',
    'ORDER_TYPE',
    'ORDER_STATUS',
  ])

  const ExecutionColumns2 = buildMultiselectOptionsFromArray([
    'ALL',
    'TICKET',
    'LOGIN',
    'SYMBOL',
    'CMD',
    'VOLUME',
    'OPEN_TIME_UTC',
    'OPEN_PRICE',
    'CLOSE_TIME',
    'CLOSE_PRICE',
    'COMMENT',
    'LP',
    'TRADING_PLATFORM',
    'CLIENT_BRANCH_COUNTRY',
    'CLOSE_CLIENT_BRANCH_COUNTRY',
    'DECISION_MAKER_ID',
    'DECISION_MAKER_ID_TYPE',
    'DECISION_MAKER_FIRST_NAME',
    'DECISION_MAKER_SURNAME',
    'DECISION_MAKER_DOB',
    'CLOSE_DECISION_MAKER_ID',
    'CLOSE_DECISION_MAKER_ID_TYPE',
    'CLOSE_DECISION_MAKER_FIRST_NAME',
    'CLOSE_DECISION_MAKER_SURNAME',
    'CLOSE_DECISION_MAKER_DOB',
    'TRANSMISSION',
    'TRANSMISSION_LOGIN',
    'VOLUME_CURRENCY',
    'INVESTMENT_DECISION_ID',
    'INVESTMENT_DECISION_ID_TYPE',
    'INVESTMENT_DECISION_BRANCH_COUNTRY',
    'CLOSE_INVESTMENT_DECISION_ID',
    'CLOSE_INVESTMENT_DECISION_ID_TYPE',
    'CLOSE_INVESTMENT_DECISION_BRANCH_COUNTRY',
    'EXECUTION_WITHIN_FIRM_ID',
    'EXECUTION_WITHIN_FIRM_ID_TYPE',
    'EXECUTING_PERSON_BRANCH_COUNTRY',
    'CLOSE_EXECUTION_WITHIN_FIRM_ID',
    'CLOSE_EXECUTION_WITHIN_FIRM_ID_TYPE',
    'CLOSE_EXECUTING_PERSON_BRANCH_COUNTRY',
    'CMDT_DERIVATIVE_INDICATOR',
    'STRIKE_PRICE',
    'OPTION_TYPE',
    'OPTION_STYLE',
    'EXPIRY_TIME',
    'PLATFORM_TIME',
  ])

  const findAllExecutionColumns = inputStateColumns?.ExecutionColumns?.find((item: any) => item?.value === 'ALL')
  const findAllRejectedColumns = inputStateColumns?.RejectedColumns?.find((item: any) => item?.value === 'ALL')
  /////////
  const findTypeReport = checked?.Type?.value === 'Exra' ? ExecutionColumnsOptions : ExecutionColumns2
  /////////
  const optionsExecutionColumns = findAllExecutionColumns?.value === 'ALL' ? [] : findTypeReport
  const optionsRejectedColumns = findAllRejectedColumns?.value === 'ALL' ? [] : RejectedColumnsOptions

  useEffect(() => {
    if (findAllExecutionColumns?.value === 'ALL' || inputStateColumns.ExecutionColumns.length === optionsExecutionColumns.length) {
      setInputStateColumns((prev: any) => {
        return {
          ...prev,
          ExecutionColumns: [{ value: 'ALL', label: 'ALL' }],
        }
      })
    }

    if (findAllRejectedColumns?.value === 'ALL' || inputStateColumns.RejectedColumns.length === RejectedColumnsOptions.length) {
      setInputStateColumns((prev: any) => {
        return {
          ...prev,
          RejectedColumns: [{ value: 'ALL', label: 'ALL' }],
        }
      })
    }

    if (type === 'modify' || type === 'clone' || type === 'add') {
      if (inputStateColumns.ExecutionColumns.length === optionsExecutionColumns.length - 1) {
        setInputStateColumns((prev: any) => {
          return {
            ...prev,
            ExecutionColumns: [{ value: 'ALL', label: 'ALL' }],
          }
        })
      }

      if (inputStateColumns.RejectedColumns.length === RejectedColumnsOptions.length - 1) {
        setInputStateColumns((prev: any) => {
          return {
            ...prev,
            RejectedColumns: [{ value: 'ALL', label: 'ALL' }],
          }
        })
      }
    }
  }, [optionsExecutionColumns.length, optionsRejectedColumns.length, inputStateColumns.RejectedColumns.length, inputStateColumns.ExecutionColumns.length])

  const reportColumns = buildControlsExtTwoPerLine(
    [
      mselectInput('ExecutionColumns', optionsExecutionColumns, 350).skipWhen(checked?.Type?.value === 'Opra'),
      mselectInput('RejectedColumns', optionsRejectedColumns, 350).skipWhen(checked?.Type?.value === 'Emir'),
    ],
    inputStateColumns,
    setInputStateColumns,
    'ReportRightbar',
    touchedColumns,
    setTouchedColumns,
    errorsColumns,
  )

  const reportColumnsExel = buildControlsExtTwoPerLine(
    [checkboxInput('Enabled').skipWhen(type === 'regenerate'), textInput('Name').skipWhen(!inputStateColumns.Enabled), textInput('Folder').skipWhen(!inputStateColumns.Enabled)],
    inputStateColumns,
    setInputStateColumns,
    'reportColumnsExel',
    touchedColumns,
    setTouchedColumns,
    errorsColumns,
  )

  const errorsNameDeals = useMemo(
    () => [!inputStateQuotes.Enabled ? '' : inputStateQuotes.Name, !inputStateUsers.Enabled ? '' : inputStateUsers.Name],
    [inputStateQuotes.Name, inputStateUsers.Name, inputStateQuotes.Enabled, inputStateUsers.Enabled],
  )

  const errorsNameQuotes = useMemo(
    () => [!inputStateDeals.Enabled ? '' : inputStateDeals.Name, !inputStateUsers.Enabled ? '' : inputStateUsers.Name],
    [inputStateDeals.Name, inputStateUsers.Name, inputStateUsers.Enabled, inputStateDeals.Enabled],
  )

  const errorsNameUsers = useMemo(
    () => [!inputStateDeals.Enabled ? '' : inputStateDeals.Name, !inputStateQuotes.Enabled ? '' : inputStateQuotes.Name],
    [inputStateDeals.Name, inputStateQuotes.Name, inputStateQuotes.Enabled, inputStateDeals.Enabled],
  )

  const allErrors = [inputStateDeals.Enabled, inputStateQuotes.Enabled, inputStateUsers.Enabled]

  const fileDeals = useMemo(
    () =>
      buildControlsExtTwoPerLine(
        [
          checkboxInput('Enabled').skipWhen(type === 'regenerate'),
          textInput('Name')
            .errorMessage(alreadyReportFile(errorsNameDeals, inputStateDeals.Name, errorsDeals, inputStateDeals.Enabled, allErrors))
            .skipWhen(!inputStateDeals.Enabled),
          textInput('Folder').skipWhen(!inputStateDeals.Enabled),
        ],
        inputStateDeals,
        setInputStateDeals,
        'ReportRightbarDeals',
        touchedDeals,
        setTouchedDeals,
        errorsDeals,
      ),
    [inputStateDeals, errorsDeals],
  )

  const fileQuotes = useMemo(
    () =>
      buildControlsExtTwoPerLine(
        [
          checkboxInput('Enabled'),
          textInput('Name')
            .errorMessage(alreadyReportFile(errorsNameQuotes, inputStateQuotes.Name, errorsQuotes, inputStateQuotes.Enabled, allErrors))
            .skipWhen(!inputStateQuotes.Enabled),
          textInput('Folder').skipWhen(!inputStateQuotes.Enabled),
          timespanInput('ExpirationTimeout').skipWhen(!inputStateQuotes.Enabled),
        ],
        inputStateQuotes,
        setInputStateQuotes,
        'ReportRightbarQuotes',
        touchedQuotes,
        setTouchedQuotes,
        errorsQuotes,
      ),
    [inputStateQuotes, errorsQuotes],
  )

  const fileUsers = useMemo(
    () =>
      buildControlsExtTwoPerLine(
        [
          checkboxInput('Enabled'),
          textInput('Name')
            .errorMessage(alreadyReportFile(errorsNameUsers, inputStateUsers.Name, errorsUsers, inputStateUsers.Enabled, allErrors))
            .skipWhen(!inputStateUsers.Enabled),
          textInput('Folder').skipWhen(!inputStateUsers.Enabled),
        ],
        inputStateUsers,
        setInputStateUsers,
        'ReportRightbarUsers',
        touchedUsers,
        setTouchedUsers,
        errorsUsers,
      ),
    [inputStateUsers, errorsUsers],
  )

  const fileOpra = useMemo(
    () =>
      buildControlsExtTwoPerLine(
        [
          checkboxInput('Enabled'),
          textInput('Name').skipWhen(!inputStateOpra.Enabled),
          textInput('Folder').skipWhen(!inputStateOpra.Enabled),
          sselectInput('FileType', buildMultiselectOptionsFromArray(['XLSX', 'CSV']))
            .optionZindex(false)
            .skipWhen(!inputStateOpra.Enabled),
        ],
        inputStateOpra,
        setInputStateOpra,
        'ReportRightbarOpra',
        touchedOpra,
        setTouchedOpra,
        errorsOpra,
      ),
    [inputStateOpra, errorsOpra],
  )

  const optionsReports = useMemo(
    () => [
      { value: 'Opra', label: translate(`report.Opra`) },
      { value: 'Exra', label: translate(`report.Exra`) },
      { value: 'Emir', label: translate(`report.Emir`) },
    ],
    [],
  )
  const optionsReportsNotOpra = useMemo(
    () => [
      { value: 'Exra', label: translate(`report.Exra`) },
      { value: 'Emir', label: translate(`report.Emir`) },
    ],
    [],
  )

  const flagOpra = checked?.Type?.value !== 'Opra' || type !== 'regenerate'

  return (
    <Card>
      <Card.Header className="color-dark font-500">
        <FormattedMessage id={`reportRightbar.${type}`} />
      </Card.Header>
      <Card.Body>
        <>
          <span className="reportTitle">
            <FormattedMessage id="ReportType" />
          </span>
          <NewSearchableSelectInput
            zIndex={1501}
            state={checked}
            isDisabled={type === 'modify' || type === 'clone'}
            setState={setChecked}
            name="Type"
            options={activePlatform === 'mt5' ? optionsReports : optionsReportsNotOpra}
            className="settings-block__field mt-2"
            isSearchable={true}
            styles={{
              menu: (provided: any) => ({
                ...provided,
                zIndex: 1501,
              }),
            }}
          />
        </>

        {flagOpra && (
          <div className="mt-4 mb-4">
            <span className="reportTitle">
              <FormattedMessage id="PlatformReportSettings" />
            </span>
            <div className="mt-2">{reportSettings}</div>
          </div>
        )}

        {flagOpra && (
          <div className="mt-4">
            <span className="reportTitle">
              <FormattedMessage id="ReportSchedule" />
            </span>
            <div className="mt-2">{reportSchedule}</div>
          </div>
        )}

        <div className="mt-4">
          <span className="reportTitle">
            <FormattedMessage id="ReportView" />
          </span>
          {checked?.Type?.value === 'Opra' && <div className="mt-2">{fileOpra}</div>}
          {checked?.Type?.value === 'Exra' && <div className="mt-2">{reportColumns}</div>}
          {checked?.Type?.value === 'Emir' && <div className="mt-2">{reportColumns}</div>}
          {checked?.Type?.value === 'Emir' && (
            <>
              <div className="mt-2">{fileDeals}</div>
              {type !== 'regenerate' && <div>{fileUsers}</div>}
              {activePlatform === 'mt5' && type !== 'regenerate' && <div>{fileQuotes}</div>}
            </>
          )}
          {checked?.Type?.value === 'Exra' && <div className="mt-2">{reportColumnsExel}</div>}
        </div>
        <Button className="t4b-bg-dark-button mt-3" onClick={handleClick}>
          <FormattedMessage id={type === 'regenerate' ? 'regenerate' : 'save'} />
        </Button>
      </Card.Body>
      {type === 'modify' && (
        <AppAccordion
          item={{
            title: <FormattedMessage id={'GeneratedFiles'} />,
            item: item?.ReportFiles?.length ? (
              <GeneratedFiles item={item} gateway={gateway} platformName={platformName} />
            ) : (
              <div className="d-flex align-items-center justify-content-center">No reports were generated</div>
            ),
          }}
          ref={mainRef}
          render={mainRef?.current}
          isHidden={false}
        />
      )}
    </Card>
  )
}
