import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { GatewayEntity } from '../entity/system-settings'

export interface IGatewayProp {
  gateway: GatewayEntity
  gatewayName: { gateway: string }
}

function withGateway(WrappedComponent: any) {
  return (props: any) => {
    const { gateway } = useSelector((state: any) => state.gateways)
    const gatewayName = useMemo(() => ({ gateway: gateway.Name }), [gateway.Name])

    if (gateway.Name) {
      return <WrappedComponent gateway={gateway} gatewayName={gatewayName} {...props} />
    }
    return null
  }
}

export default withGateway
