import { useEffect, useState } from 'react'

function useDebounce<T>(value: T, ms: number): T {
  const [debounced, setDebounced] = useState<T>(value)

  useEffect(() => {
    const timerId = setTimeout(() => setDebounced(value), ms)

    return () => clearTimeout(timerId)
  }, [value, ms])
  return debounced
}

export default useDebounce
