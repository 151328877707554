import { IMultiSelectItem } from '../entity/multiselect'
import { useTranslate } from '../hooks/useTranslate'
import { FeedAgregationEntity } from '../entity/feeding'

export function buildMultiselectOptionsFromObjects(arr: any[]) {
  return arr.map((item: any) => {
    return { value: item.Name, label: item.Name }
  })
}

export function buildSelectOption(item: string): IMultiSelectItem {
  if (typeof item === 'string') {
    return { value: item, label: item }
  }
  return item
}

export function buildMultiselectOptionsFromArray(arr: any): any[] {
  if (Array.isArray(arr)) {
    return arr.map((item: any) => {
      if (item === 'LMAXCryptoF') {
        return { value: item, label: 'LMAX-CryptoFutures' }
      }

      if (typeof item === 'string') {
        if (item === '') {
          return { value: 'None', label: 'None' }
        } else if (item === 'LMAXCryptoF') {
          return { value: item, label: 'LMAX-CryptoFutures' }
        } else {
          return { value: item, label: item }
        }
      }

      return item
    })
  }
  return []
}

export const convertFeedPlatform = (arr: any) => {
  if (typeof arr === 'string') {
    if (arr === 'Proportional') {
      return { value: arr, label: 'Spread multiplier' }
    } else {
      return { value: arr, label: arr }
    }
  } else {
    return arr.map((item: any) => {
      if (item.value === 'Proportional') {
        return { value: item.value, label: 'Spread multiplier' }
      } else {
        return item
      }
    })
  }
}

export const convertCount = (arr: string[] | string) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const translate = useTranslate()
  if (typeof arr === 'string') {
    return { value: arr, label: translate('exposure.top' + arr) }
  } else {
    return arr.map((option: string) => {
      if (option === '0') {
        return { value: option, label: translate('All') }
      }
      return { value: option, label: translate('exposure.top' + option) }
    })
  }
}

export const convertFeederOptions = (option: number, feedAgregations: any) => {
  if (option === 0) {
    return 'None'
  }

  const aggregatedFeeder = feedAgregations.find((aggregation: FeedAgregationEntity) => aggregation.Id === option)

  if (aggregatedFeeder) {
    return aggregatedFeeder.Name
  }
  return option
}

export const convertUsers = (arr: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const translate = useTranslate()
  return arr.map((item: any) => {
    return {
      value: item,
      label: translate('users.' + item),
    }
  })
}

export const convertSaveQuotesDepth = (arr: any) => {
  return arr.map((item: any) => {
    if (item === 0) {
      return { value: 0, label: 'Nothing' }
    } else {
      return { value: item, label: item }
    }
  })
}

export function buildMultiselectOptionsFromString(arr: any): any {
  if (Array.isArray(arr)) {
    return arr.join(';')
  }
  return []
}

export function buildMultiselectOptionsFromEnum(enumeration: any, translate?: any, prefix?: string) {
  return Object.values(enumeration).map((item: any) => {
    return { value: item, label: translate ? translate(`${prefix}.${item}`) : item }
  })
}

export function optionToString(item: IMultiSelectItem): string {
  if (item === null || item === undefined) {
    return ''
  }

  if (typeof item === 'object') {
    return item.value
  }

  return ''
}

export function optionsToStrings(arr: IMultiSelectItem[] | null): string[] {
  if (Array.isArray(arr)) {
    return arr.map((item: any) => {
      if (item.value === 'None') {
        return ''
      }

      if (item.value) {
        return item.value
      } else {
        return item
      }
    })
  }
  return []
}

export function optionsToStringsConditional(arr: IMultiSelectItem[]) {
  if (Array.isArray(arr)) {
    return arr.map(item => {
      if (item.value) {
        return item.value
      }
      return item
    })
  }
  return []
}

export function buildMultiselectOprionsForPool(arr: any, withPercents: boolean) {
  return arr.map((item: any) => {
    if (item.value && item.label) {
      return item
    }
    if (withPercents) {
      return { value: item.LpName, label: `${item.LpName} (${item.Percent}%)` }
    }
    return { value: item.LpName, label: item.LpName }
  })
}

export function isAllOption(option: IMultiSelectItem): boolean {
  return option.value === 'All'
}

export function isEverydayOption(option: IMultiSelectItem): boolean {
  return option.value === 'Everyday'
}

export function buildOptionsWithAll(options: IMultiSelectItem[], state: any): IMultiSelectItem[] {
  if (state instanceof Array) {
    if (state.find(isAllOption)) {
      return buildMultiselectOptionsFromArray(['All'])
    }
  }

  return options
}

export function buildOptionsWithEveryday(options: IMultiSelectItem[], state: any): IMultiSelectItem[] {
  if (state instanceof Array) {
    if (state.find(isEverydayOption)) {
      return buildMultiselectOptionsFromArray(['Everyday'])
    }
  }

  return options
}

export function isEqualArrays(arr1: string[] | null, arr2: string[] | null): boolean {
  if (arr1 instanceof Array && arr2 instanceof Array) {
    if (arr1.length !== arr2.length) {
      return false
    }

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false
      }
    }

    return true
  }

  return false
}
export const isTrimString = (item: any) => {
  if (Array.isArray(item)) {
    return item.map((item: any) => {
      if (item?.value) {
        if (item.value.includes(',')) {
          return {
            value: item.value
              .split(',')
              .map((item: string) => item.trim())
              .join(','),
            label: item.label
              .split(',')
              .map((item: string) => item.trim())
              .join(','),
          }
        } else {
          return {
            value: item.value.trim(),
            label: item.label.trim(),
          }
        }
      } else {
        if (item.includes(',')) {
          return item
            .split(',')
            .map((item: string) => item.trim())
            .join(',')
        } else {
          return item.trim()
        }
      }
    })
  } else if (item.includes(',')) {
    return item
      .split(',')
      .map((item: string) => item.trim())
      .join(',')
  } else {
    return item.trim()
  }
}
