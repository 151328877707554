import { PlatformSymbol, Session } from '../../entity/platforms'
import { Action } from '../actions/actions'

function platformsReducer(state = [], action: any) {
  switch (action.type) {
    case Action.GotPlatforms:
      return [...action.data]
    default:
      return state
  }
}

export function mt4PlatformReducer(state = [], action: any) {
  switch (action.type) {
    case Action.GotMt4Sessions:
      return []
    case Action.GotMt4SymbolMap:
      return []
    default:
      return state
  }
}

export function mt5PlatformReducer(state = [], action: any) {
  switch (action.type) {
    case Action.GotMt4Sessions:
      return []
    case Action.GotMt4SymbolMap:
      return []
    default:
      return state
  }
}

class PlatformMapState {
  public data: any[]
  public totalCount: number

  constructor() {
    this.data = []
    this.totalCount = 0
  }
}

export function platformSymbolMapReducer(state = new PlatformMapState(), action: any) {
  switch (action.type) {
    case Action.GotPlatformSymbolMap:
      return {
        totalCount: action.data.TotalCount,
        data: action.data.Data.map((item: any) => new PlatformSymbol(item)),
      }
    default:
      return state
  }
}

class PlatformSessionsState {
  public data: Session[]
  public totalCount: number

  constructor() {
    this.data = []
    this.totalCount = 0
  }
}

export function platformSessionsReducer(state = new PlatformSessionsState(), action: any) {
  switch (action.type) {
    case Action.GotPlatformSessions:
      return {
        totalCount: action.data.TotalCount,
        data: action.data.Data.map((item: any) => new Session(item)),
      }
    default:
      return state
  }
}

export default platformsReducer
