import { Action } from '../actions/actions'

function tradingHistoryReducer(
  state = {
    deals: [],
    report: [],
    totalCount: 0,
  },
  action: any,
) {
  switch (action.type) {
    case Action.FlushDeals:
      return {
        deals: [],
        report: [],
        totalCount: 0,
      }
    case Action.GotReport:
      return {
        ...state,
        report: action.data,
      }
    case Action.GotDeals:
      return {
        ...state,
        deals:
          Object.keys(action.data).length > 2 && action.data.Data?.length
            ? [
                ...action.data.Data,
                {
                  MarkupUsd: action.data.MarkupUsd,
                  Profit: action.data.ProfitUsd,
                  TotalBrokerProfit: action.data.TotalBrokerProfitUsd,
                  TpBrokerProfit: action.data.TpBrokerProfitUsd,
                  OpenTotalBrokerProfit: action.data.OpenTotalBrokerProfitUsd,
                  CloseTotalBrokerProfit: action.data.CloseTotalBrokerProfitUsd,
                  OpenTpBrokerProfit: action.data.OpenTpBrokerProfitUsd,
                  CloseTpBrokerProfit: action.data.CloseTpBrokerProfitUsd,
                  TraderProfit: action.data.TraderProfitUsd,
                  BrokerProfit: action.data.BrokerProfitUsd,
                  OpenMarkup: action.data.OpenMarkupUsd,
                  CloseMarkup: action.data.CloseMarkupUsd,
                  LastTotalAllDeals: Object.keys(action.data).length > 2,
                },
              ].map((item: any) => {
                return {
                  ...item,
                  id: Math.random() * (10000 - 1 + 1) + 1,
                }
              })
            : action.data.Data.map((item: any) => {
                return {
                  ...item,
                  id: Math.random() * (10000 - 1 + 1) + 1,
                }
              }),
        totalCount: action.data.TotalCount,
      }
    default:
      return state
  }
}

export default tradingHistoryReducer
