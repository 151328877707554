import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { GatewaySettingsEntity } from '../../entity/system-settings'
import { useFormValidation } from '../../hooks/useFormValidation'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { RootState } from '../../redux/reducers/rootReducer'
import { buildControlsExtTwoPerLine, checkboxInput, mselectInput, textInput } from '../../utils/controls'
import { optionsToStrings } from '../../utils/multiselect-utils'
import { IRightbar } from './rightbar-types'

const GatewayRightbar: React.FC<IRightbar> = React.memo(({ data: { item, onSave } }) => {
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(new GatewaySettingsEntity(item), GatewaySettingsEntity.schema)
  const dispatch = useDispatch()
  const { gateway } = useSelector((state: RootState) => state.gateways)

  const handleSave = () => {
    if (!isValid()) {
      return
    }
    if (onSave) {
      onSave({
        ...inputState,
        ExposureCurrencies: optionsToStrings(inputState.ExposureCurrencies),
      })
    }
    dispatch(hideRightBar())
  }

  return (
    <Card>
      <Card.Header className="color-dark font-500">
        <FormattedMessage id="gateway.title" />
      </Card.Header>
      <Card.Body>
        {buildControlsExtTwoPerLine(
          [
            textInput('ServerApiAddress'),
            textInput('ClientApiAddress'),
            textInput('RestoreMaxPerSec'),
            textInput('LimitsCheckTimeout'),
            textInput('ImmediateOrdersCheckTimeout'),
            checkboxInput('AutoSwitchDST'),
            mselectInput('ExposureCurrencies', gateway.ExposureCurrencies).disabled(true),
          ],
          inputState,
          setInputState,
          'gateway',
          touched,
          setTouched,
          errors,
        )}
        <Button className="t4b-bg-dark-button mt-3" onClick={handleSave}>
          <FormattedMessage id="save" tagName="span" />
        </Button>
      </Card.Body>
    </Card>
  )
})

export default GatewayRightbar
