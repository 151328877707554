import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { buildHTTPGetOptions, checkResponse, processError } from '../../utils/fetch-utils'
import { buildInfoItems, buildVolumeDistribution, infoDirection, infoPrice, infoProfit, infoTime } from '../../utils/info-item-utils'
import { AppAccordion } from '@t4b/core/lib'
import { IRightbar } from './rightbar-types'

const MT5Positions: React.FC<IRightbar> = ({ data: params }) => {
  const [position, setPosition] = useState<any>({})
  const dispatch = useDispatch()

  useEffect(() => {
    const url = new URL('/api/position', window.location.origin)
    url.searchParams.set('gateway', params.Gateway)
    url.searchParams.set('platform', params.Platform)
    url.searchParams.set('id', params.position.Position)
    fetch(url.toString(), buildHTTPGetOptions())
      .then((response: Response) => checkResponse(response))
      .then((data: any) => setPosition(data))
      .catch((error: Error) => processError(error, dispatch))
  }, [params, dispatch])

  const additionalComponent = (
    <div className="row">
      <div className="col-6">{buildInfoItems(['ExternalID', 'ContractSize', 'Dealer', 'Comment'], position, 'mt5positions')}</div>
      <div className="col-6">{buildInfoItems(['Digits', 'Login', 'UserName', 'UserGroup'], position, 'mt5positions')}</div>
    </div>
  )

  return (
    <Card>
      <Card.Header style={{ color: 'rgb(141, 171, 196)', fontWeight: 500, fontSize: '13px', textTransform: 'uppercase' }}>
        <FormattedMessage id="position-details" />
      </Card.Header>
      <Card.Body style={{ fontSize: '13px' }}>
        <div className="row mb-3">
          <div className="col-6">{buildInfoItems(['Position', infoDirection('Direction')], position, 'mt5positions')}</div>
          <div className="col-6">{buildInfoItems(['Symbol', 'Volume', 'LotVolume'], position, 'mt5positions')}</div>
        </div>

        <div className="row mb-3">
          <div className="col-6">{buildInfoItems([infoPrice('OpenPrice'), infoPrice('CurrentPrice')], position, 'mt5positions')}</div>
          <div className="col-6">{buildInfoItems(['PriceSL', 'PriceTP'], position, 'mt5positions')}</div>
        </div>

        <div className="row mb-3">
          <div className="col-6">{buildInfoItems([infoTime('OpenTime'), infoProfit('Profit')], position, 'mt5positions')}</div>
          <div className="col-6">{buildInfoItems([infoTime('UpdateTime')], position, 'mt5positions')}</div>
        </div>
        <AppAccordion
          item={{
            title: <FormattedMessage id="additional" />,
            item: additionalComponent,
          }}
          style={{ margin: '0 -20px' }}
          isHidden={false}
        />

        <AppAccordion
          item={{
            title: <FormattedMessage id="volume-distribution" />,
            item: buildVolumeDistribution(position.Distribution, 'MT5Positions'),
          }}
          style={{ margin: '0 -20px -20px' }}
          isHidden={false}
          render={position?.Distribution?.length}
        />
      </Card.Body>
    </Card>
  )
}

export default MT5Positions
