import React from 'react'
import InfoItem from '../InfoItem'
import { AppAccordion } from '@t4b/core/lib'
import { IRightbar } from './rightbar-types'
import { IVolumeDistribution } from '../../entity/volume-distribution'
import { FormattedMessage } from 'react-intl'

function buildVolumeDistribution(arr: IVolumeDistribution[]): JSX.Element {
  if (!Array.isArray(arr)) {
    return <div />
  }
  return (
    <div className="row">
      <div className="col-6">
        {arr.map((item: IVolumeDistribution) => (
          <InfoItem key={item.Lp} first="distribution.Lp" second={item.Lp} />
        ))}
      </div>
      <div className="col-6">
        {arr.map((item: IVolumeDistribution) => (
          <InfoItem key={item.Lp} first="distribution.Volume" second={item.Volume} />
        ))}
      </div>
    </div>
  )
}

const CeVolumeDistributionRightbar: React.FC<IRightbar> = React.memo(({ data: { item } }) => {
  return (
    <AppAccordion
      item={{
        title: <FormattedMessage id="volume-distribution" />,
        item: buildVolumeDistribution(item.Distribution),
      }}
      isHidden={false}
    />
  )
})

export default CeVolumeDistributionRightbar
