import { Action } from '../actions/actions'

function positionsReducer(
  state = {
    positions: [],
    totalCount: 0,
  },
  action: any,
) {
  switch (action.type) {
    case Action.FlushPositions:
      return {
        positions: [],
        totalCount: 0,
      }
    case Action.GotPositions:
      return {
        ...state,
        positions:
          action.data.ProfitUsd && action.data.Data?.length
            ? [
                ...action.data.Data,
                {
                  Profit: action.data.ProfitUsd,
                  TotalProfit: action.data.ProfitUsd,
                  LastTotalAllDeals: true,
                },
              ]
            : action.data.Data,
        totalCount: action.data.TotalCount,
      }
    default:
      return state
  }
}

export default positionsReducer
