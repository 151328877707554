import { SummaryEntity } from '../../entity/summary'
import { Action } from '../actions/actions'

class SummaryState {
  public summary: any
  public fixSessions: any[]

  constructor() {
    this.summary = new SummaryEntity()
    this.fixSessions = []
  }
}
function summaryReducer(state = new SummaryState(), action: any) {
  switch (action.type) {
    case Action.SummaryInitial:
      return new SummaryState()
    case Action.GotSummary:
      return {
        ...state,
        summary: new SummaryEntity(action.data),
      }
    case Action.GotSummarySessions:
      return {
        ...state,
        fixSessions: action.data,
      }
    default:
      return state
  }
}

export default summaryReducer
