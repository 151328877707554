import { AppAccordion, AppTable } from '@t4b/core'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ITableColumn } from '../../../utils/table-types'
import { asTime, buildTableData, buildTableHeadersFromArray } from '../../../utils/table-utils'

const tableHeader: ITableColumn[] = [
  { name: 'Time', format: asTime, show: true },
  { name: 'Type', show: true },
  { name: 'Amount', show: true },
  { name: 'Comment', show: true },
]

interface IHistoryItem {
  Time: number
  Type: string
  Amount: number
  Comment: string
}

interface IMarginBalanceHistory {
  history: IHistoryItem[]
}

const MarginBalanceHistory: React.FC<IMarginBalanceHistory> = React.memo(({ history }) => {
  const table = <AppTable tableHeader={buildTableHeadersFromArray(tableHeader, 'margin-info')} tableData={buildTableData(history, tableHeader, null)} />

  return <AppAccordion item={{ title: <FormattedMessage id="margin-info.history" />, item: table }} />
})

export default MarginBalanceHistory
