import moment from 'moment'
import { Dispatch } from 'redux'
import { throwSuccessMessage, throwWarnMessage, throwErrorMessage, throwErrorMessageImport } from '../../utils/errors-utils'
import {
  buildHTTPGetOptions,
  buildHTTPPostOptions,
  checkResponse,
  checkTextResponse,
  checkTextResponseAxios,
  downloadFile,
  downloadFilePdf,
  fetchBuilder,
  modifyBuilder,
  onlyFetchBuilder,
  processError,
} from '../../utils/fetch-utils'
import { Action } from './actions'
import axios from 'axios'
import { getJwt, removeJwt, removeTokenRefresh, removeUser } from '../../redux/actions/authentication'
import { urlPaths } from '../../utils/urlPaths'
import { hideRightBar } from './rightbar-actions'
import { hideModal } from './modal-actions'

export function onlyFetchGateways() {
  return fetch('/api/gateways', buildHTTPGetOptions())
}

export function fetchGateways() {
  return (dispatch: Dispatch, getState: any) => {
    const url = new URL('/api/gateways', document.location.origin)
    // const prevSatate = getState()

    return axios(url.toString(), {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    })
      .then((statusResponse: any) => {
        dispatch({ type: Action.GotGateways, data: statusResponse.data })
      })
      .catch((error: Error) => {
        processError(error, dispatch)
      })
  }
}

export function fetchGatewayStatus(params: any) {
  return (dispatch: Dispatch, getState: any) => {
    const { status } = getState()
    const url = new URL('/api/gateway/status', document.location.origin)
    url.searchParams.set('gateway', params.Gateway)

    return axios(url.toString(), {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    })
      .then((statusResponse: any) => {
        if (status.Status === statusResponse.data.Status) {
          return
        } else {
          dispatch({ type: Action.GotStatus, data: statusResponse.data })
        }
      })
      .catch(() => {
        removeJwt()
        removeUser()
        removeTokenRefresh()
        dispatch(hideRightBar())
        dispatch(hideModal())
        localStorage.clear()
        window.location.reload()
        dispatch({ type: Action.Unauthorized })
        sessionStorage.clear()
      })
  }
}

export function fetchGatewayReboot(params: any) {
  return (dispatch: Dispatch) => {
    const url = new URL('/api/gateway/reboot', document.location.origin)
    url.searchParams.set('gateway', params.Gateway)

    return fetch(url.toString(), buildHTTPPostOptions(null))
      .then((response: Response) => checkResponse(response))
      .then((result: any) => {
        if (result.Status) {
          return new Promise((resolve, reject) => reject(new Error(result.Status.toString())))
        }
        return new Promise((resolve, reject) => resolve(1))
      })
      .catch((error: Error) => processError(error, dispatch))
  }
}

export const onlyFetchGlobalSymbolMap = onlyFetchBuilder('/api/globalSymbolMap', { Page: true, Count: true, Search: true })
export const fetchGlobalSymbolMap = fetchBuilder(onlyFetchGlobalSymbolMap, Action.GotGlobalSymbolMap)
export const modifyGlobalSymbolMap = modifyBuilder('/api/globalSymbolMap', onlyFetchGlobalSymbolMap, Action.GotGlobalSymbolMap)
export const deleteGlobalSymbolMap = modifyBuilder('/api/globalSymbolMap', onlyFetchGlobalSymbolMap, Action.GotGlobalSymbolMap)

export const onlyFetchPlatforms = onlyFetchBuilder('/api/platforms')
export const fetchPlatforms = fetchBuilder(onlyFetchPlatforms, Action.GotPlatforms)

export function fetchAddPlatform1(params: any, data: any) {
  return (dispatch: Dispatch) => {
    const url = new URL('/api/platforms/add', document.location.origin)
    url.searchParams.set('gateway', params.Gateway)

    if (data.settings.StartTime) {
      data.settings.StartTime = data.settings.StartTime.getTime() - moment(data.settings.StartTime).startOf('day').toDate().getTime()
    }
    if (data.settings.EndTime) {
      data.settings.EndTime = data.settings.EndTime.getTime() - moment(data.settings.EndTime).startOf('day').toDate().getTime()
    }

    const platform = {
      ...data.settings,
      ...data.common,
      ConnectionString: data.connectionString,
      CoverageConnectionString: data.common.Type === 'fixapi' ? data.covConnectionString : undefined,
      RMQ: data.common.Type === 'mt4' ? data.rmq : undefined,
    }
    return fetch(url.toString(), buildHTTPPostOptions(platform))
      .then((response: Response) => checkResponse(response))
      .then((result: any) => {
        if (result.Status) {
          return new Promise((resolve, reject) => reject(new Error('error.404')))
        }
        throwSuccessMessage('Successfully')
        return onlyFetchPlatforms(params)
      })
      .then((obj: any) => dispatch({ type: Action.GotPlatforms, data: obj }))
      .catch((error: Error) => processError(error, dispatch))
  }
}

export function fetchAddPlatform(arg: any) {
  return (dispatch: Dispatch) => {
    const { params, body } = arg
    const url = new URL('/api/platforms/add', document.location.origin)
    url.searchParams.set('gateway', params.Gateway)

    return fetch(url.toString(), buildHTTPPostOptions(body))
      .then((response: Response) => checkResponse(response))
      .then((result: any) => {
        if (result.Status) {
          if (result.Status === 1) {
            throwErrorMessage(`${result.Description}`, true)
          } else {
            return new Promise((resolve, reject) => reject(new Error('error.404')))
          }
        } else {
          throwSuccessMessage('Successfully')
        }

        return onlyFetchPlatforms(params)
      })
      .then((obj: any) => dispatch({ type: Action.GotPlatforms, data: obj }))
      .catch((error: Error) => processError(error, dispatch))
  }
}

export function deletePlatform(params: any) {
  return (dispatch: Dispatch) => {
    const url = new URL('/api/platforms/delete', document.location.origin)
    url.searchParams.set('gateway', params.Gateway)

    const body = {
      Name: params.Name,
    }
    return fetch(url.toString(), buildHTTPPostOptions(body))
      .then((response: Response) => checkResponse(response))
      .then((result: any) => {
        if (result.Status) {
          if (result.Status === 1) {
            throwWarnMessage(`platform.status.${result.Status}`)
          } else {
            return new Promise((resolve, reject) => reject(new Error(`platform.status.${result.Status}`)))
          }
        }
        return onlyFetchPlatforms(params)
      })
      .then((obj: any) => dispatch({ type: Action.GotPlatforms, data: obj }))
      .catch((error: Error) => processError(error, dispatch))
  }
}

export function togglePlatform(params: any) {
  return (dispatch: Dispatch) => {
    const url = new URL('/api/platforms/setEnable', document.location.origin)
    url.searchParams.set('gateway', params.Gateway)

    const body = {
      Name: params.Name,
      Flag: params.Enabled,
    }
    return fetch(url.toString(), buildHTTPPostOptions(body))
      .then((response: Response) => checkResponse(response))
      .then((result: any) => {
        if (result.Status) {
          return new Promise((resolve, reject) => reject(new Error('error.404')))
        }
        return onlyFetchPlatforms(params)
      })
      .then((obj: any) => dispatch({ type: Action.GotPlatforms, data: obj }))
      .catch((error: Error) => processError(error, dispatch))
  }
}

export function fetchPlatformReloadManApi(params: any) {
  return (dispatch: Dispatch) => {
    const url = new URL('/api/platforms/reloadManApi', document.location.origin)
    url.searchParams.set('gateway', params.Gateway)
    url.searchParams.set('platform', params.Name)

    return fetch(url.toString(), buildHTTPPostOptions(null))
      .then((response: Response) => checkResponse(response))
      .then((result: any) => {
        if (result.Status) {
          return new Promise((resolve, reject) => reject(new Error(result.Status.toString())))
        }
        return new Promise((resolve, reject) => resolve(1))
      })
      .catch((error: Error) => processError(error, dispatch))
  }
}

export function fetchPlatformReloadGateApi(params: any) {
  return (dispatch: Dispatch) => {
    const url = new URL('/api/platforms/reloadGateApi', document.location.origin)
    url.searchParams.set('gateway', params.Gateway)
    url.searchParams.set('platform', params.Name)

    return fetch(url.toString(), buildHTTPPostOptions(null))
      .then((response: Response) => checkResponse(response))
      .then((result: any) => {
        if (result.Status) {
          return new Promise((resolve, reject) => reject(new Error(result.Status.toString())))
        }
        return new Promise((resolve, reject) => resolve(1))
      })
      .catch((error: Error) => processError(error, dispatch))
  }
}

export const modifyPlatform = modifyBuilder('/api/platforms', onlyFetchPlatforms, Action.GotPlatforms)

export const onlyFetchPlatformSymbolMap = onlyFetchBuilder('/api/platform/symbolMap', { Platform: true })
export const fetchPlatformSymbolMap = fetchBuilder(onlyFetchPlatformSymbolMap, Action.GotPlatformSymbolMap)

export const fetchPlatformSymbolMapDelete = modifyBuilder('/api/platform/symbolMap', onlyFetchPlatformSymbolMap, Action.GotPlatformSymbolMap, {
  Platform: true,
})
export const fetchPlatformSymbolMapAdd = modifyBuilder('/api/platform/symbolMap', onlyFetchPlatformSymbolMap, Action.GotPlatformSymbolMap, { Platform: true })

export const onlyFetchPlatformSessions = onlyFetchBuilder('/api/platform/sessions', { Platform: true, Page: true, Count: true, Search: true })
export const fetchPlatformSessions = fetchBuilder(onlyFetchPlatformSessions, Action.GotPlatformSessions)
export const fetchPlatformSessionsUpdate = modifyBuilder('/api/platform/sessions', onlyFetchPlatformSessions, Action.GotPlatformSessions, { Platform: true }, (status: number) => {
  return new Promise((resove, reject) => reject(new Error('sessions.same-session-name')))
})

export const onlyFetchPlatformAccountsNames = onlyFetchBuilder('/api/platform/accountsNames', { Platform: true })
export const onlyFetchPlatformGroupsNames = onlyFetchBuilder('/api/platform/groupsNames', { Platform: true })

export const onlyFetchSendercompTargetcomp = onlyFetchBuilder('api/platform/GetAllSessions', {})

export const onlyFetchPlatformAccounts = onlyFetchBuilder('/api/platform/accounts', { Platform: true, Page: true, Count: true, Search: true })
export const fetchPlatformAccounts = fetchBuilder(onlyFetchPlatformAccounts, Action.GotFixAccounts)
export const fetchPlatformAccountsUpdate = modifyBuilder('/api/platform/accounts', onlyFetchPlatformAccounts, Action.GotFixAccounts, { Platform: true })

export const onlyFetchPlatformMarginInfo = onlyFetchBuilder('/api/platform/marginInfo', { Platform: true, Page: true, Count: true })
export const fetchPlatformGotFixMarginInfo = fetchBuilder(onlyFetchPlatformMarginInfo, Action.GotFixMarginInfo)
export const fetchPlatformGotFixMarginInfoUpdate = modifyBuilder('/api/platform/marginInfo', onlyFetchPlatformMarginInfo, Action.GotFixMarginInfo, {
  Platform: true,
})
export const onlyFetchMarginInfoDepositHistory = onlyFetchBuilder('/api/platform/marginInfoDepositHistory', { Platform: true, Login: true })
export const fetchMarginInfoUpdate = modifyBuilder('/api/platform/marginInfo', onlyFetchPlatformMarginInfo, Action.GotFixMarginInfo, {
  Platform: true,
  Login: true,
})

export const onlyFetchPlatformSwaps = onlyFetchBuilder('/api/platform/swaps', { Platform: true, Page: true, Count: true, Search: true })
export const fetchPlatformSwaps = fetchBuilder(onlyFetchPlatformSwaps, Action.GotFixSwaps)
export const fetchPlatformSwapsUpdate = modifyBuilder('/api/platform/swaps', onlyFetchPlatformSwaps, Action.GotFixSwaps, { Platform: true })

export const onlyFetchPlatformFixSymbols = onlyFetchBuilder('/api/platform/fixSymbolMap', { Platform: true, Page: true, Count: true, Search: true })
export const fetchPlatformFixSymbols = fetchBuilder(onlyFetchPlatformFixSymbols, Action.GotFixSymbolMap)
export const fetchPlatformFixSymbolsUpdate = modifyBuilder('/api/platform/fixSymbolMap', onlyFetchPlatformFixSymbols, Action.GotFixSymbolMap, {
  Platform: true,
})

export const onlyFetchPlatformExtSymbols = onlyFetchBuilder('/api/platform/extSymbolMap', { Platform: true, Page: true, Count: true, Search: true })
export const fetchPlatformExtSymbols = fetchBuilder(onlyFetchPlatformExtSymbols, Action.GotExtSymbolMap)
export const fetchPlatformExtSymbolsUpdate = modifyBuilder('/api/platform/extSymbolMap', onlyFetchPlatformExtSymbols, Action.GotExtSymbolMap, {
  Platform: true,
})

export const onlyFetchUsers = onlyFetchBuilder('/api/users', { Page: true, Count: true, Search: true })
export const fetchUsers = fetchBuilder(onlyFetchUsers, Action.GotUsers)
export const fetchUsersUpdate = modifyBuilder('/api/users', onlyFetchUsers, Action.GotUsers)

export const onlyFetchLeverageProfile = onlyFetchBuilder('/api/platform/CustomSymbolSettings', { Platform: true, Page: true, Count: true })
export const fetchLeverageProfile = fetchBuilder(onlyFetchLeverageProfile, Action.GotLeverageProfile)

export const onlyFetchLeverageProfileFromAccounts = onlyFetchBuilder('/api/platform/CustomSymbolSettingsProfiles', { Platform: true })
export const fetchLeverageProfileFromAccounts = fetchBuilder(onlyFetchLeverageProfileFromAccounts, Action.GotAccountsProfile)

export function fetchFixSymbolsDownload(params: any, body: any) {
  const url = new URL('/api/platform/FixSymbolMapDownload', window.location.origin)
  url.searchParams.set('Type', params.FileType)
  url.searchParams.set('gateway', params.Gateway)
  url.searchParams.set('Platform', params.Platform)
  url.searchParams.set('Page', params.Page)
  url.searchParams.set('Count', params.Count)
  if (params.Search) {
    url.searchParams.set('Search', params.Search)
  }

  return (dispatch: Dispatch) => {
    return fetch(url.toString(), buildHTTPPostOptions(body))
      .then((response: Response) => checkTextResponse(response))
      .then((data: any) => downloadFile(`Platform:${params.Platform}-One_Page.${params.FileType}`, data))
      .catch((error: Error) => processError(error, dispatch))
  }
}

export const fetchAddOrUpdateLeverageProfile = async (params?: any) => {
  const url = new URL(`/api/platform/CustomSymbolSettings${params.type === 'modify' ? 'Modify' : 'Add'}`, window.location.origin)
  url.searchParams.set('gateway', params.Gateway)
  url.searchParams.set('Platform', params.Platform)

  try {
    await axios.post(
      `${url}`,
      { ...params.body },
      {
        headers: {
          Authorization: `Bearer ${getJwt()}`,
        },
      },
    )
  } catch (e) {
    processError(e, params.dispatch)
  }
}

export function fetchLeverageProfileDelete(params: any) {
  return (dispatch: Dispatch) => {
    const url = new URL('/api/platform/CustomSymbolSettingsDelete', window.location.origin)
    url.searchParams.set('gateway', params.Gateway)
    url.searchParams.set('Platform', params.Platform)

    dispatch({ type: Action.InProgressStart })
    params.setLoading(true)

    return fetch(url.toString(), buildHTTPPostOptions({ Id: params.Id }))
      .then((response: Response) => checkResponse(response))
      .then((result: any) => {
        if (result.Status) {
          if (result.Description) {
            throwErrorMessageImport(result.Description)
          } else {
            return new Promise((resolve, reject) => reject(new Error('error.404')))
          }
        } else {
          throwSuccessMessage('Successfully')
          dispatch({ type: Action.DeleteLeverageProfile, data: { Id: params.Id } })
        }
      })
      .then((data: any) => dispatch({ type: Action.GotConsolidationRules, data }))
      .catch((error: Error) => processError(error, dispatch))
      .finally(() => {
        dispatch({ type: Action.InProgressEnd })
        params.setLoading(false)
      })
  }
}

export function fetchFixSymbolsDownloadAll(params: any, body: any) {
  const url = new URL('/api/platform/FixSymbolMapDownload', window.location.origin)
  url.searchParams.set('Type', params.FileType)
  url.searchParams.set('gateway', params.Gateway)
  url.searchParams.set('Platform', params.Platform)
  if (params.Search) {
    url.searchParams.set('Search', params.Search)
  }

  return (dispatch: Dispatch) => {
    return fetch(url.toString(), buildHTTPPostOptions(body))
      .then((response: Response) => checkTextResponse(response))
      .then((data: any) => downloadFile(`Platform:${params.Platform}-All_Pages.${params.FileType}`, data))
      .catch((error: Error) => processError(error, dispatch))
  }
}

export function fetchFixSymbolsAdd(params: any, body: any) {
  const url = new URL('/api/platform/FixSymbolMapImportAdd', window.location.origin)
  url.searchParams.set('gateway', params.Gateway)
  url.searchParams.set('Platform', params.Platform)

  return (dispatch: Dispatch) => {
    return axios
      .post(url.toString(), body, {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Bearer ${getJwt()}`,
        },
      })
      .then(data => checkTextResponseAxios(data))
      .catch((error: Error) => processError(error, dispatch))
  }
}

export function fetchFixSymbolsReplace(params: any, body: any) {
  const url = new URL('/api/platform/FixSymbolMapImportReplace', window.location.origin)
  url.searchParams.set('gateway', params.Gateway)
  url.searchParams.set('Platform', params.Platform)

  return (dispatch: Dispatch) => {
    return axios
      .post(url.toString(), body, {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Bearer ${getJwt()}`,
        },
      })
      .then(data => checkTextResponseAxios(data))
      .catch((error: Error) => processError(error, dispatch))
  }
}

export function fetchFixSymbolsResetDefaults(params: any) {
  const url = new URL('/api/platform/ResetDefaults', window.location.origin)
  url.searchParams.set('gateway', params.Gateway)
  url.searchParams.set('Platform', params.Platform)

  return (dispatch: Dispatch) => {
    return axios
      .get(url.toString(), {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Bearer ${getJwt()}`,
        },
      })
      .then(data => checkTextResponseAxios(data))
      .catch((error: Error) => processError(error, dispatch))
  }
}

//================

export const fetchGlobalSymbolMultipleModify = async (params?: any) => {
  const url = new URL(`/api/GlobalSymbolMap/MultipleModify`, window.location.origin)
  url.searchParams.set('gateway', params.Gateway)

  try {
    await axios.post(
      `${url}`,
      { ...params.body },
      {
        headers: {
          Authorization: `Bearer ${getJwt()}`,
        },
      },
    )
    throwSuccessMessage('Successfully')
  } catch (e) {
    processError(e, params.dispatch)
  }
}

export const fetchGlobalSymbolGetAllowedModify = async (params?: any) => {
  const url = new URL(`/api/GlobalSymbolMap/GetAllowed`, window.location.origin)
  url.searchParams.set('gateway', params.Gateway)

  try {
    const { data } = await axios.post(
      `${url}`,
      { ...params.body },
      {
        headers: {
          Authorization: `Bearer ${getJwt()}`,
        },
      },
    )

    params.state(data)
  } catch (e) {
    processError(e, params.dispatch)
  }
}
export function fetchExtSymbolsDownload(params: any, body: any) {
  const url = new URL('/api/platform/ExtSymbolMapDownload', window.location.origin)
  url.searchParams.set('Type', params.FileType)
  url.searchParams.set('gateway', params.Gateway)
  url.searchParams.set('Platform', params.Platform)
  url.searchParams.set('Page', params.Page)
  url.searchParams.set('Count', params.Count)
  if (params.Search) {
    url.searchParams.set('Search', params.Search)
  }

  return (dispatch: Dispatch) => {
    return fetch(url.toString(), buildHTTPPostOptions(body))
      .then((response: Response) => checkTextResponse(response))
      .then((data: any) => downloadFile(`Platform:${params.Platform}-One_Page.${params.FileType}`, data))
      .catch((error: Error) => processError(error, dispatch))
  }
}

export function fetchExtSymbolsDownloadAll(params: any, body: any) {
  const url = new URL('/api/platform/ExtSymbolMapDownload', window.location.origin)
  url.searchParams.set('Type', params.FileType)
  url.searchParams.set('gateway', params.Gateway)
  url.searchParams.set('Platform', params.Platform)
  if (params.Search) {
    url.searchParams.set('Search', params.Search)
  }

  return (dispatch: Dispatch) => {
    return fetch(url.toString(), buildHTTPPostOptions(body))
      .then((response: Response) => checkTextResponse(response))
      .then((data: any) => downloadFile(`Platform:${params.Platform}-All_Pages.${params.FileType}`, data))
      .catch((error: Error) => processError(error, dispatch))
  }
}

export function fetchLpSymbolsDownloadAll(params: any, body: any) {
  const url = new URL('/api/lp/SymbolMapDownload', window.location.origin)
  url.searchParams.set('Type', params.FileType)
  url.searchParams.set('gateway', params.Gateway)
  url.searchParams.set('Lp', params.Lp)
  if (params.Search) {
    url.searchParams.set('Search', params.Search)
  }

  return (dispatch: Dispatch) => {
    return fetch(url.toString(), buildHTTPPostOptions(body))
      .then((response: Response) => checkTextResponse(response))
      .then((data: any) => downloadFile(`Lp:${params.Lp}-${params.LpType}-All_Pages.${params.FileType}`, data))
      .catch((error: Error) => processError(error, dispatch))
  }
}

export function fetchLpSymbolsDownload(params: any, body: any) {
  const url = new URL('/api/lp/SymbolMapDownload', window.location.origin)
  url.searchParams.set('Type', params.FileType)
  url.searchParams.set('gateway', params.Gateway)
  url.searchParams.set('Lp', params.Lp)
  url.searchParams.set('Page', params.Page)
  url.searchParams.set('Count', params.Count)
  if (params.Search) {
    url.searchParams.set('Search', params.Search)
  }

  return (dispatch: Dispatch) => {
    return fetch(url.toString(), buildHTTPPostOptions(body))
      .then((response: Response) => checkTextResponse(response))
      .then((data: any) => downloadFile(`Lp:${params.Lp}-${params.LpType}-One_page.${params.FileType}`, data))
      .catch((error: Error) => processError(error, dispatch))
  }
}

///////////////////////
export function fetchGlobalSymbolsDownload(params: any, body: any) {
  const url = new URL('/api/globalSymbolMap/export', window.location.origin)
  url.searchParams.set('gateway', params.Gateway)
  url.searchParams.set('Type', params.FileType)
  url.searchParams.set('Page', params.Page)
  url.searchParams.set('Count', params.Count)
  if (params.field) {
    url.searchParams.set('sort', params.field)
    url.searchParams.set('by', params.by)
  }

  if (params.Search) {
    url.searchParams.set('Search', params.Search)
  }

  return (dispatch: Dispatch) => {
    return fetch(url.toString(), buildHTTPGetOptions())
      .then((response: Response) => checkTextResponse(response, '/api/globalSymbolMap/export'))
      .then((data: any) => downloadFile(`GlobalSymbols-One_page.${params.FileType}`, data))
      .catch((error: Error) => processError(error, dispatch))
  }
}

export function fetchGlobalSymbolsDownloadAll(params: any, body: any) {
  const url = new URL('/api/globalSymbolMap/export', window.location.origin)
  url.searchParams.set('gateway', params.Gateway)
  url.searchParams.set('Type', params.FileType)
  if (params.field) {
    url.searchParams.set('sort', params.field)
    url.searchParams.set('by', params.by)
  }
  if (params.Search) {
    url.searchParams.set('Search', params.Search)
  }

  return (dispatch: Dispatch) => {
    return fetch(url.toString(), buildHTTPGetOptions())
      .then((response: Response) => checkTextResponse(response, '/api/globalSymbolMap/export'))
      .then((data: any) => downloadFile(`GlobalSymbols-All_Pages.${params.FileType}`, data))
      .catch((error: Error) => processError(error, dispatch))
  }
}
///////////////////////

export function fetchSessionDownload(params: any, body: any) {
  const url = new URL('api/platform/sessionsDownload', window.location.origin)
  url.searchParams.set('gateway', params.Gateway)
  url.searchParams.set('Platform', params.Platform)

  return (dispatch: Dispatch) => {
    return fetch(url.toString(), buildHTTPPostOptions(body))
      .then((response: Response) => checkTextResponse(response))
      .then((data: any) => downloadFilePdf(`Session:${params.Platform}`, data))
      .catch((error: Error) => processError(error, dispatch))
  }
}

export function fetchExtSymbolsAdd(params: any, body: any) {
  const url = new URL('/api/platform/ExtSymbolMapImportAdd', window.location.origin)
  url.searchParams.set('gateway', params.Gateway)
  url.searchParams.set('Platform', params.Platform)

  return (dispatch: Dispatch) => {
    return axios
      .post(url.toString(), body, {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Bearer ${getJwt()}`,
        },
      })
      .then(data => checkTextResponseAxios(data))
      .catch((error: Error) => processError(error, dispatch))
  }
}

export function fetchExtSymbolsReplace(params: any, body: any) {
  const url = new URL('/api/platform/ExtSymbolMapImportReplace', window.location.origin)
  url.searchParams.set('gateway', params.Gateway)
  url.searchParams.set('Platform', params.Platform)

  return (dispatch: Dispatch) => {
    return axios
      .post(url.toString(), body, {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Bearer ${getJwt()}`,
        },
      })
      .then(data => checkTextResponseAxios(data))
      .catch((error: Error) => processError(error, dispatch))
  }
}
//=======================================================================================================================================
export function fetchFixSymbolsDiff(params: any, body: any) {
  const url = new URL('/api/platform/FixSymbolMapImportDiff', window.location.origin)
  url.searchParams.set('gateway', params.Gateway)
  url.searchParams.set('Platform', params.Platform)

  return (dispatch: Dispatch) => {
    return axios
      .post(url.toString(), body, {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Bearer ${getJwt()}`,
        },
      })
      .then(data => checkTextResponseAxios(data))
      .catch((error: Error) => processError(error, dispatch))
  }
}

export function fetchFixSymbolsRewrite(params: any, body: any) {
  const url = new URL('/api/platform/FixSymbolMapImportRewrite', window.location.origin)
  url.searchParams.set('gateway', params.Gateway)
  url.searchParams.set('Platform', params.Platform)

  return (dispatch: Dispatch) => {
    return axios
      .post(url.toString(), body, {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Bearer ${getJwt()}`,
        },
      })
      .then(data => checkTextResponseAxios(data))
      .catch((error: Error) => processError(error, dispatch))
  }
}

//=======================================================================================================================================
export function fetchExtSymbolsDiff(params: any, body: any) {
  const url = new URL('/api/platform/ExtSymbolMapImportDiff', window.location.origin)
  url.searchParams.set('gateway', params.Gateway)
  url.searchParams.set('Platform', params.Platform)

  return (dispatch: Dispatch) => {
    return axios
      .post(url.toString(), body, {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Bearer ${getJwt()}`,
        },
      })
      .then(data => checkTextResponseAxios(data))
      .catch((error: Error) => processError(error, dispatch))
  }
}

export function fetchExtSymbolsRewrite(params: any, body: any) {
  const url = new URL('/api/platform/ExtSymbolMapImportRewrite', window.location.origin)
  url.searchParams.set('gateway', params.Gateway)
  url.searchParams.set('Platform', params.Platform)

  return (dispatch: Dispatch) => {
    return axios
      .post(url.toString(), body, {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Bearer ${getJwt()}`,
        },
      })
      .then(data => checkTextResponseAxios(data))
      .catch((error: Error) => processError(error, dispatch))
  }
}
////////////////////////  LP  ///////////////////////////////////

export function fetchLpSymbolsDiff(params: any, body: any) {
  const url = new URL('/api/lp/SymbolMapImportDiff', window.location.origin)
  url.searchParams.set('gateway', params.Gateway)
  url.searchParams.set('Lp', params.Lp)

  return (dispatch: Dispatch) => {
    return axios
      .post(url.toString(), body, {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Bearer ${getJwt()}`,
        },
      })
      .then(data => checkTextResponseAxios(data))
      .catch((error: Error) => processError(error, dispatch))
  }
}

export function fetchLpSymbolsRewrite(params: any, body: any) {
  const url = new URL('/api/lp/SymbolMapImportRewrite', window.location.origin)
  url.searchParams.set('gateway', params.Gateway)
  url.searchParams.set('Lp', params.Lp)

  return (dispatch: Dispatch) => {
    return axios
      .post(url.toString(), body, {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Bearer ${getJwt()}`,
        },
      })
      .then(data => checkTextResponseAxios(data))
      .catch((error: Error) => processError(error, dispatch))
  }
}

export function fetchLpSymbolsAdd(params: any, body: any) {
  const url = new URL('/api/lp/SymbolMapImportAdd', window.location.origin)
  url.searchParams.set('gateway', params.Gateway)
  url.searchParams.set('Lp', params.Lp)

  return (dispatch: Dispatch) => {
    return axios
      .post(url.toString(), body, {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Bearer ${getJwt()}`,
        },
      })
      .then(data => checkTextResponseAxios(data))
      .catch((error: Error) => processError(error, dispatch))
  }
}

export function fetchLpSymbolsReplace(params: any, body: any) {
  const url = new URL('/api/lp/SymbolMapImportReplace', window.location.origin)
  url.searchParams.set('gateway', params.Gateway)
  url.searchParams.set('Lp', params.Lp)

  return (dispatch: Dispatch) => {
    return axios
      .post(url.toString(), body, {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Bearer ${getJwt()}`,
        },
      })
      .then(data => checkTextResponseAxios(data))
      .catch((error: Error) => processError(error, dispatch))
  }
}

////////// GlobalSymbols //////////

export function fetchGlobalSymbolsReplace(params: any, body: any) {
  const url = new URL('/api/globalSymbolMap/importReplace', window.location.origin)
  url.searchParams.set('gateway', params.Gateway)

  return (dispatch: Dispatch) => {
    return axios
      .post(url.toString(), body, {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Bearer ${getJwt()}`,
          Timestamp: sessionStorage.getItem('/api/globalSymbolMap/export') ? JSON.parse(sessionStorage.getItem('/api/globalSymbolMap/export') ?? '') : '',
        },
      })
      .then((data: any) => {
        sessionStorage.setItem(urlPaths['/api/globalSymbolMap/export'], JSON.stringify(data.headers.get('Timestamp') ?? ''))
        checkTextResponseAxios(data)
      })
      .catch((error: Error) => processError(error, dispatch))
  }
}

export function fetchGlobalSymbolsAdd(params: any, body: any) {
  const url = new URL('/api/globalSymbolMap/importAdd', window.location.origin)
  url.searchParams.set('gateway', params.Gateway)

  return (dispatch: Dispatch) => {
    return axios
      .post(url.toString(), body, {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Bearer ${getJwt()}`,
          Timestamp: sessionStorage.getItem('/api/globalSymbolMap/export') ? JSON.parse(sessionStorage.getItem('/api/globalSymbolMap/export') ?? '') : '',
        },
      })
      .then((data: any) => {
        sessionStorage.setItem(urlPaths['/api/globalSymbolMap/export'], JSON.stringify(data.headers.get('Timestamp') ?? ''))
        checkTextResponseAxios(data)
      })
      .catch((error: Error) => processError(error, dispatch))
  }
}
