import React, { useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import Delimiter from './Delimiter';
import ValueContainer from './ValueContainer';
import IconOpen from './IconOpen';
import ColorPickerOptions from './ColorPickerOptions';
import './color-picker.scss';
import classNames from 'classnames';
import { fromArray, toArray } from './utils';

function useClickOutside(callback) {
  const ref = useRef(null);

  useEffect(() => {
    function onClick(event) {
      if (!ref.current?.contains(event.target)) {
        if (typeof callback === 'function') {
          callback();
        }
      }
    }
    window.addEventListener('click', onClick);

    return () => {
      window.removeEventListener('click', onClick);
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return ref;
}


function ColorPicker({
  value: initialState,
  onChange,
  onBlur,
  options,
  placeholder,
  disabled
}) {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8]
        }
      }
    ]
  });
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(fromArray(initialState));
  const containerRef = useClickOutside(() => {
    setIsOpen(false)
  });

  useEffect(() => {
    setSelected(fromArray(initialState))
  }, [initialState])

  const handleToggle = (event) => {

    if (disabled) {
      return;
    }

    if (popperElement?.contains(event.target)) {
      return;
    }

    setIsOpen(!isOpen);
  }

  const handleAddColor = (color) => {
    const newSelected = [...selected, color];
    setSelected(newSelected);

    if (update) {
      update();
    }

    if (onChange) {
      onChange(toArray(newSelected));
    }
  }

  const handleDeleteColor = (color) => {
    if (disabled) {
      return;
    }

    const newSelected = selected.filter(item => item.name !== color.name);
    setSelected(newSelected);
    if (update) {
      update();
    }

    if (onChange) {
      onChange(toArray(newSelected));
    }
  }

  const handleBlur = () => {
    if (typeof onBlur === 'function') {
      onBlur();
    }
  }

  const classes = classNames('color-picker', disabled && 'color-picker__disabled');

  return (
    <div
      ref={containerRef}
      className={classes}
      onClick={handleToggle}
      onBlur={handleBlur}
      tabIndex={disabled ? null : 0}
    >

      <ValueContainer
        selected={selected}
        onValueDelete={handleDeleteColor}
        setReferenceElement={setReferenceElement}
        placeholder={placeholder}
      />

      <Delimiter/>

      <IconOpen/>

      <ColorPickerOptions
        opened={isOpen}
        setPopperElement={setPopperElement}
        styles={styles}
        attributes={attributes}
        options={options}
        selected={selected}
        handleAddColor={handleAddColor}
      />
    </div>
  )
}

export default ColorPicker;
