import { Action } from '../actions/actions'
import { buildMultiselectOptionsFromArray } from '../../utils/multiselect-utils'
import { IMultiSelectItem } from '../../entity/multiselect'

const INITIAL_STATE: any = {
  Data: [],
  Rules: {},
  defaultRules: {},
  defaultData: [],
  TotalCount: 0,
  defaultTotalCount: 0,
}

export interface ILeverageRules {
  Id: number
  IsActive: boolean
  Leverage: number | string
  Name: string
  Symbols: IMultiSelectItem[]
}

export interface ILeverageProfile {
  Id: number
  Name: string
  DefaultLeverage?: number | string
  Rules: ILeverageRules[]
}

function leverageProfileReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case Action.GotLeverageProfile:
      return {
        ...state,
        Data: action?.data?.Data.map((item: ILeverageProfile) => {
          if (item.Rules.slice(-1)) {
            return {
              ...item,
              DefaultLeverage: item.Rules.slice(-1)[0]?.Leverage,
            }
          }
        }),
        defaultData: action?.data?.Data.map((item: ILeverageProfile) => {
          if (item.Rules.slice(-1)) {
            return {
              ...item,
              DefaultLeverage: item.Rules.slice(-1)[0]?.Leverage,
            }
          }
        }),
        Rules: action?.data?.Data.reduce((acc: any, rec: any) => {
          return {
            ...acc,
            [rec.Id]: rec.Rules.map((item: ILeverageRules) => {
              if (item.Name === 'Default') {
                return {
                  ...item,
                  Symbols: buildMultiselectOptionsFromArray(item.Symbols),
                }
              } else {
                return {
                  ...item,
                  Symbols: buildMultiselectOptionsFromArray(item.Symbols),
                }
              }
            }),
          }
        }, {}),
        defaultRules: action?.data?.Data.reduce((acc: any, rec: any) => {
          return {
            ...acc,
            [rec.Id]: rec.Rules.map((item: ILeverageRules) => {
              if (item.Name === 'Default') {
                return {
                  ...item,
                  Symbols: buildMultiselectOptionsFromArray(item.Symbols),
                }
              } else {
                return {
                  ...item,
                  Symbols: buildMultiselectOptionsFromArray(item.Symbols),
                }
              }
            }),
          }
        }, {}),
        TotalCount: action?.data?.TotalCount,
        defaultTotalCount: action?.data?.TotalCount,
      }

    ///////////////
    case Action.AddLeverageProfile:
      return {
        ...state,
        Data: [...state.Data, action?.data?.data],
        Rules: [...state.Data, action?.data?.data].reduce((acc: any, rec: any) => {
          return {
            ...acc,
            [rec.Id]: rec.Rules,
          }
        }, {}),
        TotalCount: state.TotalCount + 1,
      }
    //////////
    case Action.DeleteLeverageRules:
      return {
        ...state,
        Rules: {
          ...state.Rules,
          [action.data.Id]: state.Rules[action.data.Id].filter((item: ILeverageRules) => item.Id !== action.data.ruleId),
        },
      }

    case Action.DeleteLeverageProfile:
      return {
        ...state,
        Data: state?.Data.filter((item: ILeverageProfile) => item.Id !== action.data.Id),
        defaultData: state?.Data.filter((item: ILeverageProfile) => item.Id !== action.data.Id),
        TotalCount: state.TotalCount - 1,
      }

    case Action.ActiveLeverageRules:
      return {
        ...state,
        Rules: {
          ...state.Rules,
          [action.data.Id]: state.Rules[action.data.Id].map((item: ILeverageRules) => {
            if (item.Id === action.data.ruleId) {
              return {
                ...item,
                IsActive: !item.IsActive,
              }
            }
            return item
          }),
        },
      }

    case Action.CloneLeverageRules:
      return {
        ...state,
        Rules: {
          ...state.Rules,
          [action.data.Id]: [action.data.rule, ...state.Rules[action.data.Id]],
        },
      }

    case Action.DefaultRules:
      return {
        ...state,
        Rules: state.defaultRules,
        Data: state.defaultData,
        TotalCount: state.defaultTotalCount,
      }

    case Action.ChangeLeverageRulesItemPriority: {
      const { result, params } = action.data
      const findRules = state.Rules[params.Id]
      const newRulesArray = Array.from(findRules)
      newRulesArray.splice(result.source.index, 1)
      newRulesArray.splice(result.destination.index, 0, findRules[result.source.index])

      return {
        ...state,
        Rules: {
          ...state.Rules,
          [params.Id]: newRulesArray,
        },
      }
    }

    case Action.AddLeverageRules:
      return {
        ...state,
        Rules: {
          ...state.Rules,
          [action.data.Id]: [action.data.rule, ...state.Rules[action.data.Id]],
        },
      }

    case Action.UpdateLeverageProfile:
      const newRules = {
        ...state.Rules,
        [action.data.Id]: state.Rules[action.data.Id].map((item: ILeverageRules) => {
          if (item.Id === action.data.Rules.Id) {
            return action.data.Rules
          }

          return item
        }),
      }

      return {
        ...state,
        Rules: newRules,
      }

    default:
      return state
  }
}

export function updateLeverageProfile(data: any) {
  return { type: Action.UpdateLeverageProfile, data }
}
export function addLeverageRules(data: any) {
  return { type: Action.AddLeverageRules, data }
}

export function addLeverageProfile(data: any) {
  return { type: Action.AddLeverageProfile, data }
}

export function deleteLeverageRules(data: any) {
  return { type: Action.DeleteLeverageRules, data }
}

export function activeLeverageRules(data: any) {
  return { type: Action.ActiveLeverageRules, data }
}

export function cloneLeverageRules(data: any) {
  return { type: Action.CloneLeverageRules, data }
}

export function changeLeverageRulesItemPriority(data: any) {
  return { type: Action.ChangeLeverageRulesItemPriority, data }
}

export function defaultRules() {
  return { type: Action.DefaultRules }
}

export default leverageProfileReducer
