import { Action } from '../actions/actions'
import { BinanceRuleEntity } from '../../entity/binance'

const INITIAL_STATE = {
  BinanceFuturesSwap: [],
  BinanceFuturesSwapPagination: [],
  noChanges: true,
  TotalCount: 0,
}

function BinanceReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case Action.BinanceFutures:
      return {
        ...state,
        BinanceFuturesSwapPagination: action.data.Data.map((item: any) => new BinanceRuleEntity(item)),
        BinanceFuturesSwap: action.data.Data.map((item: any) => new BinanceRuleEntity(item)),
        noChanges: true,
        TotalCount: action?.data?.TotalCount,
      }
    case Action.AddBinanceFuturesSwap:
      return {
        ...state,
        BinanceFuturesSwap: [...state.BinanceFuturesSwap, new BinanceRuleEntity(action.data.body)],
        BinanceFuturesSwapPagination: [...state.BinanceFuturesSwap, new BinanceRuleEntity(action.data.body)],
        noChanges: false,
        TotalCount: action?.data?.TotalCount,
      }

    case Action.GotBinanceSwapRuleItems: {
      if (action.data.Count === 'All') {
        return {
          ...state,
          BinanceFuturesSwapPagination: state.BinanceFuturesSwap,
          TotalCount: state.BinanceFuturesSwap.length,
        }
      } else {
        return {
          ...state,
          BinanceFuturesSwapPagination: state.BinanceFuturesSwap.slice((action.data.Page - 1) * action.data.Count, action.data.Page * action.data.Count),
          TotalCount: state.BinanceFuturesSwap.length,
        }
      }
    }
    case Action.DeleteBinanceFutures:
      return {
        ...state,
        BinanceFuturesSwap: [],
        BinanceFuturesSwapPagination: [],
        noChanges: false,
        TotalCount: action?.data?.TotalCount,
      }
    case Action.ModifyBinanceFutures:
      return {
        ...state,
        BinanceFuturesSwap: state.BinanceFuturesSwap.map((item: any) => {
          if (item.Id === action.data.body.Id) {
            return new BinanceRuleEntity(action.data.body)
          }
          return item
        }),
        BinanceFuturesSwapPagination: state.BinanceFuturesSwapPagination.map((item: any) => {
          if (item.Id === action.data.body.Id) {
            return new BinanceRuleEntity(action.data.body)
          }
          return item
        }),
        noChanges: false,
        TotalCount: action?.data?.TotalCount,
      }
    case Action.DeleteBinanceFuturesOne:
      return {
        ...state,
        BinanceFuturesSwap: state.BinanceFuturesSwap.filter((symbol: any) => symbol.Id !== action.data.body.Id),
        BinanceFuturesSwapPagination: state.BinanceFuturesSwapPagination.filter((symbol: any) => symbol.Id !== action.data.body.Id),
        noChanges: false,
        TotalCount: action?.data?.TotalCount,
      }
    case Action.ChangePriorityBinanceFutures: {
      const { result } = action.data
      const newSymbolArray = Array.from(state.BinanceFuturesSwap)
      newSymbolArray.splice(result.source.index, 1)
      newSymbolArray.splice(result.destination.index, 0, state.BinanceFuturesSwap[result.source.index])
      return {
        ...state,
        BinanceFuturesSwap: newSymbolArray,
        BinanceFuturesSwapPagination: newSymbolArray,
        noChanges: false,
      }
    }
    default:
      return state
  }
}

export function addBinanceFuturesSwap(data: any) {
  return { type: Action.AddBinanceFuturesSwap, data }
}

export function deleteBinanceFutures() {
  return { type: Action.DeleteBinanceFutures }
}

export function ModifyBinanceFutures(data: any) {
  return { type: Action.ModifyBinanceFutures, data }
}

export function DeleteBinanceFuturesOne(data: any) {
  return { type: Action.DeleteBinanceFuturesOne, data }
}

export default BinanceReducer
