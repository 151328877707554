import React from 'react'
import { isHedging } from '../../../entity/system-settings'
import TextInput from '../../inputs/TextInput'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { buildTableHeadersFromArray } from '../../../utils/table-utils'
import { AppTable } from '@t4b/core'
import { Limit, Market } from '../../../entity/platforms'
import { FormattedMessage } from 'react-intl'
import { buildMultiselectOptionsFromArray } from '../../../utils/multiselect-utils'
import NewSearchableSelectInput from '../../inputs/NewSearchableSelectInput'

export const buildOverridesHedgingBBookTable = (hedgingBBookState: any, hedgingBBookErrors: any, hedgingBBookTouched: any, setHedgingBBookTouched: any, setExecState: any, execState: any) => {
  const handlePlus = () => {
    const newItem = hedgingBBookState?.Thresholds.push(new isHedging())
    setExecState(Object.assign({}, execState, newItem))
  }

  const lpRows = hedgingBBookState?.Thresholds?.map((elem: any, index: number) => {
    const handleDelete = () => {
      const newState = [...hedgingBBookState?.Thresholds]
      newState.splice(index, 1)
      const newItem = (hedgingBBookState.Thresholds = newState)
      setExecState(Object.assign({}, execState, newItem))
    }

    const handleStateChange = (newState: any) => {
      const newStateArr = hedgingBBookState?.Thresholds
      newStateArr.splice(index, 1, newState)
      hedgingBBookState.Thresholds = newStateArr
      setExecState(Object.assign({}, execState))
    }

    return (
      <tr key={index}>
        <td>
          <TextInput className="m-0" state={hedgingBBookState?.Thresholds[index]} setState={handleStateChange} errors={hedgingBBookErrors} touched={hedgingBBookTouched} tooltipFlag={true} tooltip={'Mask'} setTouched={setHedgingBBookTouched} name="Mask" />
        </td>
        <td>
          <TextInput
            className="m-0"
            state={hedgingBBookState?.Thresholds[index]}
            setState={handleStateChange}
            errors={hedgingBBookErrors}
            touched={hedgingBBookTouched}
            setTouched={setHedgingBBookTouched}
            name="Threshold"
            flag={!!hedgingBBookErrors.Thresholds}
          />
        </td>
        <td className="h-100">
          <Button variant="link" className="t4b-text-gray p-0 mt-1 ml-1" onClick={handleDelete}>
            <FontAwesomeIcon icon={faTrashAlt} />
          </Button>
        </td>
      </tr>
    )
  })

  lpRows?.push(
    <tr key="plus-row" className="cursor-pointer" onClick={handlePlus}>
      <td colSpan={3}>
        <FontAwesomeIcon icon={faPlus} />
      </td>
    </tr>,
  )

  const header = buildTableHeadersFromArray(
    ['Symbol', 'Volume', 'Actions'].map((elem: any) => ({
      name: elem,
      show: true,
    })),
    'lp.hedged',
  )
  const hedgingBBook = <AppTable tableHeader={header} tableData={lpRows} scrollOptions={{ x: 'scroll', y: 'visible-hidden' }} />
  return hedgingBBook
}

//============== Market ========

const market = ['FOK', 'IOC', 'DAY', 'GTC', 'GTD']

export const buildOverridesMarketTable = (execState: any, setExecState: any) => {
  const handlePlus = () => {
    const newItem = execState.TifOverrides.Market.push(
      new Market({
        Symbol: '',
        TifFrom: { value: '', label: '' },
        TifTo: { value: '', label: '' },
      }),
    )
    setExecState(Object.assign({}, execState, newItem))
  }

  const lpRows = execState?.TifOverrides?.Market?.map((elem: any, index: number) => {
    const handleDelete = () => {
      const newState = [...execState.TifOverrides.Market]
      newState.splice(index, 1)
      const newItem = (execState.TifOverrides.Market = newState)
      setExecState(Object.assign({}, execState, newItem))
    }
    const handleStateChange = (newState: any) => {
      const newStateArr = execState.TifOverrides.Market
      newStateArr.splice(index, 1, newState)
      const newStateMarket = [...newStateArr].flat().map((el: any) => new Market(el))
      execState.TifOverrides.Market = newStateMarket
      setExecState(Object.assign({}, execState))
    }

    return (
      <tr key={index}>
        <td style={{ fontSize: '16px', width: '50%' }}>
          <TextInput className="m-0" state={execState.TifOverrides.Market[index]} setState={handleStateChange} name="Symbol" tooltipFlag={true} tooltip={'Mask'} />
        </td>
        <td style={{ fontSize: '16px', width: '25%' }}>
          <NewSearchableSelectInput placement="bottom" state={execState.TifOverrides.Market[index]} setState={handleStateChange} name="TifFrom" options={buildMultiselectOptionsFromArray(market)} className=" settings-block__field mb-3" isSearchable={false} />
        </td>
        <td style={{ fontSize: '16px', width: '25%' }}>
          <NewSearchableSelectInput placement="bottom" state={execState.TifOverrides.Market[index]} setState={handleStateChange} name="TifTo" options={buildMultiselectOptionsFromArray(market)} className="settings-block__field mb-3" isSearchable={false} />
        </td>
        <td className="h-100">
          <Button variant="link" className="t4b-text-gray p-0 mt-1" onClick={handleDelete}>
            <FontAwesomeIcon icon={faTrashAlt} />
          </Button>
        </td>
      </tr>
    )
  })

  lpRows?.push(
    <tr key="plus-row" className="cursor-pointer" onClick={handlePlus}>
      <td colSpan={4}>
        <FontAwesomeIcon icon={faPlus} />
      </td>
    </tr>,
  )

  const header = buildTableHeadersFromArray(
    ['Symbol', 'TifFrom', 'TifTo', 'Actions'].map((elem: string) => ({
      name: elem,
      show: true,
    })),
    'lp',
  )

  const marketTable = <AppTable tableHeader={header} tableData={lpRows} scrollOptions={{ x: 'scroll', y: 'visible-hidden' }} />
  return (
    <div className="mb-4" style={{ position: 'relative', zIndex: 3232323 }}>
      <div className="d-flex justify-content-center">
        <FormattedMessage id="LPR.Market" />
      </div>
      {marketTable}
    </div>
  )
}

const limit = ['ForceToMarket', 'ForceToGTD', 'GTCwithCancel', 'Reject']

// ========Limit========= //

export const buildOverridesLimitTable = (inputState: any, setExecState: any, execState: any) => {
  const handlePlus = () => {
    if (inputState.Type === 'TradeviewPrime' || inputState.Type === 'cTrader') {
      const GTCwithCancel = execState.TifOverrides.Limit.push(new Limit({ Symbol: '*', Mode: { value: 'GTCwithCancel', label: 'GTCwithCancel' } }))
      setExecState(Object.assign({}, execState, GTCwithCancel))
    } else {
      const FokExpirationTimeout = execState.TifOverrides.Limit.push(new Limit({ Symbol: '', Mode: { value: '', label: '' } }))
      setExecState(Object.assign({}, execState, FokExpirationTimeout))
    }
  }

  const lpRows = execState.TifOverrides.Limit?.map((elem: any, index: number) => {
    const handleDelete = () => {
      const newState = [...execState.TifOverrides.Limit]
      newState.splice(index, 1)
      const newItem = (execState.TifOverrides.Limit = newState)
      setExecState(Object.assign({}, execState, newItem))
    }

    const handleStateChange = (newState: any) => {
      const newStateArr = execState.TifOverrides.Limit
      newStateArr.splice(index, 1, newState)
      execState.TifOverrides.Limit = newStateArr
      setExecState(Object.assign({}, execState))
    }

    return (
      <tr key={index}>
        <td className="col-6">
          <TextInput className="m-0 w-300" state={execState.TifOverrides.Limit[index]} setState={handleStateChange} name="Symbol" tooltipFlag={true} tooltip={'Mask'} />
        </td>

        <td style={{ fontSize: '16px', textAlign: 'start', position: 'relative', zIndex: 3232323222 }} className="col-6">
          <NewSearchableSelectInput placement="bottom" state={execState.TifOverrides.Limit[index]} setState={handleStateChange} name="Mode" options={buildMultiselectOptionsFromArray(limit)} className="settings-block__field mb-3 w-300" isSearchable={false} />
        </td>
        <td className="h-100">
          <Button variant="link" className="t4b-text-gray p-0 mt-1 ml-1" onClick={handleDelete}>
            <FontAwesomeIcon icon={faTrashAlt} />
          </Button>
        </td>
      </tr>
    )
  })

  lpRows?.push(
    <tr key="plus-row" className="cursor-pointer" onClick={handlePlus}>
      <td colSpan={3}>
        <FontAwesomeIcon icon={faPlus} />
      </td>
    </tr>,
  )

  const header = buildTableHeadersFromArray(
    ['Symbol', 'Mode', 'Actions'].map((elem: any) => ({
      name: elem,
      show: true,
    })),
    'lp',
  )

  const limitTable = <AppTable tableHeader={header} tableData={lpRows} scrollOptions={{ x: 'scroll', y: 'visible-hidden' }} />
  return (
    <div style={{ position: 'relative', zIndex: 323232 }}>
      <div className="d-flex justify-content-center">
        <FormattedMessage id="LPR.Limit" />
      </div>

      {limitTable}
    </div>
  )
}
