import React, { FC, memo, useState } from 'react'
import { ReceiverChannelBlock } from './ReceiverChannelBlock'
import { Accordion, Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

export const ReceiverChannel: FC<any> = memo(({ data, name, id }) => {
  const [hidden, setHidden] = useState(true)

  return (
    <Accordion defaultActiveKey={hidden ? 'accordion' : undefined} style={{ width: '100%' }}>
      <Card className={`accordion-custom border-none`} style={{ overflow: 'visible' }}>
        <Card.Header className="d-flex justify-content-between p-0 accordion-custom__toggle border-none wordBreak" style={{ width: '100%' }} onClick={() => setHidden(prev => !prev)}>
          <Accordion.Toggle eventKey="accordion" style={{ padding: 15 }} className={`cursor-pointer d-flex align-items-center border-none font-500 flex-grow-1 text-wrap accordion-header-custom`} as="div">
            <div className="d-flex justify-content-between align-items-center" style={{ width: '100%' }}>
              <div style={{ width: '70%' }}>{name}</div>
              <FontAwesomeIcon icon={faAngleRight} className={hidden ? 'icon ml-auto' : 'ml-auto icon icon-rotate'} />
            </div>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="accordion">
          <Card.Body className="accordion-custom__collapse accordion-collapse d-flex">
            <div className="flex-column" style={{ width: '100%' }}>
              <ReceiverChannelBlock item={data} name={name} id={id} />
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  )
})
