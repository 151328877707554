import { Action } from '../actions/actions'

const quoteInitial = {
  quotes: new Map(),
  selection: new Set(),
  executionReport: null,
}

function tradingReducer(state = quoteInitial, action: any) {
  switch (action.type) {
    case Action.GotTradingQuotes: {
      const newState = new Map(state.quotes)
      newState.set(`${action.data.SourceType}_${action.data.SourceName}_${action.data.OrderBook.Symbol}`, action.data)
      return {
        ...state,
        quotes: newState,
      }
    }
    case Action.GotExecutionReport:
      return {
        ...state,
        executionReport: action.data,
      }
    default:
      return state
  }
}

export default tradingReducer
