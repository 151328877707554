import React, { useEffect, useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { FeedAgregationEntity, FeedPlatformEntity } from '../../entity/feeding'
import { addFeedPlatform, changeFeedAggregationPlat, changeFeedPlatform } from '../../redux/actions/execution/execution-actions'
import { changeFeedingLp, feedingConfigurationChanged } from '../../redux/actions/feeding-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import withGateway, { IGatewayProp } from '../../hocs/withGateway'
import { useFormValidation } from '../../hooks/useFormValidation'
import { buildControlsExtTwoPerLine, checkboxInput, sselectInput, textInput } from '../../utils/controls'
import * as yup from 'yup'
import { folderNameSchema } from '../../utils/schema-utils'
import { FeedExecPresetEntity } from '../../entity/Preset-entitu'
import { buildMultiselectOptionsFromArray, convertFeederOptions } from '../../utils/multiselect-utils'

interface IFeedAgregationRightbar extends IGatewayProp {
  data: any
}

const schema = {
  Name: folderNameSchema(),
  Connector: yup.object().shape({
    value: yup.string().required(),
    label: yup.string().required(),
  }),
  AggregatedFeeder: yup.object().shape({
    value: yup.number().required(),
    label: yup.string().required(),
  }),
  BackupFeedAggregationId: yup.object().shape({
    value: yup.number().required(),
    label: yup.string().required(),
  }),
  Profile: yup.object().shape({
    value: yup.string().required(),
    label: yup.string().required(),
  }),
}

const FeedPlatformRightbar: React.FC<IFeedAgregationRightbar> = ({ data: { type, item, preset }, gateway }) => {
  const dispatch = useDispatch()
  const { feedAgregations, Profile, lp, feedPlatforms } = useSelector((state: any) => state.feedingConfiguration)

  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(
    {
      ...item,
      Connector: { value: item.Connector, label: item.Connector },
      Profile: { value: item.Profile, label: item.Profile },
    },
    schema,
  )

  const Filtertable: any = {}
  const FiltertablePreset = Profile.filter((el: FeedExecPresetEntity) => !Filtertable[el.Name] && (Filtertable[el.Name] = 1)).map((item: any) => item.Name)

  useEffect(() => {
    const connector = gateway.Platforms.filter((item: any) => item.Type !== 'mtexec')
    if (feedAgregations && type === 'add') {
      setInputState({
        ...inputState,
        Connector: { value: connector[0]?.Name ?? '', label: connector[0]?.Name ?? '' },
        AggregatedFeeder: { value: feedAgregations[0]?.Id ?? '', label: convertFeederOptions(feedAgregations[0]?.Id, feedAgregations) },
        Profile: { value: preset === 'All' ? FiltertablePreset[0] : preset, label: preset === 'All' ? FiltertablePreset[0] : preset },
        BackupFeedAggregationId: { value: 0, label: 'None' },
      })
    }
  }, [feedAgregations]) // eslint-disable-line react-hooks/exhaustive-deps

  const alreadyExist = () => {
    if (type === 'modify' && feedPlatforms.find((platform: any) => platform.Name === inputState.Name && platform.Profile === inputState.Profile && platform.Id !== inputState.Id)) {
      errors.Name = true
      return 'platform.exists'
    }
    if ((type === 'add' || type === 'clone') && feedPlatforms.find((platform: any) => platform.Name === inputState.Name && platform.Profile === inputState.Profile)) {
      errors.Name = true
      return 'platform.exists'
    }
    return ''
  }

  const [msg, setMsg] = useState({
    Profile: '',
    AggregatedFeeder: '',
  })

  const handleSave = async () => {
    if (!isValid() || !FiltertablePreset.length || !feedAgregations.length) {
      errors.Profile = true
      touched.Profile = true
      errors.AggregatedFeeder = true
      touched.AggregatedFeeder = true

      setMsg((prev: any) => {
        if (!FiltertablePreset.length && !feedAgregations.length) {
          return {
            Profile: 'You need to create a profile to proceed',
            AggregatedFeeder: 'You need to create a feeding pool to proceed',
          }
        } else if (!feedAgregations.length) {
          return {
            ...prev,
            AggregatedFeeder: 'You need to create a feeding pool to proceed',
          }
        } else if (!FiltertablePreset.length) {
          return {
            ...prev,
            Profile: 'You need to create a profile to proceed',
          }
        }
      })

      return
    }

    const findProfile = Profile.find((item: any) => item.Name === inputState.Profile.value)

    const data = new FeedPlatformEntity({
      ...inputState,
      Enabled: findProfile?.Enabled,
      Connector: inputState.Connector.value,
      AggregatedFeeder: inputState.AggregatedFeeder.value,
      Profile: inputState.Profile.value,
      BackupFeedAggregationId: inputState.BackupFeedAggregationId.value,
    })

    if (type === 'add' || type === 'clone') {
      feedAgregations?.forEach((item: any) => {
        if (Number(data?.AggregatedFeeder) === Number(item.Id)) {
          const nameLp = item.Feeders
          dispatch(changeFeedAggregationPlat({ ...item, bunchProfiles: [...item.bunchProfiles, data.Profile], Profile: data.Profile }))
          lp?.forEach((item: any) => {
            if (nameLp.includes(item.Id)) {
              dispatch(changeFeedingLp({ ...item, bunchProfiles: [...item.bunchProfiles, data.Profile], flag: true }))
            }
          })
        }
      })
      dispatch(addFeedPlatform(data))
    } else {
      feedAgregations?.forEach((item: any) => {
        if (Number(data?.AggregatedFeeder) === Number(item.Id)) {
          const nameLp = item.Feeders
          dispatch(changeFeedAggregationPlat({ ...item, bunchProfiles: [...item.bunchProfiles, data.Profile], Profile: data.Profile }))
          lp?.forEach((item: any) => {
            if (nameLp.includes(item.Id)) {
              dispatch(changeFeedingLp({ ...item, bunchProfiles: [...item.bunchProfiles, data.Profile], flag: true }))
            }
          })
        }
      })
      dispatch(changeFeedPlatform(data))
    }
    dispatch(feedingConfigurationChanged())
    dispatch(hideRightBar())
  }

  const notCover: string[] = []
  gateway.Platforms.forEach((platform: any) => {
    if (platform.Type !== 'mtexec') {
      notCover.push(platform.Name)
    }
  })

  return (
    <Card>
      <Card.Header className="color-dark font-500">
        <FormattedMessage id={`feed-platform.${type}`} />
      </Card.Header>
      <Card.Body>
        {buildControlsExtTwoPerLine(
          [
            textInput('Name').errorMessage(alreadyExist()),
            sselectInput('Connector', buildMultiselectOptionsFromArray(notCover || []), false),
            sselectInput(
              'AggregatedFeeder',
              feedAgregations.map((aggregation: FeedAgregationEntity) => {
                return {
                  value: aggregation.Id,
                  label: convertFeederOptions(aggregation.Id, feedAgregations),
                }
              }),
              !item.Enabled && type !== 'clone',
              false,
              inputState,
              setInputState,
            ).errorMessage(msg?.AggregatedFeeder),
            sselectInput(
              'BackupFeedAggregationId',
              [0, ...feedAgregations.map((aggregation: FeedAgregationEntity) => aggregation.Id)].map((id: any) => {
                return {
                  value: id,
                  label: convertFeederOptions(id, feedAgregations),
                }
              }),
              false,
              !item.Enabled && type !== 'clone',
              inputState,
              setInputState,
            ).optionZindex(false),
            sselectInput('Profile', buildMultiselectOptionsFromArray(FiltertablePreset)).optionZindex(false).errorMessage(msg?.Profile),
            checkboxInput('FilterEnabled', false, false),
            checkboxInput('LimitationEnabled', false, false),
            checkboxInput('FilterInvalidQuotesEnabled', false, false),
          ],
          inputState,
          setInputState,
          'feed-platforms',
          touched,
          setTouched,
          errors,
        )}
        <Button className="t4b-bg-dark-button mt-3" onClick={handleSave}>
          <FormattedMessage id="save" tagName="span" />
        </Button>
      </Card.Body>
    </Card>
  )
}
export default withGateway(FeedPlatformRightbar)
