import { AggregationPoolType } from './configuration'

export class BackupActiveEntity {
  public Accounts: any
  public Symbol: string
  public Direction: string
  public Volume: number
  public AverageOpenPrice: number
  public CurrentPrice: number
  public BackupPool: string
  public BasePool: string

  constructor(item?: any) {
    if (item) {
      this.Accounts = item.Accounts
      this.Symbol = item.Symbol
      this.Direction = item.Direction
      this.Volume = item.Volume
      this.AverageOpenPrice = item.AverageOpenPrice
      this.CurrentPrice = item.CurrentPrice
      this.BackupPool = item.BackupPool
      this.BasePool = item.BasePool
    } else {
      this.Accounts = []
      this.Symbol = ''
      this.Direction = ''
      this.Volume = 0
      this.AverageOpenPrice = 0
      this.CurrentPrice = 0
      this.BackupPool = ''
      this.BasePool = ''
    }
  }
}

export class BackupHistoryEntity {
  public BasePool: string
  public Backup: string
  public Direction: string
  public Volume: number
  public AverageOpenPrice: number
  public ReturnPrice: number
  public ReturnTime: Date

  constructor(item?: any) {
    if (item) {
      this.BasePool = item.BasePool
      this.Backup = item.Backup
      this.Direction = item.Direction
      this.Volume = item.Volume
      this.AverageOpenPrice = item.AverageOpenPrice
      this.ReturnPrice = item.ReturnPrice
      this.ReturnTime = item.ReturnTime
    } else {
      this.BasePool = ''
      this.Backup = ''
      this.Direction = ''
      this.Volume = 0
      this.AverageOpenPrice = 0
      this.ReturnPrice = 0
      this.ReturnTime = new Date()
    }
  }
}

export class BackupRuleEntity {
  public Id: number
  public Name: string
  public ExecutorsPercents: Array<{ LpName: string; Percent: number }>
  public Type: AggregationPoolType
  public Backup: string
  public BookType: string
  public Enabled: boolean
  public Lps: Array<string>

  constructor(item?: any) {
    if (item) {
      this.Id = item.Id
      this.Lps = item.Lps
      this.Name = item.Name
      this.ExecutorsPercents = item.ExecutorsPercents
      this.Type = item.Type
      this.Backup = item.Backup
      this.BookType = item.BookType
      this.Enabled = item.Enabled
    } else {
      this.Id = 0
      this.Lps = []
      this.Name = ''
      this.ExecutorsPercents = []
      this.Type = AggregationPoolType.SingleBook
      this.Backup = ''
      this.BookType = ''
      this.Enabled = true
    }
  }
}
