import React from 'react'
import { Form } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import ColorPicker, { webColors } from '../color-picker'
import classNames from 'classnames'

interface IColorPickerInputProps {
  className?: string
  state: any
  touched?: any
  errors?: any
  errorText?: string
  name: string
  label?: string
  placeholder?: string
  isDisabled?: boolean
  style?: any

  setState(state: any): any

  setTouched?(touched: any): any
}

const ColorPickerInput: React.FC<IColorPickerInputProps> = ({ state, setState, name, label, touched, setTouched, errors, className, isDisabled, style }) => {
  const handleChange = (colors: any) => {
    setState({
      ...state,
      [name]: colors,
    })
  }

  const handleBlur = () => {
    setTouched &&
      setTouched({
        ...touched,
        [name]: true,
      })
  }

  return (
    <div className={classNames('form-group', className)} style={style}>
      {label ? (
        <>
          <Form.Label>
            <FormattedMessage id={label} tagName="span" />
          </Form.Label>
          <span>:</span>
        </>
      ) : null}

      <ColorPicker
        value={state[name]}
        onChange={handleChange}
        onBlur={handleBlur}
        options={webColors}
        placeholder={<FormattedMessage id="color-picker.select" />}
        disabled={isDisabled}
      />
      {touched && touched[name] && errors && errors[name] ? (
        <div className="d-block invalid-feedback">
          <FormattedMessage id="field-validation-error" tagName="span" />
        </div>
      ) : null}
    </div>
  )
}

export default ColorPickerInput
