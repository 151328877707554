import { throwErrorMessage } from '../../utils/errors-utils'
import Cookies from 'universal-cookie'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import axios from 'axios'

const cookies = new Cookies()

export function saveJwt(jwt: string) {
  cookies.set('jwt', jwt, { path: '/', maxAge: 102 * 6 })
}

export function tokenRefresh(refresh: string) {
  cookies.set('refresh', refresh, { path: '/', maxAge: 86400 })
}
export function removeTokenRefresh() {
  cookies.remove('refresh')
}

export function getJwt(): string | null {
  return cookies.get('jwt')
}

export function removeJwt() {
  cookies.remove('jwt')
}

export function saveUser(user: any) {
  localStorage.setItem(
    'user',
    JSON.stringify({
      username: user.username,
      rights: user.roles,
    }),
  )
}

export const saveKeyChatBot = (key: any) => {
  localStorage.setItem(
    'chatBot',
    JSON.stringify({
      AIAssistantId: key.AIAssistantId,
      AIAssistantApiKey: key.AIAssistantApiKey,
    }),
  )
}
export const getKeyChatBot = () => {
  const key: any = localStorage.getItem('chatBot')
  return JSON.parse(key) || {}
}
export const removeKeyChatBot = () => {
  localStorage.removeItem('chatBot')
}

export function getRights(): UserRoles[] {
  const user = localStorage.getItem('user')
  if (user === null) {
    return []
  }
  return JSON.parse(user).rights || []
}

function getUsername(): string {
  const user = localStorage.getItem('user')
  if (user === null) {
    return 'Unknown'
  }
  return JSON.parse(user).username || ''
}

export function removeUser() {
  localStorage.removeItem('user')
}

export enum UserRoles {
  Summary = 'summary',
  Exposure = 'exposure',
  Positions = 'positions',
  TradingHistory = 'trading-history',
  NewSwitch = 'new-switch',
  ActiveSwitches = 'active-switches',
  SwitchHistory = 'risk-tool-history',
  AutoSwitch = 'auto-switch',
  ActiveRules = 'active-rules',
  VolumeTransfer = 'volume-transfer',
  VolumeTransferHistory = 'volume-transfer-history',
  Execution = 'execution-configuration',
  Feeding = 'feeding-configuration',
  CeActive = 'ce-active',
  CeFailed = 'ce-failed',
  CeRules = 'ce-rules',
  CeHistory = 'ce-history',
  ConsolidationRules = 'consolidation-rules',
  ConsolidationHistory = 'consolidation-history',
  BackupsActive = 'backups-active',
  BackupsRules = 'backups-rules',
  DropCopySettings = 'drop-copy-settings',
  DropCopySessions = 'drop-copy-sessions',
  Common = 'common',
  Platforms = 'platforms',
  Lps = 'lps',
  GlobalSymbolMap = 'symbol-map',
  EditUsers = 'edit-users',
  Telemetry = 'telemetry',
  Logs = 'logs',
  Quotes = 'quotes',
  Trading = 'trading',
  Spreads = 'spreads',
  TpApi = 'tp-api',
  TpApiManager = 'tp-api-manager',
  Reports = 'reports',

  notificationRules = 'notification-rules',
  notificationReceivers = 'notification-receivers',
  notificationHistory = 'notification-history',
}

export enum UserType {
  Custom = 'custom',
  Manager = 'manager',
  Dealer = 'dealer',
  Admin = 'admin',
}

const managerRoles = [UserRoles.Summary, UserRoles.Exposure, UserRoles.Positions, UserRoles.TradingHistory]
const dealerRoles = [
  ...managerRoles,
  UserRoles.Execution,
  UserRoles.Feeding,
  UserRoles.NewSwitch,
  UserRoles.ActiveSwitches,
  UserRoles.SwitchHistory,
  UserRoles.AutoSwitch,
  UserRoles.ActiveRules,
  UserRoles.VolumeTransfer,
  UserRoles.ConsolidationRules,
  UserRoles.ConsolidationHistory,
]

const adminRoles: UserRoles[] = Object.values(UserRoles)

export const userTypeRoles: { [key: string]: UserRoles[] } = {
  custom: [],
  manager: managerRoles,
  dealer: dealerRoles,
  admin: adminRoles,
}

const rights: { [key: string]: UserRoles } = {
  '/summary': UserRoles.Summary,
  '/exposure': UserRoles.Exposure,
  '/positions': UserRoles.Positions,
  '/trading-history': UserRoles.TradingHistory,
  '/risk-tool/new-switch': UserRoles.NewSwitch,
  '/risk-tool/active-switches': UserRoles.ActiveSwitches,
  '/risk-tool/history': UserRoles.SwitchHistory,
  '/risk-tool/auto-switch': UserRoles.AutoSwitch,
  '/risk-tool/active-rules': UserRoles.ActiveRules,
  '/volume-transfer/transfer': UserRoles.VolumeTransfer,
  '/volume-transfer/history': UserRoles.VolumeTransferHistory,
  '/execution-configuration': UserRoles.Execution,
  '/execution-configuration/execution-profile': UserRoles.Execution,
  '/execution-configuration/processing-rules': UserRoles.Execution,
  '/execution-configuration/lps': UserRoles.Execution,
  '/feeding-configuration': UserRoles.Feeding,
  '/feeding-configuration/platform': UserRoles.Feeding,
  '/continuous-execution/active': UserRoles.CeActive,
  '/continuous-execution/failed': UserRoles.CeFailed,
  '/continuous-execution/rules': UserRoles.CeRules,
  '/continuous-execution/history': UserRoles.CeHistory,
  '/consolidation/rules': UserRoles.ConsolidationRules,
  '/consolidation/history': UserRoles.ConsolidationHistory,
  '/backups/active': UserRoles.BackupsActive,
  '/backups/rules': UserRoles.BackupsRules,
  '/drop-copy/settings': UserRoles.DropCopySettings,
  '/drop-copy/sessions': UserRoles.DropCopySessions,
  '/system-settings/common': UserRoles.Common,
  '/system-settings/platforms': UserRoles.Platforms,
  '/system-settings/platforms/sessions': UserRoles.Platforms,
  '/system-settings/platforms/accounts': UserRoles.Platforms,
  '/system-settings/platforms/margin-info': UserRoles.Platforms,
  '/system-settings/platforms/leverage-profile': UserRoles.Platforms,
  '/system-settings/platforms/swaps': UserRoles.Platforms,
  '/system-settings/platforms/symbols': UserRoles.Platforms,
  '/system-settings/platforms/symbol-map': UserRoles.Platforms,
  '/system-settings/platforms/platform_mt4/sessions': UserRoles.Platforms,
  '/system-settings/platforms/platform_mt4/symbol-map': UserRoles.Platforms,
  '/system-settings/platforms/platform_mt5/symbol-map': UserRoles.Platforms,
  '/system-settings/platforms/extapi/sessions': UserRoles.Platforms,
  '/system-settings/platforms/extapi/symbols': UserRoles.Platforms,
  '/system-settings/platforms/extapi/symbol-map': UserRoles.Platforms,
  '/system-settings/platforms/mtexec/sessions': UserRoles.Platforms,
  '/system-settings/platforms/mtexec/symbol-map': UserRoles.Platforms,
  '/system-settings/lps/hedged-bbook-volumes': UserRoles.Lps,
  '/system-settings/lps/SyntheticLP': UserRoles.Lps,
  '/system-settings/lps/SyntheticIndexLP': UserRoles.Lps,
  '/system-settings/lps/volume-converter-volumes': UserRoles.Lps,
  '/system-settings/lps/volume-converter-volumes-history': UserRoles.Lps,
  '/system-settings/lps/volume-converter-volumes-cumulative': UserRoles.Lps,
  '/system-settings/lps/hedged-synthetic-volume': UserRoles.Lps,
  '/system-settings/lps/hedged-synthetic-ce': UserRoles.Lps,
  '/system-settings/lps/hedged-synthetic-history': UserRoles.Lps,
  '/system-settings/lps/hedged-bbook-history': UserRoles.Lps,
  '/system-settings/lps': UserRoles.Lps,
  '/system-settings/lps/symbol-map': UserRoles.Lps,
  '/system-settings/lps/binance-futures-swap': UserRoles.Lps,
  '/system-settings/lps/binance-symbol-map': UserRoles.Lps,
  '/system-settings/lps/binance-coin-symbol-map': UserRoles.Lps,
  '/system-settings/lps/binance-futures-coin-swap': UserRoles.Lps,
  '/system-settings/global-symbols': UserRoles.GlobalSymbolMap,
  '/system-settings/edit-users': UserRoles.EditUsers,
  '/monitoring/telemetry': UserRoles.Telemetry,
  '/monitoring/logs': UserRoles.Logs,
  '/monitoring/notification-history': UserRoles.notificationHistory, // Rule notification
  '/monitoring/notification-receiver-settings': UserRoles.notificationReceivers, // Rule notification
  '/monitoring/notification-rules': UserRoles.notificationRules, // Rule notification
  '/lp-performance/quotes': UserRoles.Quotes,
  '/lp-performance/trading': UserRoles.Trading,
  '/lp-performance/trading-history': UserRoles.Trading,
  '/lp-performance/spreads': UserRoles.Spreads,
  '/reports': UserRoles.Reports,
}

export class User {
  public username: string
  public rights: string[]

  constructor() {
    this.username = getUsername()
    this.rights = getRights()
  }

  hasAccess(path: string): boolean {
    const isGroupedPath = (path: string) => path.includes(':')
    const requiredRight: any = rights[path]

    if (isGroupedPath(path)) {
      return true
    }
    if (this.rights.includes(requiredRight)) {
      return true
    }
    return false
  }

  genLink(path: string): string {
    const link = Object.keys(rights)
      .filter((right: string) => right.startsWith(path))
      .find((url: string) => this.hasAccess(url))
    return link || '/not-enough-privilege'
  }
}

export function fetchSignIn(username: string, password: string, onSuccess: any) {
  fetch('/api/auth/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify({
      username,
      password,
    }),
  })
    .then((response: Response) => {
      if (response.status === 401) {
        return new Promise((resolve, reject) => reject(new Error('auth.bad')))
      }
      if (response.status === 405) {
        return new Promise((resolve, reject) => reject(new Error('auth.try-later')))
      }
      if (response.ok) {
        return response.json()
      }
      return new Promise((resolve, reject) => reject(new Error('auth.error')))
    })
    .then((credentials: any) => {
      saveJwt(credentials.jwt)
      saveUser(credentials)
      saveKeyChatBot(credentials)
      tokenRefresh(credentials.refresh)
      onSuccess()
    })
    .catch((error: Error) => throwErrorMessage(error.message))
}

const refreshAuthLogic = (failedRequest: any) => {
  return axios
    .post('/api/auth/refresh', {
      token: cookies.get('refresh'),
    })
    .then(credentials => {
      saveJwt(credentials.data.jwt)
      tokenRefresh(credentials.data.refresh)
      saveUser(credentials.data)
      failedRequest.response.config.headers['Authorization'] = 'Bearer ' + credentials.data.jwt
      return Promise.resolve()
    })
}

createAuthRefreshInterceptor(axios, refreshAuthLogic, {
  pauseInstanceWhileRefreshing: true,
})
