import React, { useEffect, useRef } from 'react'
import { Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { FeedAggTypes, FeedAgregationEntity, FeedAgregationExtEntity } from '../../entity/feeding'
import { addFeedAggregation, changeFeedAggregation } from '../../redux/actions/execution/execution-actions'
import { changeFeedingLp, changeFeedingLpNoChanged, feedingConfigurationChanged } from '../../redux/actions/feeding-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import MultiSelectInput from '../inputs/MultiSelectInput'
import TextInput from '../inputs/TextInput'
import { useFormValidation } from '../../hooks/useFormValidation'
import { AppAccordion } from '@t4b/core/lib'
import * as yup from 'yup'
import { folderNameSchema } from '../../utils/schema-utils'
import { IRightbar } from './rightbar-types'
import { FeedExecPresetEntity } from '../../entity/Preset-entitu'
import NewSearchableSelectInput from '../inputs/NewSearchableSelectInput'
import { buildMultiselectOptionsFromArray } from '../../utils/multiselect-utils'

const schema = {
  Name: folderNameSchema(),
  Type: yup.string().required(),
  FeedersNames: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required(),
        label: yup.string().required(),
      }),
    )
    .required(),
}

const FeedAggregationRightbar: React.FC<IRightbar> = ({ data: { type, item } }) => {
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation({ ...item, Type: { value: item.Type, label: item.Type } }, schema)
  const dispatch = useDispatch()
  const { feedAgregations, lp, Profile } = useSelector((state: any) => state.feedingConfiguration)
  const ref = useRef<any>(null)

  const Filtertable: any = {}
  const FiltertablePreset = Profile.filter((el: FeedExecPresetEntity) => !Filtertable[el.Name] && (Filtertable[el.Name] = 1)).map((item: any) => item.Name)

  useEffect(() => {
    if (!inputState.Profile && type === 'add') {
      setInputState((prev: any) => {
        return {
          ...prev,
          Profile: FiltertablePreset[0] ?? '',
        }
      })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (newState: any) => {
    const updatedState = {
      ...newState,
      Feeders: newState.FeedersNames?.map((feederName: any) => feederName.value) ?? [],
    }
    setInputState(updatedState)
  }

  const handleSave = () => {
    if (!isValid()) {
      if (ref.current) {
        ref.current.open()
      }
      return
    }

    const data = new FeedAgregationEntity({ ...inputState, Type: inputState.Type.value })

    if (type === 'clone') {
      dispatch(addFeedAggregation(data))
    } else if (type === 'add') {
      lp.forEach((el: any) => {
        if (inputState.Feeders.includes(el.Id)) {
          el.flag = true
          const data = { ...el, bunchProfiles: el.bunchProfiles.filter((el: any) => el !== item.Profile) }
          dispatch(changeFeedingLp({ ...data, bunchProfiles: [...data.bunchProfiles, inputState.Profile], flag: true }))
        }
      })
      dispatch(addFeedAggregation(new FeedAgregationEntity({ ...inputState, Type: inputState.Type.value, Enabled: true, bunchProfiles: [] })))
    } else {
      lp.forEach((el: any) => {
        el.flag = true
        const data = { ...el, bunchProfiles: el.bunchProfiles.filter((el: any) => el !== item.Profile) }
        dispatch(changeFeedingLpNoChanged({ ...data, bunchProfiles: [...data.bunchProfiles, inputState.Profile], flag: true }))
      })

      dispatch(changeFeedAggregation(data))
    }
    dispatch(feedingConfigurationChanged())
    dispatch(hideRightBar())
  }

  const alreadyExist = () => {
    if ((type === 'add' || type === 'clone') && feedAgregations.find((feed: FeedAgregationExtEntity) => feed.Name === inputState.Name)) {
      errors.Name = true
      return 'feed-already-exists'
    }
    return ''
  }
  const content = (
    <>
      <div className="row">
        <TextInput state={inputState} setState={setInputState} touched={touched} setTouched={setTouched} errors={errors} name="Name" label="feed-agregation.Name" className="col-6" errorText={alreadyExist()} />
        <NewSearchableSelectInput state={inputState} setState={setInputState} name="Type" options={buildMultiselectOptionsFromArray(Object.values(FeedAggTypes))} label="feed-agregation.Type" className="col-6" isSearchable={false} />
        <MultiSelectInput
          state={inputState}
          setState={handleChange}
          touched={touched}
          setTouched={setTouched}
          errors={errors}
          name="FeedersNames"
          label="feed-agregation.Feeders"
          options={lp.map((lpItem: any) => ({
            value: lpItem.Id,
            label: lpItem.Name,
          }))}
          className="col-6"
        />
      </div>
    </>
  )

  return (
    <>
      <AppAccordion
        item={{
          title: <FormattedMessage id={`feed-aggregation.${type}`} />,
          item: content,
        }}
        ref={ref}
        isHidden={false}
      />

      <Button className="t4b-bg-dark-button my-3 ml-20" onClick={handleSave}>
        <FormattedMessage id="save" />
      </Button>
    </>
  )
}
export default FeedAggregationRightbar
