import React, { useState } from 'react';


function ColorPickerOption({ color, onClick, disabled}) {

  const [hovered, setHovered] = useState(false);

  const handleClick = (e) => {
    if (disabled) {
      return;
    }
    onClick(color)
  }

  const styles = {
    backgroundColor: color.color,
    transform: hovered && !disabled ? 'scale(1.25)' : 'none',
    opacity: disabled ? 0.65 : 1,
  };

  return (
    <div
      style={styles}
      className='color-picker_option'
      onClick={handleClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    />
  )
}

export default ColorPickerOption;