import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { hideModal } from '../../redux/actions/modal-actions'
import { RootState } from '../../redux/reducers/rootReducer'
import { Link } from 'react-router-dom'
import { feedingConfigurationInitial } from '../../redux/actions/feeding-actions'
import { executionConfigurationInitial } from '../../redux/actions/execution/execution-actions'


const ModalFeed: React.FC = () => {
  const dispatch = useDispatch()
  const { modalData } = useSelector((state: RootState) => state.modals)
  const handleYes = () => {
    if (modalData.onYes) {
      modalData.onYes()
      dispatch(feedingConfigurationInitial())
      dispatch(executionConfigurationInitial())
    }
    dispatch(hideModal())
  }

  const handleNo = () => {
    dispatch(hideModal())
  }

  const headerText = typeof modalData.headerText === 'function' ? modalData.headerText() : <FormattedMessage id={modalData.headerText} />
  const bodyText = typeof modalData.bodyText === 'function' ? modalData.bodyText() : <FormattedMessage id={modalData.bodyText} />

  return (
    <Modal show={true} onHide={handleNo}>
      <Modal.Header closeButton={true} className="color-dark font-500">
        {headerText}
      </Modal.Header>
      <Modal.Body>{bodyText}</Modal.Body>
      <Modal.Footer>
        <Button onClick={handleYes} as={Link} to={modalData.onYes()}>
          <FormattedMessage id="yes" />
        </Button>
        <Button onClick={handleNo}>
          <FormattedMessage id="no" />
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default React.memo(ModalFeed)
