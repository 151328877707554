import { Action } from '../actions/actions'

class RightBarState {
  public rightbarHidden: boolean
  public name: string
  public data: any

  constructor(rightbarHidden: boolean = true, name: string = '', data: any = {}) {
    this.rightbarHidden = rightbarHidden
    this.name = name
    this.data = data
  }
}

function rightbarReducer(state = new RightBarState(), action: any) {
  switch (action.type) {
    case Action.ShowRightBar:
      return {
        ...state,
        rightbarHidden: false,
        name: action.payload.name,
        data: action.payload.data,
      }
    case Action.HideRightBar:
      return new RightBarState()
  }
  return state
}

export default rightbarReducer
