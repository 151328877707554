import React, { FC, memo, useMemo, useState } from 'react'
import { IRightbar } from '../rightbar-types'
import { AppAccordion } from '@t4b/core'
import { FormattedMessage } from 'react-intl'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import Channel from './Channel'
import { addNotificationChannel, NotificationReceiver } from '../../../redux/reducers/notificationReducer'
import { useDispatch, useSelector } from 'react-redux'
import { hideRightBar } from '../../../redux/actions/rightbar-actions'
import { NotificationChannelFetch, updateNotificationChannelFetch } from '../../monitoring/NotificationPages/NotificationReceiverSettings/actions'

const NotificationChannelsRightbar: FC<IRightbar> = ({ data: { type, Gateway } }) => {
  const dispatch = useDispatch()
  const { channel, receiver, receiverTotalCount } = useSelector((state: any) => state.NotificationReducer)
  const [valid, setValid] = useState({ Name: false, Receivers: false, isValid: () => true })

  const handleSave = async () => {
    if (valid.Name || valid.Receivers) {
      if (!valid.isValid()) return
    }

    const data = channel.filter((item: any) => {
      if (item.Id < 0) {
        return item
      }
      if (item.hasOwnProperty('Deleted')) {
        return item
      }
    })

    const body = data.map((item: any) => {
      return {
        ...item,
        Receivers: item.Receivers?.map((item: any) => {
          return {
            Id: item.value ?? item.Id,
            Name: item.label ?? item.Name,
          }
        }),
      }
    })

    await updateNotificationChannelFetch({ Gateway, body })
    await NotificationChannelFetch({ Gateway, dispatch })
    dispatch(
      NotificationReceiver({
        Data: receiver,
        TotalCount: receiverTotalCount,
      }),
    )
    dispatch(hideRightBar())
  }

  const handelAdd = () => {
    dispatch(addNotificationChannel({ Receivers: [], Name: '', Id: -Math.floor(Math.random() * (10000 - 1 + 1)) + 1 }))
  }

  const channelBody = useMemo(
    () =>
      channel?.map((item: any, index: number) => {
        if (!item.Deleted) {
          return <Channel key={item.Id} data={item} setValid={setValid} index={index} />
        }
      }),
    [channel],
  )

  const body = (
    <div>
      <div className="d-flex justify-content-end ">
        <div className="cursor-pointer color-checked-hov" onClick={handelAdd}>
          <span className="color-checked">Add group</span>
          <FontAwesomeIcon icon={faPlus} className="ml-2 cursor-pointer" />
        </div>
      </div>
      {channelBody}
    </div>
  )

  return (
    <>
      <AppAccordion
        item={{
          title: <FormattedMessage id={`Channel.${type}`} />,
          item: body,
        }}
        isHidden={false}
      />

      <Button className="t4b-bg-dark-button mt-3 ml-20" onClick={handleSave}>
        <FormattedMessage id="save" tagName="span" />
      </Button>
    </>
  )
}

export default memo(NotificationChannelsRightbar)
