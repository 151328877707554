import { ITableColumn } from '../../utils/table-types'
import { IRightbar } from './rightbar-types'
import { useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { fetchGet, processError } from '../../utils/fetch-utils'
import { Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { AppTable } from '@t4b/core'
import { asTime, buildTableData, buildTableHeadersFromArray } from '../../utils/table-utils'

const tableHeader: ITableColumn[] = [
  { name: 'Id', show: true },
  { name: 'ExecType', show: true },
  { name: 'OrderStatus', show: true },
  { name: 'LastQuantity', show: true },
  { name: 'LastPrice', show: true },
  { name: 'CumQuantity', show: true },
  { name: 'LeavesQuantity', show: true },
  { name: 'AveragePrice', show: true },
  { name: 'TransactTime', format: asTime, show: true },
  { name: 'Text', show: true },
]

function transformItem(item: any) {
  return {
    orderId: item.Id,
  }
}

function TradingHistoryRightbar({ data: { item, gatewayName } }: IRightbar) {
  const dispatch = useDispatch()
  const [data, setData] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedData = await fetchGet('/api/trading/executionReports', gatewayName, transformItem(item))
        setData(fetchedData)
      } catch (e) {
        processError(e, dispatch)
      }
    }

    fetchData()
  }, [dispatch, item]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card>
      <Card.Header className="color-dark font-500">
        <FormattedMessage id="lp-performance.trading-history.rightbar" />
      </Card.Header>
      <Card.Body>
        <AppTable tableHeader={buildTableHeadersFromArray(tableHeader, 'lp-performance.trading-history')} tableData={buildTableData(data, tableHeader, null)} />
      </Card.Body>
    </Card>
  )
}

export default TradingHistoryRightbar
