import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { NavDropdown } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { Action } from '../redux/actions/actions'
import { removeJwt, removeUser, removeTokenRefresh, User, removeKeyChatBot } from '../redux/actions/authentication'
import { useDispatch } from 'react-redux'

const NavUser: React.FC<any> = () => {
  const dispatch = useDispatch()
  const user = new User()

  const onSignOut = () => {
    dispatch({ type: Action.FlushGateways })
    dispatch({ type: Action.Unauthorized })
    removeJwt()
    removeUser()
    removeKeyChatBot()
    removeTokenRefresh()
    localStorage.removeItem('ExecProfile')
    localStorage.removeItem('FeedProfile')
    localStorage.clear()
    sessionStorage.clear()
    window.location.reload()
  }
  const title = (
    <span className="color-white">
      <span className="mr-1 font-500">{user.username}</span>
      <FontAwesomeIcon icon={faUserCircle} size="lg" />
    </span>
  )
  return (
    <NavDropdown title={title} id="nav-dropdown" className="nav-user d-flex align-items-center">
      <NavDropdown.Item onClick={onSignOut}>
        <FormattedMessage id="sign-out" />
      </NavDropdown.Item>
    </NavDropdown>
  )
}

export default React.memo(NavUser)
