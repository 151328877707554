import React, { useEffect, useRef, useState } from 'react'
import { Alert, Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import { AutoSwitchEntity, convert, convertObj, SwitchTriggerType } from '../../entity/risk-tool-entity'
import { useFormValidation } from '../../hooks/useFormValidation'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { fetchAutoSwitchUpdate } from '../../redux/actions/risk-tool-actions'
import { blankInput, buildControlsExtTwoPerLine, buildPoolsInputSwitch, checkboxInput, sselectInput, textInput } from '../../utils/controls'
import { buildMultiselectOptionsFromArray, buildSelectOption, isTrimString } from '../../utils/multiselect-utils'
import { AppAccordion } from '@t4b/core/lib'
import { IRightbar } from './rightbar-types'
import { isMixedPool, isSwitchUnsupportedPoolType, typeOfPool } from '../../utils/aggregation-pool'

const schema = {
  RuleName: yup.string().required(),
  Groups: yup.string().required(),
  Accounts: yup.string().required(),
  NewProcessor: yup.object().shape({
    value: yup.string().required(),
    label: yup.string().required(),
  }),
  SwitchTriggerValue: yup.number().required(),
  ReturnTriggerValue: yup.number().required(),
  OriginalPool: yup.object().shape({
    value: yup.string().required(),
    label: yup.string().required(),
  }),
  SwitchToPool: yup.object().shape({
    value: yup.string().required(),
    label: yup.string().required(),
  }),
}

enum SwitchTriggerDirection {
  Greater = 'Greater',
  Smaller = 'Smaller',
}

const AutoSwitchRightbar: React.FC<IRightbar> = ({ data: { type, item, params } }) => {
  const [settings, setSettings] = useState({
    Gateway: '',
    Symbols: [],
  })

  const dispatch = useDispatch()
  const [poolToProcessors, setPoolToProcessors] = useState(new Map())
  const { gateway } = useSelector((state: any) => state.gateways)
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(
    new AutoSwitchEntity({
      ...item,
      ReturnTriggerType: convertObj(item.ReturnTriggerType ?? SwitchTriggerType.Currency),
      SwitchTriggerType: convertObj(item.SwitchTriggerType ?? SwitchTriggerType.Currency),
    }),
    schema,
  )
  const { data } = useSelector((state: any) => state.riskTool.autoSwitch)
  const mainRef = useRef<any>(null)
  const switchRef = useRef<any>(null)
  const returnRef = useRef<any>(null)

  const findNameNewProcessor = gateway.Processors.find((item: any) => item.Name === inputState?.NewProcessor?.value)?.Name

  useEffect(() => {
    if (gateway.Name) {
      const poolToProc = new Map()
      for (const pair of gateway?.Processors) {
        if (poolToProc.has(pair.Pool)) {
          poolToProc.set(pair.Pool, [...poolToProc.get(pair.Pool), pair.Name])
        } else {
          poolToProc.set(pair.Pool, [pair.Name])
        }
      }

      const activePool = gateway.Pools[0] ?? null
      let targetPool = null
      if (activePool) {
        if (activePool.Type === 'B-book') {
          targetPool = gateway.Pools.find((pool: any) => pool.Type !== 'B-book')
        } else {
          targetPool = gateway.Pools.find((pool: any) => pool.Type === 'B-book')
        }
      }

      const platformObject = gateway.Platforms.find((platform: any) => platform.Name === params.Platform.value)

      const newState = {
        Gateway: gateway.Name,
        OriginalPool: buildSelectOption(activePool?.Name),
        SwitchToPool: buildSelectOption(targetPool?.Name),
        NewProcessor: buildSelectOption(targetPool && poolToProc.get(targetPool.Name) ? poolToProc.get(targetPool.Name)[0] : '' || ''),
        Symbols: platformObject?.Symbols || [],
      }

      setPoolToProcessors(poolToProc)
      setSettings({
        ...inputState,
        ...newState,
      })

      if (type === 'modify' || type === 'clone') {
        setInputState({
          ...inputState,
          NewProcessor: buildSelectOption(findNameNewProcessor),
        })
      }

      if (type === 'add') {
        setInputState({
          ...inputState,
          Gateway: gateway.Name,
          OriginalPool: buildSelectOption(activePool?.Name),
          SwitchToPool: buildSelectOption(targetPool?.Name),
          NewProcessor: buildSelectOption(targetPool && poolToProc.get(targetPool.Name) ? poolToProc.get(targetPool.Name)[0] : '' || ''),
          Symbols: platformObject?.Symbols || [],
          Symbol: platformObject?.Symbols[0],
        })
      }
    }
  }, [gateway]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (type === 'clone') {
      setInputState((prev: any) => {
        return {
          ...prev,
          RuleName: '',
        }
      })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const finT4BLpCurrentPool: any = gateway?.Pools?.find((item: any) => item?.Name === inputState.OriginalPool?.value)
  const findTypeLpCurrentPool: any = gateway?.Lps?.find((item: any) => finT4BLpCurrentPool?.Lps?.includes(item?.Name))

  const finT4BLpTargetPool: any = gateway?.Pools?.find((item: any) => item?.Name === inputState.SwitchToPool?.value)
  const findTypeLpTargetPool: any = gateway?.Lps?.find((item: any) => finT4BLpTargetPool?.Lps?.includes(item?.Name))

  const isUnsupportedCurrentPool = isSwitchUnsupportedPoolType(typeOfPool(gateway, inputState.OriginalPool?.value ?? '')) || isMixedPool(gateway, inputState.OriginalPool?.value ?? '')
  const isUnsupportedTargetPool = isSwitchUnsupportedPoolType(typeOfPool(gateway, inputState.SwitchToPool?.value ?? '')) || isMixedPool(gateway, inputState.SwitchToPool?.value ?? '')

  const switchTrigger = buildControlsExtTwoPerLine(
    [
      sselectInput(
        'SwitchTriggerType',
        buildMultiselectOptionsFromArray(
          Object.values(SwitchTriggerType).map((item: any) => {
            return {
              value: item,
              label: convert(item),
            }
          }),
        ),
      ).optionZindex(false),
      blankInput(),
      sselectInput('SwitchTriggerDirection', buildMultiselectOptionsFromArray(Object.values(SwitchTriggerDirection))).optionZindex(false),
      textInput('SwitchTriggerValue'),
    ],
    inputState,
    setInputState,
    'auto-switch',
    touched,
    setTouched,
    errors,
  )

  const returnTrigger = buildControlsExtTwoPerLine(
    [
      sselectInput(
        'ReturnTriggerType',
        buildMultiselectOptionsFromArray(
          Object.values(SwitchTriggerType).map((item: any) => {
            return {
              value: item,
              label: convert(item),
            }
          }),
        ),
      ).optionZindex(false),
      blankInput(),
      sselectInput('ReturnTriggerDirection', buildMultiselectOptionsFromArray(Object.values(SwitchTriggerDirection))).optionZindex(false),
      textInput('ReturnTriggerValue'),
    ],
    inputState,
    setInputState,
    'auto-switch',
    touched,
    setTouched,
    errors,
  )

  const setOriginalPool = (newState: any) => {
    const updatedState = { ...newState }
    updatedState.SwitchToPool = buildSelectOption(buildPoolsInputSwitch([], gateway, newState.OriginalPool.value)[0])
    updatedState.NewProcessor = buildSelectOption(poolToProcessors.get(updatedState.SwitchToPool.value) ? poolToProcessors.get(updatedState.SwitchToPool.value)[0] : '')
    setInputState(updatedState)
  }

  const setProcessor = (newState: any) => {
    const updatedState = { ...newState }
    updatedState.NewProcessor = buildSelectOption(poolToProcessors.get(newState.SwitchToPool.value) ? poolToProcessors.get(newState.SwitchToPool.value)[0] : '')
    setInputState(updatedState)
  }

  const alreadyExist = () => {
    if ((type === 'add' || type === 'clone') && data.find((item: any) => item.RuleName === inputState.RuleName)) {
      errors.RuleName = true
      return 'auto-switch.already-exist'
    }
    return ''
  }

  const test = () => {
    if (type === 'add' || type === 'modify' || type === 'clone') {
      return errors.NewProcessor ? 'Incorrect value' : ''
    }
    return ''
  }

  const inputs = buildControlsExtTwoPerLine(
    [
      textInput('RuleName').errorMessage(alreadyExist()),
      textInput('Groups', false, '', '', 'Mask', true),
      textInput('Accounts', false, '', '', 'Mask', true),
      sselectInput('Symbol', buildMultiselectOptionsFromArray(settings.Symbols)).optionZindex(false),
      sselectInput('OriginalPool', buildMultiselectOptionsFromArray(gateway?.Pools?.map((pool: any) => pool.Name) ?? []), false, false, inputState, setOriginalPool).optionZindex(false),
      sselectInput('SwitchToPool', buildMultiselectOptionsFromArray(buildPoolsInputSwitch([], gateway, inputState.OriginalPool?.value)), false, false, inputState, setProcessor).optionZindex(false),
      sselectInput('NewProcessor', buildMultiselectOptionsFromArray(poolToProcessors.get(inputState.SwitchToPool?.value) ?? []))
        .optionZindex(false)
        .errorMessage(test()),
      checkboxInput('AggregatedSwitch'),
    ],
    inputState,
    setInputState,
    'auto-switch',
    touched,
    setTouched,
    errors,
  )

  const handleClick = () => {
    if (!isValid() || isUnsupportedCurrentPool || isUnsupportedTargetPool || findTypeLpCurrentPool?.Type === 'T4BHedged' || findTypeLpTargetPool?.Type === 'T4BHedged') {
      if (mainRef.current) {
        mainRef.current.open()
      }
      if (errors.SwitchTriggerValue && switchRef.current) {
        switchRef.current.open()
      }
      if (errors.ReturnTriggerValue && returnRef.current) {
        returnRef.current.open()
      }

      return
    }

    const FindId = gateway.Processors.filter((item: any) => item?.Name === inputState.NewProcessor.value || item?.Id === inputState.NewProcessor.value)

    const newTrimState = {
      ...inputState,
      Symbol: inputState.Symbol.value,
      SwitchTriggerType: inputState.SwitchTriggerType.value,
      SwitchTriggerDirection: inputState.SwitchTriggerDirection.value,
      SwitchToPool: inputState.SwitchToPool.value,
      ReturnTriggerType: inputState.ReturnTriggerType.value,
      ReturnTriggerDirection: inputState.ReturnTriggerDirection.value,
      OriginalPool: inputState.OriginalPool.value,
      Accounts: isTrimString(inputState.Accounts),
      Groups: isTrimString(inputState.Groups),
    }
    delete newTrimState.Symbols

    dispatch(fetchAutoSwitchUpdate({ action: type, body: { ...newTrimState, NewProcessor: FindId[0]?.Id }, params: { ...params, Platform: params.Platform.value } }))
    dispatch(hideRightBar())
  }

  return (
    <>
      <AppAccordion
        item={{
          title: <FormattedMessage id={type === 'clone' ? 'auto-switch.rightbar.title.clone' : 'auto-switch.rightbar.title'} />,
          item: inputs,
        }}
        ref={mainRef}
        render={mainRef?.current}
        isHidden={false}
      />

      {(isUnsupportedCurrentPool && (
        <Alert className="d-flex mb-0" variant="danger">
          <FormattedMessage id="new-switch.unsupported-pool" />
        </Alert>
      )) ||
        (isUnsupportedTargetPool && (
          <Alert className="d-flex mb-0" variant="danger">
            <FormattedMessage id="new-switch.unsupported-pool" />
          </Alert>
        )) ||
        (findTypeLpCurrentPool?.Type === 'T4BHedged' && (
          <Alert className="d-flex mb-0" variant="danger">
            <FormattedMessage id="new-switch.T4BHedged.error" />
          </Alert>
        )) ||
        (findTypeLpTargetPool?.Type === 'T4BHedged' && (
          <Alert className="d-flex mb-0" variant="danger">
            <FormattedMessage id="new-switch.T4BHedged.error" />
          </Alert>
        ))}
      <AppAccordion
        item={{
          title: <FormattedMessage id="auto-switch.rightbar.switch-trigger" />,
          item: switchTrigger,
        }}
        ref={switchRef}
        render={switchRef?.current}
      />
      <AppAccordion
        item={{
          title: <FormattedMessage id="auto-switch.rightbar.return-trigger" />,
          item: returnTrigger,
        }}
        ref={returnRef}
        render={returnRef?.current}
      />
      <Button className="t4b-bg-dark-button my-3 ml-20" onClick={handleClick}>
        <FormattedMessage id={'Auto.switch.Save'} tagName="span" />
      </Button>
    </>
  )
}

export default AutoSwitchRightbar
