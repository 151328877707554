import React from 'react'
import { Form, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

interface ISearchInputProps {
  state: any
  setState: any
  name: string
  placeholder?: string
  label?: any
  className?: string
  tooltip?: string
  tooltipFlag?: boolean
  schema?: any
}
const SearchInput: React.FC<ISearchInputProps> = ({ state, setState, name, schema, placeholder, label, className, tooltip, tooltipFlag }) => {
  const handleChange = (event: any) => {
    setState({
      ...state,
      [name]: event.target.value,
    })
  }

  const renderTooltip = (props?: any, tooltip?: string) => (
    <Tooltip id="button-tooltip" {...props}>
      {tooltip}
    </Tooltip>
  )

  let isInvalid = false

  if (schema) {
    try {
      schema.validateSync(state[name])
    } catch (e) {
      isInvalid = true
    }
  }

  return (
    <>
      {tooltipFlag ? (
        <OverlayTrigger placement="bottom" delay={{ show: 500, hide: 400 }} overlay={renderTooltip({}, tooltip)}>
          <Form.Group className={className}>
            {label ? (
              <Form.Label>
                <FormattedMessage id={label} tagName="span" />
                <span>:</span>
              </Form.Label>
            ) : null}
            <Form.Control type="search" value={state[name]} onChange={handleChange} placeholder={placeholder} />
            {isInvalid ? (
              <div className="d-block invalid-feedback test position-button fontError">
                <FormattedMessage id="field-validation-error" tagName="span" />
              </div>
            ) : null}
          </Form.Group>
        </OverlayTrigger>
      ) : (
        <Form.Group className={className}>
          {label ? (
            <Form.Label>
              <FormattedMessage id={label} tagName="span" />
              <span>:</span>
            </Form.Label>
          ) : null}
          <Form.Control type="search" value={state[name]} onChange={handleChange} placeholder={placeholder} />
          {isInvalid ? (
            <div className="d-block invalid-feedback test position-button fontError">
              <FormattedMessage id="field-validation-error" tagName="span" />
            </div>
          ) : null}
        </Form.Group>
      )}
    </>
  )
}

export default React.memo(SearchInput)
