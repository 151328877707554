import { AppAccordion } from '@t4b/core/lib'
import React, { useEffect, useRef } from 'react'
import { Alert, Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { ConnectionString } from '../../entity/configuration'
import { convertPlatformFields, ExtApiPlatformEntity } from '../../entity/platforms'
import withGateway, { IGatewayProp } from '../../hocs/withGateway'
import { useDefaultDB } from '../../hooks/useDefaultDbSettings'
import { useFormValidation } from '../../hooks/useFormValidation'
import { fetchAddPlatform, modifyPlatform } from '../../redux/actions/platforms-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { blankInput, buildControlsExtTwoPerLine, checkboxInput, passwordInput, selectInput, sselectInput, textInput, timespanInput } from '../../utils/controls'
import DbConnectionEditor from '../configuration/system/DbConnectionEditor'
import { buildMultiselectOptionsFromArray } from '../../utils/multiselect-utils'

interface IFixApiPlatformEdit extends IGatewayProp {
  data: any
  isValidParent?(): boolean
}

const ExtApiPlatformEdit: React.FC<IFixApiPlatformEdit> = ({ data: { item, type, port }, isValidParent, gateway, gatewayName }) => {
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(new ExtApiPlatformEntity(item), ExtApiPlatformEntity.schema())
  const [connectionString, setConnectionString, connTouched, setConnTouched, connErrors, isConnValid] = useFormValidation(
    new ConnectionString(item.ConnectionString || {}),
    ConnectionString.schema(type),
  )
  const dispatch = useDispatch()
  const ref = useRef<any>(null)
  const dbRef = useRef<any>(null)

  useEffect(() => {
    setInputState({
      ...inputState,
      Name: item.Name,
      FileStorePath: item.FileStorePath,
      FileLogPath: item.FileLogPath,
    })
    setConnectionString({
      ...connectionString,
      Database: item.ConnectionString.Database,
    })
  }, [item.Name]) // eslint-disable-line react-hooks/exhaustive-deps

  const newPort = port.filter((elem: any) => elem !== item.SocketAcceptPort)
  const alreadyExist = () => {
    if (port.map(String).includes(String(inputState.SocketAcceptPort)) && type === 'add') {
      errors.SocketAcceptPort = true
      return 'Аlready exists'
    }

    if (newPort.map(String).includes(String(inputState.SocketAcceptPort))) {
      errors.SocketAcceptPort = true
      return 'Аlready exists'
    }
    return ''
  }

  const [isDefaultSettings, setDefaultDb] = useDefaultDB(type, connectionString, setConnectionString, gatewayName, inputState)

  const dbEditor = (
    <DbConnectionEditor
      type={type}
      state={connectionString}
      setState={setConnectionString}
      touched={connTouched}
      setTouched={setConnTouched}
      errors={connErrors}
      isDefaultSettings={isDefaultSettings}
      setIsDefaultSettings={setDefaultDb}
    />
  )
  const editPlatform = buildControlsExtTwoPerLine(
    [
      textInput('SocketAcceptPort').errorMessage(alreadyExist()),
      checkboxInput('UseLocalTime'),
      checkboxInput('AutoSwitchDST'),
      timespanInput('StartTime'),
      timespanInput('EndTime'),
      textInput('FileStorePath'),
      textInput('FileLogPath'),
      blankInput(),
      textInput('ExternalIp'),
      textInput('ExternalPort'),
    ],
    inputState,
    setInputState,
    'platform.fix',
    touched,
    setTouched,
    errors,
  )

  const ssl = buildControlsExtTwoPerLine(
    [
      checkboxInput('SSLEnable'),
      textInput('SslCertificate').disabled(!inputState.SSLEnable).holder('optional'),
      passwordInput('SslCertificatePassword', type === 'add' ? 'setup' : 'change')
        .disabled(!inputState.SSLEnable)
        .holder('optional'),
      textInput('SslCaCertificate').disabled(!inputState.SSLEnable).holder('optional'),
      textInput('SslServerName').disabled(!inputState.SSLEnable).holder('optional'),
      sselectInput('SslProtocols', buildMultiselectOptionsFromArray(gateway?.SslProtocols) ?? [])
        .disabled(!inputState.SSLEnable)
        .textMessages(
          inputState.SslProtocols.value === 'SSL 2.0' || inputState.SslProtocols.value === 'SSL 3.0'
            ? 'Protocol is deprecated. Not all systems support it, the connection may not work!'
            : '',
        ),
      checkboxInput('SslCheckCertificateRevocation').disabled(!inputState.SSLEnable),
      checkboxInput('SslRequireClientCertificate').disabled(!inputState.SSLEnable),
      checkboxInput('SslValidateCertificates').disabled(!inputState.SSLEnable),
    ],
    inputState,
    setInputState,
    'platform.fix',
    touched,
    setTouched,
    errors,
  )

  const handleSave = () => {
    const cond1 = isValid()
    let cond2 = isConnValid()

    if (isValidParent) {
      let validName = isValidParent()
      if (!validName) {
        return
      }
    }

    if (!cond1) {
      ref?.current?.open()
    }

    if (!cond2) {
      dbRef?.current?.open()
    }

    if (!cond1 || !cond2) {
      return
    }

    dispatch(hideRightBar())
    if (type === 'add') {
      dispatch(
        fetchAddPlatform({
          action: 'add',
          params: { Gateway: gateway.Name },
          body: {
            ...convertPlatformFields(inputState),
            SslProtocols: inputState.SslProtocols.value,
            ConnectionString: connectionString,
            SaveLpBookSnapshots: inputState.SaveLpBookSnapshots.value,
          },
        }),
      )
    } else if (type === 'modify') {
      dispatch(
        modifyPlatform({
          action: 'modify',
          params: { Gateway: gateway.Name },
          body: {
            ...convertPlatformFields(inputState),
            SslProtocols: inputState.SslProtocols.value,
            ConnectionString: connectionString,
            SaveLpBookSnapshots: inputState.SaveLpBookSnapshots.value,
          },
        }),
      )
    }
  }

  const headerText = () => {
    if (type === 'add') {
      if (item.Type === 'extapi') {
        return 'EXT-Platform'
      }
      if (item.Type === 'ctrader') {
        return 'cTrader'
      }
      if (item.Type === 'match-trader') {
        return 'Match-Trade'
      }

      if (item.Type === 'dxtrade') {
        return 'DXtrade'
      }
      if (item.Type === 'vertexfx') {
        return 'VertexFX'
      }
    } else {
      if (item.Type === 'dxtrade') {
        return `Modify ${item.Name} (DXtrade)`
      }
      if (item.Type === 'vertexfx') {
        return `Modify ${item.Name} (VertexFX)`
      }

      if (item.Type === 'extapi') {
        return `Modify ${item.Name} (EXT-Platform)`
      }
      if (item.Type === 'ctrader') {
        return `Modify ${item.Name} (cTrader)`
      }
      if (item.Type === 'match-trader') {
        return `Modify ${item.Name} (Match-Trade)`
      }
    }
  }

  const options = [
    { value: 'Disabled', label: 'Disabled' },
    { value: 'ExecutionOnly', label: 'Only for LPs with executions' },
    { value: 'All', label: 'For all LPs in aggregation pool' },
  ]

  const editSnapshots = buildControlsExtTwoPerLine(
    [sselectInput('SaveLpBookSnapshots', options), checkboxInput('SavePlatformBookSnapshot')],
    inputState,
    setInputState,
    'Snapshots',
    touched,
    setTouched,
    errors,
  )

  return (
    <>
      <AppAccordion
        item={{
          title: <FormattedMessage id="platform.rightbar.edit.ext" values={{ Type: headerText() }} />,
          item: (
            <div>
              {editPlatform}
              <Alert className="d-flex mt-2 mb-0" variant="warning">
                <FormattedMessage id="platform.rightbar.warning" />
              </Alert>
            </div>
          ),
        }}
        ref={ref}
        render={ref?.current}
        isHidden={false}
      />

      <AppAccordion
        item={{
          title: <FormattedMessage id="platform.rightbar.conn-string" />,
          item: dbEditor,
        }}
        ref={dbRef}
      />

      <AppAccordion
        item={{
          title: <FormattedMessage id="platform.rightbar.ssl" />,
          item: ssl,
        }}
      />

      <AppAccordion
        item={{
          title: <FormattedMessage id="platform.rightbar.Snapshots" />,
          item: editSnapshots,
        }}
      />

      <Button className="t4b-bg-dark-button my-3 ml-20" onClick={handleSave}>
        <FormattedMessage id="save" />
      </Button>
    </>
  )
}

export default withGateway(ExtApiPlatformEdit)
