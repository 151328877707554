import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import MouseOver from './MouseOver';

function numToHex(value) {
  let val = parseInt(value || '').toString(16);
  if (val.length === 1) {
    val = '0' + val;
  }
  return val;
}

const initialState = {
  r: '',
  g: '',
  b: ''
};

const ColorCreator = ({ onCreate, onClose }) => {
  const [inputState, setInputState] = useState(initialState);

  const inputStyles = {width: '55px', display: 'inline-flex'};

  const iconCloseStyles = (isOver) => ({
    width: '28px',
    height: '28px',
    padding: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: '50%',
    transition: 'background-color 200ms ease',
    backgroundColor: isOver ? 'rgba(0, 0, 0, 0.05)' : 'inherit'
  })

  const handleSubmit = (event) => {
    event.preventDefault();

    if (inputState.r === '' || inputState.g === '' || inputState.b === '') {
      return;
    }

    const hexColor = '#' + numToHex(inputState.r) + numToHex(inputState.g) + numToHex(inputState.b);

    if (onCreate) {
      onCreate({
        name: hexColor,
        color: hexColor
      })
    }

    setInputState(initialState);
  }

  const handleChange = (event) => {
    const val = Number(event.target.value);
    if (val >= 0 && val <= 255) {
      setInputState({
        ...inputState,
        [event.target.name]: event.target.value
      })
    }
  }

  return (
    <div style={{padding: '8px'}}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '16px'
        }}
      >
        <h5 style={{margin: 0}}>Create own color</h5>

        <MouseOver>
          {(isOver) => (
            <div onClick={onClose} style={iconCloseStyles(isOver)}>
              <FontAwesomeIcon icon={faTimes}/>
            </div>
          )}
        </MouseOver>
      </div>

      <form onSubmit={handleSubmit}>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <label style={{margin: 0}}>
            <span style={{marginRight: '3px'}}>R:</span>
            <input
              type='text'
              name='r'
              value={inputState.r}
              className='form-control'
              style={inputStyles}
              onChange={handleChange}
            />
          </label>

          <label style={{margin: 0}}>
            <span style={{marginRight: '3px'}}>G:</span>
            <input
              type='text'
              name='g'
              value={inputState.g}
              className='form-control'
              style={inputStyles}
              onChange={handleChange}
            />
          </label>

          <label style={{margin: 0}}>
            <span style={{marginRight: '3px'}}>B:</span>
            <input
              type='text'
              name='b'
              value={inputState.b}
              className='form-control'
              style={inputStyles}
              onChange={handleChange}
            />
          </label>

          <input type='submit' value='Add'/>
        </div>
      </form>
    </div>
  )
}

export default ColorCreator;