import React, { memo, useEffect, useMemo, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { hideModal } from '../../../redux/actions/modal-actions'
import { useDispatch, useSelector } from 'react-redux'
import SaveTable from '../../SaveTable'
import { faCog, faFileDownload } from '@fortawesome/free-solid-svg-icons'
import { fetchReportDeals, fetchReportDealsDownload } from '../../../redux/actions/deals-actions'
import { RootState } from '../../../redux/reducers/rootReducer'
import { QuotesViewModal } from './QuotesViewModal'
import { AppAccordion, AppTable } from '@t4b/core'
import { buildInfoItems, infoTime } from '../../../utils/info-item-utils'
import { buildTableData, buildTableHeadersConfigured, newAsTime } from '../../../utils/table-utils'

export const ModalDetails = memo(() => {
  const dispatch = useDispatch()
  const { modalData } = useSelector((state: any) => state.modals)
  const { report } = useSelector((state: RootState) => state.deals)

  const handleYes = () => {
    if (modalData.onYes) {
      modalData.onYes()
    }
    dispatch(hideModal())
  }

  const handleNo = () => {
    dispatch(hideModal())
  }

  const saveOptions = [
    {
      translateKey: 'Save as xlsx',
      callback: () => {
        dispatch(
          fetchReportDealsDownload({
            FileType: 'xlsx',
            ...modalData.params,
          }),
        )
      },
    },
    {
      translateKey: 'Save as csv',
      callback: () => {
        dispatch(
          fetchReportDealsDownload({
            FileType: 'csv',
            ...modalData.params,
          }),
        )
      },
    },
    {
      translateKey: 'Save as txt',
      callback: () => {
        dispatch(
          fetchReportDealsDownload({
            FileType: 'txt',
            ...modalData.params,
          }),
        )
      },
    },
    {
      translateKey: 'Save as json',
      callback: () => {
        dispatch(
          fetchReportDealsDownload({
            FileType: 'json',
            ...modalData.params,
          }),
        )
      },
    },
  ]

  const quotes = useMemo(
    () => ({
      OrderBook: {
        Ask:
          report?.PlatformMarketDepth?.Asks.map((item: any, index: number) => {
            return {
              ...item,
              Spreed: report?.PlatformMarketDepth?.Spreads[index],
            }
          }) ?? [],
        Bid:
          report?.PlatformMarketDepth?.Bids.map((item: any, index: number) => {
            return {
              ...item,
              Spreed: report?.PlatformMarketDepth?.Spreads[index],
            }
          }) ?? [],
      },
    }),
    [report],
  )

  const DealsOne = buildInfoItems(['OrderId', 'Symbol', 'Side', 'Type', 'TypeFill'], report?.PlatformOrder ?? {}, 'dealsModal', 'additional', true)
  const DealsTwo = buildInfoItems(['FillVolume', 'ExternalId', 'Account', 'Group', 'AveragePrice'], report?.PlatformOrder ?? {}, 'dealsModal', 'additional', true)

  const AdditionalOne = buildInfoItems(['Status', 'ExternalId'], report?.PlatformOrder ?? {}, 'dealsModal', 'additional', true)
  const AdditionalTwo = buildInfoItems(['PlacementType'], report?.PlatformOrder ?? {}, 'dealsModal', 'additional', true)
  const AdditionalThree = buildInfoItems([infoTime('Time')], { Time: report?.Time } ?? {}, 'dealsModal', 'additional', true)

  const header = [
    { name: 'Lp', show: true, sortable: false },
    { name: 'ClientOrderId', show: true, sortable: false },
    { name: 'OrderVolume', show: true, sortable: false },
    { name: 'LpPrice', show: true, sortable: false },
    { name: 'TransactionTime', show: true, format: newAsTime, sortable: false },
  ]

  const [depth, setDepth] = useState(5)
  const saveDepth = Array.from({ length: 20 }, (_, index) => index + 1)?.map((item: any) => {
    return {
      translateKey: String(item),
      callback: () => {
        setDepth(item)
      },
    }
  })

  return (
    <Modal show={true} onHide={handleNo} start size={'xl'}>
      <Modal.Header closeButton={true} className="color-dark font-500 modal-header-custom">
        {report.GlobalSymbol}
        <div className="mAuto">
          <SaveTable icon={faCog} items={saveDepth} depthFlag={true} />
          <SaveTable className={'SaveTable-custom'} icon={faFileDownload} items={saveOptions} />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div>
          <h4 className="line">Market depth sent to platform</h4>
          <div className="d-flex justify-content-center ">
            <div className="mr-5 d-flex flex-column mb-5 table-modal">
              <AppAccordion
                item={{
                  title: <FormattedMessage id="OrderDetails" />,
                  item: (
                    <>
                      <section className="row mb-2">
                        <div className="col-6">{DealsOne}</div>
                        <div className="col-6">{DealsTwo}</div>
                      </section>
                    </>
                  ),
                }}
                className={'accardionModal'}
                style={{ fontSize: '13px' }}
                isHidden={false}
              />
              <AppAccordion
                item={{
                  title: <FormattedMessage id="Additional" />,
                  item: (
                    <>
                      <section className="row mb-2">
                        <div className="col-6">{AdditionalOne}</div>
                        <div className="col-6">
                          {AdditionalTwo}
                          {AdditionalThree}
                        </div>
                      </section>
                    </>
                  ),
                }}
                className={'accardionModal'}
                style={{ fontSize: '13px' }}
                isHidden={false}
              />
              <AppAccordion
                item={{
                  title: <FormattedMessage id="Details" />,
                  item: (
                    <>
                      <AppTable
                        tableHeader={buildTableHeadersConfigured(
                          header,
                          {
                            field: '',
                            by: 'none',
                          },
                          () => {},
                          'modalDeals',
                        )}
                        tableData={buildTableData(
                          report?.LpExecutions ?? [],
                          header,
                          () => {},
                          () => {},
                        )}
                        scrollOptions={{ x: 'scroll', y: 'visible-hidden' }}
                      />
                    </>
                  ),
                }}
                className={'accardionModalLast'}
                style={{ fontSize: '13px' }}
                isHidden={false}
              />
            </div>
            <div className=" mb-5">
              <QuotesViewModal quotes={quotes} depth={depth} name={report.PlatformMarketDepth?.Source} />
            </div>
          </div>
          <div>
            <h4 className="line">LP market depth at order sending moment</h4>
            <div className="d-flex flex-wrap justify-content-between">
              {report?.LpMarketDepths?.map((item: any) => {
                return (
                  <QuotesViewModal
                    quotes={{
                      OrderBook: {
                        Ask: [
                          ...item?.Asks.map((elem: any, index: number) => {
                            return {
                              ...elem,
                              Spreed: item?.Spreads[index],
                            }
                          }),
                        ],
                        Bid: [
                          ...item?.Bids.map((elem: any, index: number) => {
                            return {
                              ...elem,
                              Spreed: item?.Spreads[index],
                            }
                          }),
                        ],
                      },
                    }}
                    depth={depth}
                    name={item.Source}
                  />
                )
              })}
            </div>
          </div>
        </div>
        {/*    */}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleYes} className="button-modal">
          <FormattedMessage id="Close" />
        </Button>
      </Modal.Footer>
    </Modal>
  )
})
