import React, { useEffect } from 'react'
import { IQuoteData, makeKey } from './quotes-types'
import { Card } from 'react-bootstrap'
import classNames from 'classnames'
import QuotesTable from './QuotesTable'


interface ISourceData {
  quotes: IQuoteData
}

export const TopBidPrice: React.FC<ISourceData> = ({ quotes }) => {
  const topBidQuote = quotes.OrderBook.Bid[0] || { Volume: 0, Price: 0 }
  return (
    <div className="col-6">
      <div className="d-flex justify-content-center">
        <span style={{ fontSize: '25px', fontWeight: 700 }}>{topBidQuote.Price}</span>
      </div>
      <div className="d-flex justify-content-center">{topBidQuote.Volume < 100000 ? `${topBidQuote.Volume}` : `${topBidQuote.Volume / 1000000} M`}</div>
    </div>
  )
}

export const TopAskPrice: React.FC<ISourceData> = ({ quotes }) => {
  const topAskQuote = quotes.OrderBook.Ask[0] || { Volume: 0, Price: 0 }
  return (
    <div className="col-6">
      <div className="d-flex justify-content-center">
        <span style={{ fontSize: '25px', fontWeight: 700 }}>{topAskQuote.Price}</span>
      </div>
      <div className="d-flex justify-content-center">{topAskQuote.Volume < 100000 ? `${topAskQuote.Volume}` : `${topAskQuote.Volume / 1000000} M`}</div>
    </div>
  )
}

export const TotalBidVolume: React.FC<ISourceData> = ({ quotes }) => {
  const TotalBidVolume = quotes.OrderBook.Bid.reduce((sum, current) => sum + current.Volume, 0)
  return (
    <div className="col-6">
      <div className="d-flex justify-content-center">Total Bid</div>
      <div className="d-flex justify-content-center">
        {TotalBidVolume < 100000 ? `${TotalBidVolume.toFixed(5)}` : `${(TotalBidVolume / 1000000).toFixed(5)}`}
      </div>
    </div>
  )
}

export const TotalAskVolume: React.FC<ISourceData> = ({ quotes }) => {
  const TotalAskVolume = quotes.OrderBook.Ask.reduce((sum, current) => sum + current.Volume, 0)
  return (
    <div className="col-6">
      <div className="d-flex justify-content-center">Total Ask</div>
      <div className="d-flex justify-content-center">
        {TotalAskVolume < 100000 ? `${TotalAskVolume.toFixed(5)}` : `${(TotalAskVolume / 1000000).toFixed(5)}`}
      </div>
    </div>
  )
}

export const QuotesView: React.FC<any> = ({ sourceData, quotesStorage, socket, symbol, selection }) => {
  useEffect(() => {
    if (socket?.readyState === 1) {
      socket?.send(
        JSON.stringify({
          Action: 'Subscribe',
          SourceType: sourceData.SourceType,
          SourceName: sourceData.SourceName,
          Symbol: symbol,
        }),
      )
    }

    return () => {
      if (socket?.readyState === 1) {
        socket?.send(
          JSON.stringify({
            Action: 'Unsubscribe',
            SourceType: sourceData.SourceType,
            SourceName: sourceData.SourceName,
            Symbol: symbol,
          }),
        )
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const key = {
    SourceType: sourceData.SourceType,
    SourceName: sourceData.SourceName,
    Symbol: symbol,
  }

  const me = makeKey(key, key.Symbol)

  let quotes = quotesStorage.get(me)
  if (!quotes) {
    quotes = {
      OrderBook: {
        Ask: [],
        Bid: [],
      },
    }
  }

  return (
    <Card className={classNames('quote-source', 'mb-20', selection.has(me) && 'highlighted', 'm-auto')}>
      <Card.Header
        className={classNames('accordion-custom__toggle', 'd-flex', 'justify-content-between', 'text-white', 'font-500', 'border-none', {
          'bg-color-olive': sourceData.SourceType === 'FeedAggregation',
          'bg-color-dark': sourceData.SourceType === 'LiquidityProvider',
        })}
      >
        <span>{sourceData.SourceName}</span>
      </Card.Header>
      <Card.Body className="accordion-custom__collapse">
        <div className="row">
          <TopBidPrice quotes={quotes} />
          <TopAskPrice quotes={quotes} />
        </div>
        <div className="d-flex justify-content-center">
          <QuotesTable depth={sourceData.Depth} quotesStorage={quotesStorage} selection={selection} me={me} expand />
        </div>
        <div
          style={{
            borderBottom: '2px solid rgba(0, 0, 0, 0.3)',
            margin: '1rem 0',
          }}
        />
        <div className="row">
          <TotalBidVolume quotes={quotes} />
          <TotalAskVolume quotes={quotes} />
        </div>
        <div
          style={{
            borderBottom: '2px solid rgba(0, 0, 0, 0.3)',
            margin: '1rem 0',
          }}
        />
      </Card.Body>
    </Card>
  )
}
