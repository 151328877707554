import React from 'react'
import { Alert, Button, Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { OrderSide, OrderType, ProcessingRuleItem, ProcessingRuleItemNotValid, roundTypes } from '../../entity/configuration'
import { useFormValidation } from '../../hooks/useFormValidation'
import { addProcessingRuleItem, changeProcessingRuleItem, executionConfigurationChanged, getProcessingRuleItems } from '../../redux/actions/execution/execution-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { RootState } from '../../redux/reducers/rootReducer'
import { blankInput, buildControlsExtTwoPerLine, checkboxInput, cmselectInput, mselectInput, sselectInput, textInput } from '../../utils/controls'
import {
  buildMultiselectOptionsFromArray,
  buildMultiselectOptionsFromEnum,
  buildOptionsWithAll,
  buildSelectOption,
  isAllOption,
  isEqualArrays,
  isTrimString,
  optionsToStrings,
} from '../../utils/multiselect-utils'
import { IRightbar } from './rightbar-types'
import { useTranslate } from '../../hooks/useTranslate'

const ProcessingRuleItemRightbar: React.FC<IRightbar> = React.memo(({ data: { type, item, params } }) => {
  const dispatch = useDispatch()
  const translate = useTranslate()
  const { aggregationPools, routingRules } = useSelector((state: RootState) => state.executionConfiguration)

  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(
    new ProcessingRuleItem({
      ...item,
      Symbols: Array.isArray(item?.Symbols) ? item?.Symbols?.map((elem: any) => buildSelectOption(elem)) : item?.Symbols.split(',').map((elem: any) => buildSelectOption(elem)),
      MinVolume: String(item.MinVolume),
      MaxVolume: String(item.MaxVolume),
      OrderSides: { value: item.OrderSides, label: item.OrderSides },
      RoundType: { value: item.RoundType, label: translate(`round-type.${item.RoundType}`) },
      ExecutionPriceSource: { value: item.ExecutionPriceSource, label: translate(`price-source.${item.ExecutionPriceSource}`) },
    }),
    ProcessingRuleItemNotValid.schema,
  )

  const { gateway } = useSelector((state: RootState) => state.gateways)

  const findRouting: any = routingRules.filter((item: any) => item.ProcessorName === params.Processor)
  const findPlatform = findRouting
    .map((item: any) => item.Platforms)
    .flat(Infinity)
    .map((item: any) => item.value)
    .filter((item: any, index: number, arr: any) => arr.indexOf(item) === index)

  const activePlatform = gateway?.Platforms?.filter((item: any) => {
    if (findPlatform.includes(item.Name)) {
      return item
    }

    if (findPlatform[0] === 'All') {
      return item
    }
  })

  const platformTags = activePlatform.map((item: any) => item.Tags).flat(Infinity)
  const platformSymbols = activePlatform
    .map((item: any) => item.Symbols)
    .flat(Infinity)
    .map((item: any) => item.value)

  const handleSave = () => {
    if (!isValid()) {
      return
    }

    const newTrimState = {
      ...inputState,
      Logins: isTrimString(inputState.Logins),
      Groups: isTrimString(inputState.Groups),
      Symbols: inputState.Symbols.map((item: any) => {
        if (item.value.includes(',')) {
          return item.value.split(',')
        } else {
          return item.value
        }
      }).flat(Infinity),
      Security: isTrimString(inputState.Security),
      OrderSides: inputState.OrderSides.value,
      RoundType: inputState.RoundType.value,
      ExecutionPriceSource: inputState.ExecutionPriceSource.value,
    }

    const rule = new ProcessingRuleItem(newTrimState)
    if (type === 'add' || type === 'clone') {
      dispatch(addProcessingRuleItem({ rule, params, Profile: params.profile }))
    } else {
      dispatch(changeProcessingRuleItem({ rule, params, Profile: params.profile }))
    }
    dispatch(getProcessingRuleItems({ ...params }))
    dispatch(executionConfigurationChanged())
    dispatch(hideRightBar())
  }

  const setOrderTypesState = (state: any) => {
    const all = 'All'

    if (
      state.OrderTypes?.find(isAllOption) ||
      isEqualArrays(
        optionsToStrings(state.OrderTypes)
          .filter(option => option !== all)
          .sort(),
        Object.keys(OrderType)
          .filter(option => option !== all)
          .sort(),
      )
    ) {
      setInputState({
        ...state,
        OrderTypes: buildMultiselectOptionsFromArray([all]),
      })
      return
    }
    setInputState(state)
  }

  if (+inputState.MinVolume > 1000000000 || inputState.MinVolume.slice(-1) === '.') {
    errors.MinVolume = true
  }

  if (+inputState.MaxVolume > 1000000000 || inputState.MaxVolume.slice(-1) === '.') {
    errors.MaxVolume = true
  }

  const findBBookPool: any = aggregationPools?.find((item: any) => item.Name === params.LpPool)
  const findPoolNameLp = findBBookPool?.Lps.map((item: any) => item.value)
  const findABBookType = gateway?.Lps.filter((item: any) => findPoolNameLp?.includes(item.Name))
  const findBbookType = findABBookType.filter(
    (item: any) =>
      item.Type === 'BbookHedged' || item.Type === 'VolumeConverter' || item.Type === 'Bbook' || item.Type === 'HedgingSyntheticSymbol' || item.Type === 'HedgingSyntheticIndex',
  )
  let flag = findABBookType.length - findBbookType.length

  const options = [
    {
      label: '---Symbols---',
      value: '1',
      options: buildMultiselectOptionsFromArray(platformSymbols.filter((item: string, i: number, arr: string[]) => arr.indexOf(item) === i)),
    },
    {
      label: '---Tags---',
      value: '2',
      options: buildMultiselectOptionsFromArray(platformTags.filter((item: string, i: number, arr: string[]) => arr.indexOf(item) === i)),
    },
  ]

  return (
    <Card>
      <Card.Header className="color-dark font-500">
        <FormattedMessage id={`processing-rule.${type}`} />
      </Card.Header>
      <Card.Body>
        {buildControlsExtTwoPerLine(
          [
            cmselectInput('Symbols', options || [], '', 'Mask', true).title('Add mask:'),
            textInput('Logins', false, '', '', 'Mask', true),
            textInput('Groups', false, '', '', 'Mask', true),
            textInput('MinVolume'),
            textInput('MaxVolume'),
            textInput('Security', false, '', '', 'Mask', true),
            mselectInput('OrderTypes', buildOptionsWithAll(buildMultiselectOptionsFromEnum(OrderType), inputState.OrderTypes)).stateSetup(setOrderTypesState),
            sselectInput('OrderSides', buildMultiselectOptionsFromArray(Object.keys(OrderSide))).optionZindex(false),
            blankInput(),
            textInput('MarkupFrom').disabled(inputState.ExecutionPriceSource.value === 'Platform'),
            textInput('MarkupTo').disabled(inputState.ExecutionPriceSource.value === 'Platform'),
            textInput('LimitMarkupFrom').disabled(inputState.ExecutionPriceSource.value === 'Platform'),
            textInput('LimitMarkupTo').disabled(inputState.ExecutionPriceSource.value === 'Platform'),
            sselectInput(
              'RoundType',
              roundTypes.map((item: any) => {
                return {
                  value: item,
                  label: translate(`round-type.${item}`),
                }
              }),
            ).optionZindex(false),
            sselectInput(
              'ExecutionPriceSource',
              ['LP', 'Platform', 'TakePositiveSlippage'].map((item: any) => {
                return {
                  value: item,
                  label: translate(`price-source.${item}`),
                }
              }),
            )
              .optionZindex(false)
              .skipWhen(!gateway.EnablePriceOption)
              .disabled(!Boolean(flag)),
            textInput('DelayFrom').skipWhen(inputState.ExecutionPriceSource.value !== 'TakePositiveSlippage'),
            textInput('DelayTo').skipWhen(inputState.ExecutionPriceSource.value !== 'TakePositiveSlippage'),
            checkboxInput('AllowContinuousExecutionForFOK'),
            checkboxInput('ExecuteLimitsAsMarkets'),
            checkboxInput('Enabled'),
          ],
          inputState,
          setInputState,
          'processing-rule',
          touched,
          setTouched,
          errors,
        )}
        {inputState.ExecuteLimitsAsMarkets && (
          <Alert className="d-flex mb-0" variant="danger">
            <FormattedMessage id="ExecuteLimitsAsMarkets.error" />
          </Alert>
        )}
        <Button className="t4b-bg-dark-button mt-3" onClick={handleSave}>
          <FormattedMessage id="save" tagName="span" />
        </Button>
      </Card.Body>
    </Card>
  )
})

export default ProcessingRuleItemRightbar
