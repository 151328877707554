import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { fetchGatewayStatus } from '../redux/actions/platforms-actions'
import { asTime } from '../utils/table-utils'

function statusToString(status: number) {
  const statusMap: { [key: string]: any } = {
    0: { text: 'status.offline', color: 'text-warning' },
    1: { text: 'status.online', color: 'text-success' },
    2: { text: 'reboot.required', color: 'text-danger' },
    3: { text: 'reboot.restarting', color: 'text-danger' },
    4: { text: 'reboot.Starting', color: 'text-warning' },
    5: { text: 'reboot.Stopping', color: 'text-danger' },
  }

  return statusMap[status] || { text: 'status.unknown', color: 'text-danger' }
}

const GatewayStatus: React.FC = () => {
  const dispatch = useDispatch()
  const { gateway } = useSelector((state: any) => state.gateways)
  const isAuthenticated = useSelector((state: any) => state.auth)
  const { Status, DelayedRebootTime } = useSelector((state: any) => state.status)

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      if (gateway.Name && isAuthenticated) {
        const interval = setInterval(() => dispatch(fetchGatewayStatus({ Gateway: gateway.Name })), 2 * 1000)
        return () => clearInterval(interval)
      }
    }
  }, [gateway, isAuthenticated]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={`d-flex flex-column justify-content-center mr-2 font-500 ${statusToString(Status).color}`}>
      <div className="d-flex position-relative">
        <span className="mr-1">
          <FormattedMessage id="gateway-status" />
        </span>
        <FormattedMessage id={statusToString(Status).text} />
        {Status !== 0 && Boolean(DelayedRebootTime) && (
          <span
            className="position-absolute text-warning"
            style={{
              top: '70%',
              left: 0,
              fontSize: '13px',
              whiteSpace: 'nowrap',
            }}
          >
            <FormattedMessage
              id="reboot-planned"
              values={{
                DelayedRebootTime: asTime(DelayedRebootTime, 'MMM Do, HH:mm:ss'),
              }}
            />
          </span>
        )}
      </div>
    </div>
  )
}

export default GatewayStatus
