import { Dispatch } from 'redux'
import { throwSuccessMessage } from '../../../utils/errors-utils'
import { buildHTTPGetOptions, buildHTTPPostOptions, checkResponse, processError } from '../../../utils/fetch-utils'
import { Action } from '../actions'

function onlyFetchDropCopySessions(params: any) {
  const url = new URL('/api/dropCopy/sessions', window.location.origin)
  url.searchParams.set('gateway', params.Gateway)
  url.searchParams.set('page', params.Page)
  url.searchParams.set('count', params.Count)
  if (params.field.length && params.by !== 'none') {
    url.searchParams.set('sort', params.field)
    url.searchParams.set('by', params.by)
  }
  return fetch(url.toString(), buildHTTPGetOptions()).then((response: Response) => checkResponse(response, '/api/dropCopy/sessions'))
}

export function fetchDropCopySessions(params: any) {
  return (dispatch: Dispatch) => {
    dispatch({ type: Action.InProgressStart })
    params.setLoading(true)

    return onlyFetchDropCopySessions(params)
      .then((data: any) => dispatch({ type: Action.GotDropCopySessions, data }))
      .catch((error: Error) => processError(error, dispatch))
      .finally(() => {
        dispatch({ type: Action.InProgressEnd })
        params.setLoading(false)
      })
  }
}

export function fetchDropCopySessionsDelete(params: any) {
  return (dispatch: Dispatch) => {
    const url = new URL('/api/dropCopy/sessionsDelete', window.location.origin)
    url.searchParams.set('gateway', params.Gateway)
    const req = {
      SenderCompId: params.SenderCompId,
      TargetCompId: params.TargetCompId,
      BeginString: params.BeginString,
    }

    dispatch({ type: Action.InProgressStart })
    params.setLoading(true)

    return fetch(url.toString(), buildHTTPPostOptions(req))
      .then((response: Response) => checkResponse(response))
      .then((result: any) => {
        if (result.Status) {
          return new Promise((resolve, reject) => reject(new Error(`drop-copy.status.${result.Status}`)))
        }
        throwSuccessMessage('Successfully')
        return onlyFetchDropCopySessions(params)
      })
      .then((data: any) => dispatch({ type: Action.GotDropCopySessions, data }))
      .catch((error: Error) => processError(error, dispatch))
      .finally(() => {
        dispatch({ type: Action.InProgressEnd })
        params.setLoading(false)
      })
  }
}

export function fetchDropCopySessionsUpdate(arg: any) {
  const { action, rule, params } = arg
  const url = new URL(`/api/dropCopy/sessions${action === 'clone' ? 'add' : `${action[0].toUpperCase()}${action.slice(1)}`}`, document.location.origin)
  url.searchParams.set('gateway', params.Gateway)

  return (dispatch: Dispatch) => {
    dispatch({ type: Action.InProgressStart })
    params.setLoading(true)

    return fetch(url.toString(), buildHTTPPostOptions(rule))
      .then((response: Response) => checkResponse(response))
      .then((result: any) => {
        if (result.Status) {
          return new Promise((resolve, reject) => reject(new Error(`drop-copy.status.${result.Status}`)))
        }
        throwSuccessMessage('Successfully')
        return onlyFetchDropCopySessions(params)
      })
      .then((data: any) => dispatch({ type: Action.GotDropCopySessions, data }))
      .catch((error: Error) => processError(error, dispatch))
      .finally(() => {
        dispatch({ type: Action.InProgressEnd })
        params.setLoading(false)
      })
  }
}

function onlyFetchDropCopySettings(params: any) {
  const url = new URL('/api/dropCopy/settings', window.location.origin)
  url.searchParams.set('gateway', params.Gateway)
  return fetch(url.toString(), buildHTTPGetOptions()).then((response: Response) => checkResponse(response, '/api/dropCopy/settings'))
}

export function fetchDropCopySettings(params: any) {
  return (dispatch: Dispatch) => {
    dispatch({ type: Action.InProgressStart })

    return onlyFetchDropCopySettings(params)
      .then((data: any) => dispatch({ type: Action.GotDropCopySettings, data }))
      .catch((error: Error) => processError(error, dispatch))
      .finally(() => dispatch({ type: Action.InProgressEnd }))
  }
}

export function fetchDropCopySettingsUpdate(params: any, settings: any) {
  return (dispatch: Dispatch) => {
    const url = new URL('/api/dropCopy/settingsModify', window.location.origin)
    url.searchParams.set('gateway', params.Gateway)

    dispatch({ type: Action.InProgressStart })

    return fetch(url.toString(), buildHTTPPostOptions(settings, '/api/dropCopy/settingsModify'))
      .then((response: Response) => checkResponse(response))
      .then((result: any) => {
        if (result.Status) {
          return new Promise((resolve, reject) => reject(new Error(`drop-copy.status.${result.Status}`)))
        }
        throwSuccessMessage('Successfully')
        return onlyFetchDropCopySettings(params)
      })
      .then((data: any) => dispatch({ type: Action.GotDropCopySettings, data }))
      .catch((error: Error) => processError(error, dispatch))
      .finally(() => dispatch({ type: Action.InProgressEnd }))
  }
}

export function fetchDropCopySettingsToogle(params: any, settings: any) {
  return (dispatch: Dispatch) => {
    const url = new URL('/api/dropCopy/settingsSetEnable', window.location.origin)
    url.searchParams.set('gateway', params.Gateway)

    dispatch({ type: Action.InProgressStart })

    return fetch(url.toString(), buildHTTPPostOptions({ Flag: !settings.DropCopyEnabled }))
      .then((response: Response) => checkResponse(response))
      .then((result: any) => {
        if (result.Status) {
          return new Promise((resolve, reject) => reject(new Error(`drop-copy.status.${result.Status}`)))
        }
        return onlyFetchDropCopySettings(params)
      })
      .then((data: any) => dispatch({ type: Action.GotDropCopySettings, data }))
      .catch((error: Error) => processError(error, dispatch))
      .finally(() => dispatch({ type: Action.InProgressEnd }))
  }
}
