import { combineReducers } from 'redux'
import authReducer from './authReducer'
import { backupsReducer } from './backupsReducer'
import ceReducer from './ceReducer'
import commonSettingsReducer from './commonSettingsReducer'
import consolidationReducer from './consolidationReducer'
import dropCopyReducer from './dropCopyReducer'
import executionConfigurationReducer from './executionConfigurationReducer'
import exposureReducer from './exposureReducer'
import feedingConfigurationReducer from './feedingConfigurationReducer'
import fixConfigurationReducer from './fixConfigurationReducer'
import gatewaysReducer from './gatewaysReducer'
import globalSymbolMapReducer from './globalSymbolMapReducer'
import inProgressReducer from './inProgressReducer'
import { modalsReducer } from './modalsReducer'
import platformsReducer, { mt4PlatformReducer, mt5PlatformReducer, platformSessionsReducer, platformSymbolMapReducer } from './platformsReducer'
import positionsReducer from './positionsReducer'
import quotesReducer from './quotesReducer'
import rightbarReducer from './rightbarReducer'
import riskToolReducer from './riskToolReducer'
import statusReducer from './statusReducer'
import summaryReducer from './summaryReducer'
import sysLpsReducer from './sysLpsReducer'
import tradingHistoryReducer from './tradingHistoryReducer'
import volumeTransferReducer from './volumeTransferReducer'
import extConfigurationReducer from './extConfigurationReducer'
import usersReducer from './usersReducer'
import tradingReducer from './tradingReducer'
import BinanceReducer from './BinanceReducer'
import NotificationReducer from './notificationReducer'
import leverageProfileReducer from './leverageProfileReducer'
import ReportsReducer from './reportsReducer'
import ChatBotReducer from './ChatBotReducer'

const rootReducer = combineReducers({
  auth: authReducer,
  gateways: gatewaysReducer,
  status: statusReducer,
  summary: summaryReducer,
  rightbar: rightbarReducer,
  exposure: exposureReducer,
  positions: positionsReducer,
  deals: tradingHistoryReducer,
  volumeTransfer: volumeTransferReducer,
  riskTool: riskToolReducer,
  modals: modalsReducer,
  platforms: platformsReducer,
  mt4Platform: mt4PlatformReducer,
  mt5Platform: mt5PlatformReducer,
  fixConfiguration: fixConfigurationReducer,
  extConfiguration: extConfigurationReducer,
  platformSymbolMap: platformSymbolMapReducer,
  platfrormSessions: platformSessionsReducer,
  leverageProfile: leverageProfileReducer,
  commonSettings: commonSettingsReducer,
  globalSymbolMap: globalSymbolMapReducer,
  executionConfiguration: executionConfigurationReducer,
  feedingConfiguration: feedingConfigurationReducer,
  sysLps: sysLpsReducer,
  backups: backupsReducer,
  ce: ceReducer,
  consolidation: consolidationReducer,
  dropCopy: dropCopyReducer,
  inProgress: inProgressReducer,
  users: usersReducer,
  quotes: quotesReducer,
  trading: tradingReducer,
  BinanceReducerSwap: BinanceReducer,
  NotificationReducer: NotificationReducer,
  Reports: ReportsReducer,
  ChatBot: ChatBotReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
