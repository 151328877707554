import React, { memo, useCallback } from 'react'
import cls from '../chatBot.module.scss'
import { FormattedMessage } from 'react-intl'
import { Button } from './Button'
import { faClose, faReply } from '@fortawesome/free-solid-svg-icons'
import { ResetMessage, UpdateOpen } from '../../../redux/reducers/ChatBotReducer'
import { useDispatch } from 'react-redux'

export const Header = memo(() => {
  const dispatch = useDispatch()

  const onClose = useCallback(() => {
    dispatch(UpdateOpen(false))
  }, [])

  const onReset = useCallback(() => {
    dispatch(ResetMessage())
  }, [])

  return (
    <div className={cls.header}>
      <FormattedMessage id="t4bBot" />
      <div>
        <Button text={'Start'} cb={onReset} icon={faReply} cls={cls.btn} type="prompt" />
        <Button text={'Close'} cb={onClose} icon={faClose} cls={cls.btn} type="prompt" />
      </div>
    </div>
  )
})
