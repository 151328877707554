import React, { useEffect } from 'react'
import { Button, Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { ExecutionLpBRule, OrderSide, OrderType, PriceOptionTypes } from '../../entity/configuration'
import { useFormValidation } from '../../hooks/useFormValidation'
import { addExecutionLpRule, changeExecutionLpRule, executionConfigurationChanged, getExecutionLpRules } from '../../redux/actions/execution/execution-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { RootState } from '../../redux/reducers/rootReducer'
import { blankInput, buildControlsExtTwoPerLine, checkboxInput, mselectInput, textInput, sselectInput, cmselectInput } from '../../utils/controls'
import {
  buildMultiselectOptionsFromArray,
  buildMultiselectOptionsFromEnum,
  buildOptionsWithAll,
  buildSelectOption,
  isAllOption,
  isEqualArrays,
  isTrimString,
  optionsToStrings,
} from '../../utils/multiselect-utils'
import { IRightbar } from './rightbar-types'
import { useTranslate } from '../../hooks/useTranslate'

enum enumAll {
  All = 'All',
}

const ExecutionLpRuleRightbar: React.FC<IRightbar> = React.memo(({ data: { type, item, params } }) => {
  const dispatch = useDispatch()
  const translate = useTranslate()
  const executionConfiguration = useSelector((state: any) => state.executionConfiguration)
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(
    new ExecutionLpBRule({
      ...item,
      Symbols: item?.Symbols?.map((elem: any) => buildSelectOption(elem)),
      PriceOption: { value: item.PriceOption, label: translate(`test.${item.PriceOption}`) },
      MinVolume: String(item.MinVolume),
      MaxVolume: String(item.MaxVolume),
      OrderSides: { value: item.OrderSides, label: item.OrderSides },
    }),
    ExecutionLpBRule.schema,
  )

  const { gateway } = useSelector((state: RootState) => state.gateways)

  useEffect(() => {
    if (type === 'add') {
      setInputState((prev: any) => {
        return {
          ...prev,
          PriceOption: { value: 'ORDER_PRICE', label: translate(`test.ORDER_PRICE`) },
          OrderTypes: buildMultiselectOptionsFromEnum(enumAll),
        }
      })
    }
  }, [type]) // eslint-disable-line react-hooks/exhaustive-deps

  if (+inputState.MinVolume > 1000000000 || String(inputState.MinVolume)?.slice(-1) === '.') {
    errors.MinVolume = true
  }

  if (+inputState.MaxVolume > 1000000000 || String(inputState.MaxVolume)?.slice(-1) === '.') {
    errors.MaxVolume = true
  }

  if (+inputState.DelayFrom > +inputState.DelayTo) {
    errors.DelayTo = true
    touched.DelayTo = true
  }

  const handleSave = () => {
    if (!isValid()) {
      return
    }

    const newTrimState = {
      ...inputState,
      Logins: isTrimString(inputState.Logins),
      Groups: isTrimString(inputState.Groups),
      Symbols: inputState.Symbols.map((item: any) => {
        if (item.value.includes(',')) {
          return item.value.split(',')
        } else {
          return item.value
        }
      }).flat(Infinity),
      Security: isTrimString(inputState.Security),
      PriceOption: inputState.PriceOption.value,
      OrderSides: inputState.OrderSides.value,
    }

    const rule = new ExecutionLpBRule(newTrimState)
    if (type === 'add' || type === 'clone') {
      dispatch(addExecutionLpRule({ rule, params }))
    } else {
      dispatch(changeExecutionLpRule({ rule, params }))
    }
    dispatch(getExecutionLpRules({ ...params }))
    dispatch(executionConfigurationChanged())
    dispatch(hideRightBar())
  }

  const setOrderTypesState = (state: any) => {
    const all = 'All'

    if (
      state.OrderTypes?.find(isAllOption) ||
      isEqualArrays(
        optionsToStrings(state.OrderTypes)
          .filter(option => option !== all)
          .sort(),
        Object.keys(OrderType)
          .filter(option => option !== all)
          .sort(),
      )
    ) {
      setInputState({
        ...state,
        OrderTypes: buildMultiselectOptionsFromArray([all]),
      })
      return
    }
    setInputState(state)
  }

  const findAggregationPools = executionConfiguration.aggregationPools
    .map((item: any) => {
      if (item.Lps.some((item: any) => item.value === params.Lp)) return item.Name
    })
    .filter(Boolean)
  const findProcessingRules = executionConfiguration.processingRules
    .map((item: any) => {
      if (findAggregationPools.includes(item.LpPool)) return item.Id
    })
    .filter(Boolean)
    .flat(Infinity)

  const findActivePlatform = executionConfiguration.routingRules
    .map((item: any) => {
      if (findProcessingRules.includes(item.ProcessorId)) return item.Platforms
    })
    .filter(Boolean)
    .flat(Infinity)
    .map((item: any) => item.value)
    .filter((item: any, index: number, arr: any) => arr.indexOf(item) === index)

  const activePlatform = gateway?.Platforms?.filter((item: any) => {
    if (findActivePlatform.includes(item.Name)) {
      return item
    }

    if (findActivePlatform[0] === 'All') {
      return item
    }
  })

  const platformTags = activePlatform.map((item: any) => item.Tags).flat(Infinity)
  const platformSymbols = activePlatform
    .map((item: any) => item.Symbols)
    .flat(Infinity)
    .map((item: any) => item.value)

  const options = [
    {
      label: '---Symbols---',
      value: '1',
      options: buildMultiselectOptionsFromArray(platformSymbols.filter((item: string, i: number, arr: string[]) => arr.indexOf(item) === i)),
    },
    {
      label: '---Tags---',
      value: '2',
      options: buildMultiselectOptionsFromArray(platformTags.filter((item: string, i: number, arr: string[]) => arr.indexOf(item) === i)),
    },
  ]

  return (
    <Card>
      <Card.Header className="color-dark font-500">
        <FormattedMessage id={`lp-rule.${type}`} />
      </Card.Header>
      <Card.Body>
        {buildControlsExtTwoPerLine(
          [
            cmselectInput('Symbols', options || [], '', 'Mask', true).title('Add mask:'),
            textInput('Logins', false, '', '', 'Mask', true),
            textInput('Groups', false, '', '', 'Mask', true),
            textInput('MinVolume'),
            textInput('MaxVolume'),
            textInput('Security', false, '', '', 'Mask', true),
            mselectInput('OrderTypes', buildOptionsWithAll(buildMultiselectOptionsFromEnum(OrderType), inputState.OrderTypes)).stateSetup(setOrderTypesState),
            sselectInput('OrderSides', buildMultiselectOptionsFromArray(Object.keys(OrderSide)), false).optionZindex(false),
            blankInput(),

            textInput('DelayFrom'),
            textInput('DelayTo'),
            textInput('TicksThreshold'),
            checkboxInput('ConsiderConnectorSpread'),
            sselectInput(
              'PriceOption',
              Object.values(PriceOptionTypes).map((item: any) => {
                return {
                  value: item,
                  label: translate(`test.${item}`),
                }
              }),
              false,
            ).skipWhen(!gateway.EnablePriceOption),
            textInput('WorstPricePercentage').skipWhen(inputState.PriceOption.value !== PriceOptionTypes.WORST_PERCENTAGE_PRICE || !gateway.EnablePriceOption),
            checkboxInput('Enabled'),
          ],
          inputState,
          setInputState,
          'execution-lp-bar',
          touched,
          setTouched,
          errors,
        )}

        <Button className="t4b-bg-dark-button mt-3" onClick={handleSave}>
          <FormattedMessage id="save" tagName="span" />
        </Button>
      </Card.Body>
    </Card>
  )
})

export default ExecutionLpRuleRightbar
