import React from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage } from 'react-intl'

interface ISaveWarning {
  noChanges: boolean
  link: string
}

const SaveWarning: React.FC<ISaveWarning> = ({ noChanges, link }) => {
  if (noChanges) {
    return null
  }
  return (
    <Button as={Link} to={link} className="t4b-bg-dark-button ml-auto d-flex align-items-center">
      <span className="text-warning mr-1">
        <FontAwesomeIcon icon={faExclamationTriangle} />
      </span>
      <FormattedMessage id="save-warning" tagName="span" />
    </Button>
  )
}

export default SaveWarning
