import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { SmtpEntity } from '../../entity/system-settings'
import { useFormValidation } from '../../hooks/useFormValidation'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { buildControlsExtTwoPerLine, checkboxInput, textInput } from '../../utils/controls'
import { IRightbar } from './rightbar-types'

const SmtpRightbar: React.FC<IRightbar> = React.memo(({ data: { item, onSave } }) => {
  const dispatch = useDispatch()
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(new SmtpEntity(item), SmtpEntity.schema)

  const handleSave = () => {
    if (!isValid()) {
      return
    }
    if (onSave) {
      onSave(inputState)
    }
    dispatch(hideRightBar())
  }

  return (
    <Card>
      <Card.Header className="color-dark font-500">
        <FormattedMessage id="smtp-rightbar.title" />
      </Card.Header>
      <Card.Body>
        {buildControlsExtTwoPerLine(
          [textInput('SmtpServerHost'), textInput('SmtpServerPort'), checkboxInput('UseSsl'), checkboxInput('SendOnRestart')],
          inputState,
          setInputState,
          'smtp',
          touched,
          setTouched,
          errors,
        )}
        <Button className="t4b-bg-dark-button mt-3" onClick={handleSave}>
          <FormattedMessage id="save" tagName="span" />
        </Button>
      </Card.Body>
    </Card>
  )
})

export default SmtpRightbar
