import { useState } from 'react'

export function useFormValidation(item: any, schema: any) {
  const [inputState, setInputState] = useState<any>(item)
  const [touched, setTouched] = useState<any>({})

  const validate = (object: any, schema: any) => {
    const errors: { [index: string]: any } = {}

    for (const field in schema) {
      if (!schema[field].isValidSync(object[field])) {
        errors[field] = true
      }
    }
    return errors
  }

  const validationResult = validate(inputState, schema)
  const isValid = () => {
    if (Object.values(validationResult).find((result: boolean) => result)) {
      setTouched(Object.fromEntries(Object.entries(inputState).map(([key, value]) => [key, true])))
      return false
    }
    return true
  }

  return [inputState, setInputState, touched, setTouched, validationResult, isValid]
}
